import React from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Stack, Grid, Button, } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { FormItem, SenderProfile } from "../../models/common";
import { UploadSupportDocumentFormItems } from "./UploadSupportDocumentFormItems";
import FormItemComponent from "../FormItem";
import { useStateStore } from "../../store";
import { toast } from "react-toastify";
import { UploadSenderSupportingDocument } from "../../services/OutboundApis";
import { UploadSenderSupportingDocumentRequest } from "../../models/Outbound";

const initialUploadSupportDocument: any = {
  DocumentType: '',
  DocumentName: '',
  Description: '',
  // Support_TransactionID: '',
  Document: '',
};

const validationSchema = yup.object({
  DocumentType: yup
    .string()
    .required('Document Type is required'),
  DocumentName: yup
    .string()
    .required('Document Name is required')
    .max(495, 'Maximum 495 letters'),
  Description: yup
    .string()
    .required('Document Description is required')
    .max(1500, 'Maximum 1500 letters'),
  Document: yup
    .string()
    .required('Document is required'),
});

const formValues2FormItemsArray = (formValues: any): FormItem[] => {
  const formItemsArray: FormItem[] = [];
  for (var key of Object.keys(formValues)) {
    if (UploadSupportDocumentFormItems[key]) {
      switch (key) {
        default:
          formItemsArray.push(UploadSupportDocumentFormItems[key])
          break;
      }
    };
  }
  return formItemsArray;
}

interface UploadSupportFormProps {
  transactionId: number,
  cb?: Function;
}

const UploadSupportDocumentForm = ({ cb, transactionId }: UploadSupportFormProps) => {
  const [submitting, setSubmitting] = React.useState(false);
  const state = useStateStore();
  const formik = useFormik({
    initialValues: initialUploadSupportDocument,
    validationSchema,
    onSubmit: async (values: any) => {
      try {
        setSubmitting(true);
        const supportingDocRequrestBody: UploadSenderSupportingDocumentRequest = {
          ...values,
          TransactionID: transactionId,
          SenderID: parseInt((state.profile as unknown as SenderProfile)?.ExSenderId),
        }
        await UploadSenderSupportingDocument(state.affiliate.affiliateId, supportingDocRequrestBody);

        toast.success('Your document has been uploaded.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        if (cb) cb();
      } catch (error) {
        console.log(error)
      } finally {
        setSubmitting(false);
      }
    },
  });

  const formItemsArray = formValues2FormItemsArray(initialUploadSupportDocument)

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={4} alignItems="center">
        <Grid container spacing={2} alignItems='center'>
          {formItemsArray.map((item: FormItem) =>
            <FormItemComponent key={item.key} item={item} formik={formik} validationSchema={validationSchema} />
          )}
        </Grid>
        <Button size="large" color="primary" variant="contained" sx={{ width: 1 / 2 }} type="submit">
          Upload
        </Button>
      </Stack>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  );
};

export default UploadSupportDocumentForm;
