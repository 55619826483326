import { stringify } from "qs";
import { toast } from 'react-toastify';
import { FetchTransactionListParams } from "../models/common";
import { clearFormData } from "../utils/form";
import request, { RequestBody } from "../utils/request";

export async function CreateInboundSender(AffiliateId: string, data: RequestBody) {
  const res = await request({
    url: `/createSender/${AffiliateId}`,
    method: 'POST',
    data: clearFormData(data),
  });
  if (res.Status === 'DUPLICATE') {
    toast.warn('Oops! Duplicate sender found', {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    throw res;
  }
  return res;
}

export async function CreateInboundReceiver(AffiliateId: string, data: RequestBody) {
  const res = await request({
    url: `/inReceiver/${AffiliateId}`,
    method: 'POST',
    data: clearFormData(data),
  });
  if (res.Status === 'DUPLICATE') {
    toast.warn('Oops! Duplicate receiver found', {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    throw res;
  }
  return res;
}

export async function fetchInboundReceivers(AffiliateId: string, senderId: string) {
  return request({
    url: `/listReceivers/${AffiliateId}?senderId=${senderId}`,
    method: 'GET',
  });
}

export async function CreateInboundTransaction(AffiliateId: string, data: RequestBody) {
  return request({
    url: `/inboundCreateTransaction/${AffiliateId}`,
    method: 'POST',
    data: clearFormData(data),
  });
}

export async function fetchInboundTransactionList(AffiliateId: string, params: FetchTransactionListParams) {
  return request({
    url: `/inTransactions/${AffiliateId}?${stringify(params)}`,
    method: 'GET',
  });
}

export async function fetchInboundTransactionDetail(AffiliateId: string, transactionId: string) {
  return request({
    url: `/inboundGetTransactionDetails/${AffiliateId}/transaction/${transactionId}`,
    method: 'GET',
  });
}