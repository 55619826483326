import React, { createContext, useReducer, useContext } from 'react';
import { Affiliate, SenderProfile } from './models/common';

const defaultAffiliate: Affiliate = {
  affiliateId: '',
  shortName: '',
  type: 'outbound',
  name: 'NovaRemit',
  logoUrl: {
    url: ''
  },
  themeColor: '#074898',
  headline: 'A better way to send money',
  featuresCollection: {
    items: [
      {
        title: 'Deposit to Bank Account',
        description: 'Send a secure bank transfer directly to hundreds of major banks worldwide.',
      },
      {
        title: 'Credit to Wallet',
        description: 'Credit to Wallet',
      },
      {
        title: 'Cash Pick up',
        description: 'Cash is available to collect within minutes from many locations across the country.',
      },
    ]
  },
  bankDetail: {
    bsb: '123456',
    accountNumber: '1234567890',
    payId: '0411111111',
  },
  sendCountriesCollection: {
    items: [
      {
        name: 'Australia',
        code: 'Aus',
        currencyCollection: {
          items: [
            { code: 'AUD' }
          ]
        }
      }
    ]
  },
  receiveCountriesCollection: {
    items: [
      {
        name: 'Thailand',
        code: 'THA',
        currencyCollection: {
          items: [
            { code: 'THB' }
          ]
        }
      }
    ]
  },
  threshold: 5000,
  app: {
    appStoreUrl: 'https://www.apple.com/',
    googlePlayUrl: 'https://play.google.com/',
    showAppDownload: false
  },
  poLi: {
    enable: false,
    poLiLogo: {
      url: "https://www.polipayments.com/"
    },
    sendConfirmationEmailToMerchant: false,
    sendEmailLinkToCustomer: false,
    expiryAfterDays: 7,
    allowSenderReference: false,
    allowMultiplePayment: false
  },
};

export interface ContextState {
  affiliate?: Affiliate,
  profile?: SenderProfile,
  loading: boolean
}


const initialState = {
  affiliate: defaultAffiliate,
  profile: undefined,
  loading: true,
};

function reducer(state: ContextState, action: any) {
  switch (action.type) {
    case 'update':
      return {
        affiliate: { ...state.affiliate, ...action.payload.affiliate },
        profile: action.payload.profile,
        loading: action.payload.loading,
      };
    default:
      throw new Error();
  }
}

const StateContext = createContext(initialState);
const DispatchContext = createContext({} as React.Dispatch<any>);

function useStateStore() {
  return useContext(StateContext);
}

function useDispatchStore() {
  return useContext(DispatchContext);
}

//@ts-ignore
function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export { useStateStore, useDispatchStore, StoreProvider };