import React from 'react';
import Features from '../components/Features';
import Layout from '../components/Layout';
import Remit from '../components/Remit';

const Home = () => (
  <Layout hideBorder={true}>
    <Remit />
    <Features />
  </Layout>
);

export default Home;
