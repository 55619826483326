import { FormItem, FormItemType } from "../../models/common";
import { AUSTATES } from "../../utils/common";
import Countries from '../../utils/countryCodeList.json';

export const ReceiverFormItems: { [key: string]: FormItem; } = {
  AccountNumber: {
    key: 'AccountNumber',
    label: 'Account Number',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 12,
    isRequired: true,
  },
  BSB: {
    key: 'BSB',
    label: 'BSB',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 6,
    isRequired: true,
  },
  BankCode: {
    key: 'BankCode',
    label: 'Bank Code',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 3,
    isRequired: true,
  },
  IndividualOrBusiness: {
    key: 'IndividualOrBusiness',
    label: 'Individual Or Business',
    type: FormItemType.SELECT,
    width: 6,
    selectValues: [{label: 'Individual', value: 'I'},{label: 'Business', value: 'B'}],
    isRequired: true,
  },
  ABN: {
    key: 'ABN',
    label: 'ABN',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 11,
    isHidden: true,
  },
  ACN: {
    key: 'ACN',
    label: 'ACN',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 9,
    isHidden: true,
  },
  FullName: {
    key: 'FullName',
    label: 'Full Name',
    type: FormItemType.TEXTINPUT,
    width: 12,
    maxLength: 140,
    isRequired: true,
  },
  AddressLine1: {
    key: 'AddressLine1',
    label: 'Address',
    type: FormItemType.TEXTINPUT,
    width: 12,
    maxLength: 140,
    isRequired: true,
  },
  Suburb: {
    key: 'Suburb',
    label: 'Suburb',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 35,
    isRequired: true,
  },
  State: {
    key: 'State',
    label: 'State',
    type: FormItemType.SELECT,
    width: 3,
    selectValues: AUSTATES,
    isRequired: true,
  },
  Postcode: {
    key: 'Postcode',
    label: 'Postcode',
    type: FormItemType.TEXTINPUT,
    width: 3,
    maxLength: 4,
    minLength: 4,
    isRequired: true,
  },
  Country: {
    key: 'Country',
    label: 'Country',
    type: FormItemType.SELECT,
    width: 6,
    selectValues: Countries.map((i: any) => ({ value: i.value, label: i.label })),
    isRequired: true,
  },
  MobileInternationalDialCode: {
    key: 'MobileInternationalDialCode',
    label: 'Country Code',
    type: FormItemType.SELECT,
    width: 2,
    selectValues: Countries.map((i: any) => ({ value: i.phone, label: i.phone })),
  },
  Mobile: {
    key: 'Mobile',
    label: 'Mobile',
    type: FormItemType.TEXTINPUT,
    isNumberOnly: true,
    isPhoneNumber: true,
    width: 4,
  },
  EmailAddress: {
    key: 'EmailAddress',
    label: 'Email Address',
    type: FormItemType.TEXTINPUT,
    width: 12,
    maxLength: 200,
  },
  DOB: {
    key: 'DOB',
    label: 'Date Of Birth',
    type: FormItemType.DATEPICKER,
    width: 6,
  },
  Occupation: {
    key: 'Occupation',
    label: 'Occupation',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 150,
  },
  AKA: {
    key: 'AKA',
    label: 'AKA',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 140,
  },
}

