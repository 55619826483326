import { FormItem, FormItemType } from "../../models/common";

export const UploadSupportDocumentFormItems: { [key: string]: FormItem; } = {
  DocumentType: {
    key: 'DocumentType',
    label: 'Document Type',
    type: FormItemType.SELECT,
    width: 6,
    isRequired: true,
    selectValues: [
      {
        label: 'Invoice',
        value: 'Invoice',
      },
      {
        label: "Pay Slips",
        value: 'Pay Slips',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ]
  },
  DocumentName: {
    key: 'DocumentName',
    label: 'Document Name',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 495,
    isRequired: true,
  },
  Description: {
    key: 'Description',
    label: 'Document Description',
    type: FormItemType.TEXTINPUT,
    width: 12,
    maxLength: 1500,
    maxRows: 3,
    isRequired: true,
  },
  TransactionID: {
    key: 'TransactionID',
    label: 'Transaction ID',
    type: FormItemType.TEXTINPUT,
    width: 6,
    isRequired: false,
  },
  Document: {
    key: 'Document',
    label: 'Front of Document',
    type: FormItemType.DOCUMENT,
    width: 12,
    isRequired: true,
  },
}

