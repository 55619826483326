import { Container, Box, Typography, Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useStateStore } from "../../store";
import DepositMethodDialog from "./DepositMethodDialog";
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { TransactionDetails } from "../../models/common";
import { DepositMethodConfig, DepositMethodLinkResponse } from "../../models/Outbound";
import { createDepositMethodLink } from "../../services/OutboundApis";
import { toast } from "react-toastify";

const depositMethodName = {
  POLI: 'POLi',
  PAYTO: 'PayTo',
}

export interface DepositMethodOptionsProps {
  transaction: TransactionDetails
}

const DepositMethodOptions = ({ transaction }: DepositMethodOptionsProps) => {
  const state = useStateStore();

  const [depositMethodTitle, setDepositMethodTitle] = React.useState<string>("");
  const [depositMethodUrl, setDepositMethodUrl] = React.useState<string>("");
  const [openDepositMethodDialog, setOpenDepositMethodDialog] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [poliLink, setPoliLink] = React.useState<string>("");

  const shouldShowOptions = state.affiliate.poLi?.enable; // in the feature, check with PayTo status

  const handleOpenDepositMethodDialog = async (name: string) => {
    setShowLoading(true);

    try {
      switch (name) {
        case depositMethodName.POLI:
          if (!state.affiliate.poLi?.enable) {
            toast.warn("POLi Payment is not available now", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
            return;
          };
  
          if (poliLink !== ""){
            setDepositMethodUrl(poliLink);
            setOpenDepositMethodDialog(true);
            return;
          }
  
          let config: DepositMethodConfig = {
            MethodName: depositMethodName.POLI,
            SendConfirmationEmailAfterPaymentCompleted: state.affiliate.poLi.sendConfirmationEmailToMerchant,
            SendEmailLinkToCustomer: state.affiliate.poLi.sendEmailLinkToCustomer,
            ExpiryAfterDays: state.affiliate.poLi.expiryAfterDays,
            AllowSenderReference: state.affiliate.poLi.allowSenderReference,
            AllowMultiplePayment: state.affiliate.poLi.allowMultiplePayment,
          };
          let response = await createDepositMethodLink(state.affiliate.affiliateId, transaction, config) as DepositMethodLinkResponse;
          
          if (response.IsSuccess) {
            setDepositMethodTitle("POLi Payment");
            setPoliLink(response.Link)
            setDepositMethodUrl(response.Link);
            setOpenDepositMethodDialog(true);
          } else {
            toast.warn("POLi Payment is not available now.", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
          }
          break;
  
        case depositMethodName.PAYTO:
          break;
      }
    }
    finally {
      setShowLoading(false);
    }
  };

  const containerStyle: SxProps<Theme> = {
    display: shouldShowOptions ? "block" : "none"
  }

  const boxStyle: SxProps<Theme> = {
    p: 1,
    mb: 2,
    width: 500,
    objectFit: "contain", 
    cursor: "pointer",
    backgroundColor: "#9a0d0d17", 
    border: "2px solid #9a0d0d17",
    borderRadius: 2
  }

  const boxTitleStyle: SxProps<Theme> = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontWeight: "bold", 
    width: 250,
    textAlign: "left"
  }

  return (
    <React.Fragment>
      <Container disableGutters sx={containerStyle}>
        <Typography  variant="body1" component="div" sx={{ textAlign: "center", p:1 }}>OR</Typography>
        { // POLi
          state.affiliate.poLi?.enable === true &&
          <Box sx={boxStyle} onClick={() => handleOpenDepositMethodDialog(depositMethodName.POLI)}>
            <Container sx={{ display: "flex", width: 500 }}>
              <Typography variant="h6" component="div" sx={boxTitleStyle}>PAY WITH POLi</Typography>
              <img
                src={state.affiliate.poLi.poLiLogo.url}
                srcSet={state.affiliate.poLi.poLiLogo.url}
                alt="POLi Payment"
                loading="lazy"
                width="120px"
                style={{ marginLeft: "auto", marginRight: "25px" }}
              />
            </Container>
          </Box>
        }
        {/* { PayTo } */}
      </Container>
      <DepositMethodDialog
        open={openDepositMethodDialog}
        title={depositMethodTitle}
        url={depositMethodUrl}
        onClose={() => setOpenDepositMethodDialog(false)} />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default DepositMethodOptions;
