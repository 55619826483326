export interface SenderProfile {
  ID: string;
  AffiliateSenderId: string;
  ExSenderId: string;
  Title: string;
  FirstName: string;
  LastName: string;
  AddressLine1: string;
  Suburb: string;
  State: string;
  Postcode: string;
  Country: string;
  MobileInternationalDialCode?: string;
  Mobile?: string;
  EmailAddress: string;
  DateOfBirth: string;
  KycStatus: string;
  Occupation?: string;
  CountryOfBirth?: string;
  Nationality?: string;
  PlaceOfBirth?: string;
  SenderType: string;
  [propName: string]: any;
}

export interface Country {
  code: string;
  name: string;
  flag: React.ReactNode;
  currency: string[];
}

export interface uploadDocument {
  SenderID: number;
  DocumentType: string;
  DocumentNumber: string;
  Country: string;
  State?: string;
  ExpiryDate?: Date;
  IssueDate?: Date;
  Document: Document;
}

export interface Document {
  Front: string;
  Back?: string;
}

export interface FormItem {
  key: string;
  label: string;
  type: FormItemType;
  width: number;
  selectValues?: FormItemSelectValue[];
  isNumberOnly?: boolean;
  isDisabled?: boolean;
  maxLength?: number;
  minLength?: number;
  isPhoneNumber?: boolean;
  minDate?: Date,
  maxDate?: Date,
  isHidden?: boolean,
  maxRows?: number,
  helpText?: string,
  isRequired?: boolean,
}

export interface FormItemSelectValue {
  label: string;
  value: string|number;
}

export interface Affiliate {
  affiliateId: string;
  shortName: string;
  name: string;
  logoUrl: LogoUrl;
  themeColor?: string;
  headline?: string;
  featuresCollection?: FeaturesCollection;
  type: string;
  sendCountriesCollection: SendCountriesCollection,
  receiveCountriesCollection: ReceiveCountriesCollection,
  bankDetail: BankDetail,
  threshold: number,
  privacyStatement?: any,
  termsAndConditions?: any,
  contactUs?: any,
  app?: App,
  poLi?: Poli
}

export interface LogoUrl {
  url: string;
}

export interface Feature {
  title: string;
  description: string;
}

export interface FeaturesCollection {
  items: Feature[];
}

export interface Currency {
  code: string;
}

export interface CurrencyCollection {
  items: Currency[];
}

export interface CFCountry {
  name: string;
  code: string;
  currencyCollection: CurrencyCollection
}

export interface SendCountriesCollection {
  items: CFCountry[];
}

export interface ReceiveCountriesCollection {
  items: CFCountry[];
}

export interface BankDetail {
  bsb: string,
  accountNumber: string,
  payId: string,
}

export enum FormItemType {
  TEXTINPUT,
  SELECT,
  SWITH,
  DATEPICKER,
  COUNTRY,
  CURRENCY,
  DOCUMENT,
  SUPPORT_DOCUMENT
}

export interface KASSIError {
  ErrorCode: string;
  LongDescription?: string;
}

export interface FetchTransactionListParams {
  senderId?: string;
  limit: number;
  offset: number;
  status?: string;
  fromDate?: string;
  toDate?: string;
}

export interface App {
  appStoreUrl?:  string;
  googlePlayUrl?:  string;
  showAppDownload?: boolean;
}

export interface Poli {
  enable: boolean;
  poLiLogo: LogoUrl;
  sendConfirmationEmailToMerchant: boolean;
  sendEmailLinkToCustomer: boolean;
  expiryAfterDays: number;
  allowSenderReference: boolean;
  allowMultiplePayment: boolean;
}

export interface TransactionDetails {
  transactionId: string;
  amount: number;
  senderFullName?: string;
  senderEmail?: string;
}