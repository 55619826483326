import React from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Stack, Grid, } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { FormItem } from "../../models/common";
import { ProfileFormItems } from "./ProfileFormItems";
import FormItemComponent from "../FormItem";
import { CreateOutboundSender, EditOutboundSender } from "../../services/OutboundApis";
import { useDispatchStore, useStateStore } from "../../store";
import { CreateOutboundSenderRequest, EditOutboundSenderRequest } from "../../models/Outbound";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import { getIdFromAuth0Sub } from "../../utils/common";
import { fetchProfile } from "../../services/CommonApis";

const validationSchema = yup.object({
  // Title: yup.string(),
  FirstName: yup
    .string()
    .required('First Name is required')
    .matches(/^[a-zA-Z\s-]+$/, "Only allow alphabet letters, spaces and hyphens.")
    .max(70, 'Maximum 70 letters'),
  LastName: yup
    .string()
    .required('Last Name is required')
    .matches(/^[a-zA-Z\s-]+$/, "Only allow alphabet letters, spaces and hyphens.")
    .max(70, 'Maximum 70 letters'),
  AddressLine1: yup
    .string()
    .required('Address is required')
    .max(140, 'Maximum 140 letters')
    .matches(/^[a-zA-Z0-9\s-,/]+$/, "Only allow characters, spaces, hyphens, commas and forward slash."),
  Suburb: yup
    .string()
    .required('Suburb is required')
    .max(35, 'Maximum 35 letters')
    .matches(/^[a-zA-Z\s-]+$/, "Only allow alphabet letters, spaces and hyphens."),
  State: yup
    .string()
    .required('State is required'),
  Postcode: yup
    .string()
    .matches(/^[0-9]{4}$/, "Must be exactly 4 digits")
    .required('Postcode is required'),
  Country: yup
    .string()
    .required('Country is required'),
  MobileInternationalDialCode: yup
    .string(),
  Mobile: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(9, 'Minimum 9 digits')
    .max(10, 'Maximum 10 digits'),
  EmailAddress: yup
    .string()
    .email('Enter a valid email')
    .max(200, 'Maximum 200 letters')
    .required('Email is required'),
  DateOfBirth: yup
    // .date()
    .string()
    .nullable()
    .required('Date Of Birth is required'),
  Occupation: yup.string().max(150, 'Maximum 150 digits'),
  CountryOfBirth: yup.string(),
  Nationality: yup.string(),
  PlaceOfBirth: yup.string(),
});

const formValues2FormItemsArray = (formValues: any): FormItem[] => {
  const formItemsArray: FormItem[] = [];
  for (var key of Object.keys(formValues)) {
    if (ProfileFormItems[key]) {
      switch (key) {
        case 'Country':
          formItemsArray.push({
            ...ProfileFormItems[key],
            isDisabled: true,
          })
          break;
        case 'MobileInternationalDialCode':
        case 'TelephoneInternationalDialCode':
          formItemsArray.push({
            ...ProfileFormItems[key],
            isDisabled: true,
          })
          break;
        default:
          formItemsArray.push(ProfileFormItems[key])
          break;
      }
    };
  }
  return formItemsArray;
}

interface ProfileFormProps {
  formValues: CreateOutboundSenderRequest;
  cb?: Function;
  isStepper?: boolean;
  senderId?: string;
}


const OutboundProfileForm = ({ formValues, cb, isStepper = false, senderId }: ProfileFormProps) => {
  const [submitting, setSubmitting] = React.useState(false);
  const state = useStateStore();
  const dispatch = useDispatchStore();
  const {
    user,
  } = useAuth0();
  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit: async (values: CreateOutboundSenderRequest) => {
      try {
        setSubmitting(true);
        if (senderId) {
          await EditOutboundSender(state.affiliate.affiliateId,
            {
              ...values,
              SenderID: senderId,
              SenderType: 'I',
            } as EditOutboundSenderRequest,
          );
        } else { // create
          await CreateOutboundSender(state.affiliate.affiliateId,
            {
              ...values,
              AffiliateSenderID: getIdFromAuth0Sub(user?.sub!),
              SenderType: 'I',
            } as CreateOutboundSenderRequest,
          );
        }
        // fetch profile
        const profileRes = await fetchProfile(getIdFromAuth0Sub(user?.sub!), state.affiliate.affiliateId);
        dispatch({
          type: 'update', payload: {
            affiliate: state.affiliate,
            loading: false,
            profile: profileRes.profile,
          },
        });
        if (!isStepper)
          toast.success('Your information has been submitted.', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        if (cb) cb();
      } catch (error) {
        console.log(error)
      } finally {
        setSubmitting(false);
      }
    },
  });

  const formItemsArray = formValues2FormItemsArray(formValues);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack alignItems='center' spacing={4}>
        <Grid container spacing={2} alignItems='center'>
          {formItemsArray.map((item: FormItem) =>
            <FormItemComponent key={item.key} item={item} formik={formik} validationSchema={validationSchema} />
          )}
        </Grid>
        <Button size="large" color="primary" variant="contained" sx={{ width: 1 / 2 }} type="submit">
          {isStepper ? 'Next' : 'Update'}
        </Button>
      </Stack>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  );
};

export default OutboundProfileForm;
