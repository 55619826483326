import React from "react";
import _ from 'lodash';
import { Box, Paper, FormControl, Select, ListItemText, InputBase, MenuItem, Typography } from "@mui/material";
import NumberFormat from 'react-number-format';
import { Country } from "../models/common";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
      // prefix="$"
      />
    );
  },
);

const MoneyInput = (props: {
  counties: Country[],
  country: Country,
  currency: string,
  amount: number,
  changeCountry: Function,
  changeAmount: Function,
  changeCurrency: Function,
}) => {

  const [focus, setFocus] = React.useState<boolean>(false);
  const { country, currency, amount, counties, changeCountry, changeAmount, changeCurrency } = props;

  const handleChangeAmount = (event: any) => {
    if (focus) changeAmount(event.target.value);
  };

  const handleChangeCountry = (event: any) => {
    const countryCode: string = event.target.value;
    const selectCountry: Country = _.find(counties, e => e.code === countryCode)!;
    if (focus) changeCountry(selectCountry);
  };

  const handleChangeCurrency = (event: any) => {
    const currency: string = event.target.value;
    if (focus) changeCurrency(currency);
  };

  return (
    <Paper elevation={1} sx={{ pl: {xs: 0, sm: 1 }, py: 1, pr: {xs: 1, sm: 2 } }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '70px auto 70px', sm: '190px auto 80px' },
        }}
      >
        <Box>
          <FormControl variant="standard" fullWidth>
            <Select
              onFocus={() => setFocus(true)}
              disableUnderline
              value={country.code}
              renderValue={(() => <MenuItem>
                {country.flag}
                <ListItemText sx={{ ml: 1, display: { xs: 'none', sm: 'inline' } }} primary={country.name}
                />
              </MenuItem>)}
              onChange={handleChangeCountry}
            >
              {counties.map(c => (
                <MenuItem key={c.code} value={c.code}>
                  {c.flag}
                  <ListItemText sx={{ ml: 1 }} primary={c.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ px: 2, borderLeft: 1, borderRight: 1, borderColor: 'grey.300', ml: 1, display: 'flex', alignItems: 'center' }}>
          <FormControl fullWidth >
            <InputBase
              onFocus={() => setFocus(true)}
              sx={{ typography: 'h5', fontWeight: 'bold' }}
              value={amount}
              onChange={handleChangeAmount}
              inputComponent={NumberFormatCustom as any}
            />
          </FormControl>
        </Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          {country.currency.length > 1 ?
            <FormControl variant="standard" fullWidth sx={{ ml: 2 }}>
              <Select
                onFocus={() => setFocus(true)}
                disableUnderline
                value={currency}
                onChange={handleChangeCurrency}
              >
                {country.currency.map(i => (
                  <MenuItem key={i} value={i}>
                    <ListItemText primary={i} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            :
            <Typography variant="body1" component="div">
              {country.currency}
            </Typography>
          }
        </Box>
      </Box>
    </Paper >
  );
};

export default MoneyInput;
