import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, Container, Grid, Stack, List, Typography, Divider, CardContent, Box, Chip } from "@mui/material";
import AccountMenu from "../../components/AccountMenu";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import Layout from "../../components/Layout";
import { OutboundTransactionDetail } from "../../models/Outbound";
import { fetchOutboundTransactionDetail } from "../../services/OutboundApis";
import { useStateStore } from "../../store";
import { getExternalBankText } from "../../utils/common";

type TransactionParams = {
  id: string;
};

const TransactionDetail = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [transaction, setTransaction] = React.useState<OutboundTransactionDetail>();
  const state = useStateStore();
  const { id } = useParams<TransactionParams>();

  React.useEffect(() => {
    if (state.profile) getTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.profile]);

  const getTransaction = async () => {
    try {
      setLoading(true);
      const res = await fetchOutboundTransactionDetail(state.affiliate.affiliateId, id);
      setTransaction(res);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const getTransactionWidget = () => {
    if (loading) return <Loading />;
    if (!loading && !transaction) {
      return (
        <Box sx={{ textAlign: 'center', p: 8 }}><Typography sx={{ color: '#666' }} variant="caption" component="span">
          No Transaction Found
        </Typography></Box>
      );
    }


    return (<Card variant="outlined" sx={{ my: 2 }}>
      <CardContent >

        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
          <Typography color="#777" variant="body1" component="div">
            Transaction ID: {transaction!.TransactionID}
          </Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent={{ xs: 'center', sm: 'flex-start' }} sx={{ pb: 1, borderBottom: 1, borderBottomColor: '#ccc', width: { xs: 1, sm: 'auto' } }}>
            <Typography variant="h4" component="span" sx={{ fontWeight: 'bold', fontSize: 29 }}>
              AUD {transaction!.SendingAmount}
            </Typography>
            <NavigateNextIcon fontSize="large" sx={{ display: { xs: 'none', sm: 'inline' } }} />
            <ExpandMoreIcon fontSize="large" sx={{ display: { xs: 'block', sm: 'none' } }} />
            <Typography variant="h4" component="span" sx={{ fontWeight: 'bold', fontSize: 29 }}>
              {transaction!.ReceivingCurrency} {transaction!.ReceivingForeignAmount}
            </Typography>
          </Stack>
          <Chip variant="outlined" color="primary" label={transaction!.Status} sx={{ fontWeight: 'bold' }} />

          <Typography color="#777" variant="body1" component="div" sx={{ pt: 0 }}>
            Country: {transaction!.Country}
          </Typography>
          <Typography color="#777" variant="body1" component="div" sx={{ pt: 0 }}>
            Rate: 1.00 AUD = {transaction!.Rate} {transaction!.ReceivingCurrency}
          </Typography>
          <Typography color="#777" variant="body1" component="div" sx={{ pt: 0 }}>
            Service Charges: {transaction!.ServiceCharges} AUD
          </Typography>
          <Typography color="#777" variant="body1" component="div" sx={{ pt: 0 }}>
            Transaction date: {transaction!.DateOfTransaction}
          </Typography>
          <Typography color="#777" variant="body1" component="div">
            Purpose: {transaction!.Purpose}
          </Typography>
          <Typography color="#777" variant="body1" component="div">
            Source Of Funds: {transaction!.SourceOfFunds}
          </Typography>
          <Typography color="#777" variant="body1" component="div">
            Submitted To AUSTRAC: {transaction!.SubmittedToAUSTRAC}
          </Typography>
          <Typography color="#777" variant="body1" component="div">
            Transaction Submitted To KAASI Date: {transaction!.TransactionSubmittedToKAASIDate}
          </Typography>
          {transaction!.RouteToExternalBank &&
            <Typography color="#777" variant="body1" component="div">
              Route To External Bank: {getExternalBankText(transaction!.RouteToExternalBank)}
            </Typography>
          }
          {transaction!.DateSubmittedToAUSTRAC &&
            <Typography color="#777" variant="body1" component="div">
              Date Submitted To AUSTRAC: {transaction!.DateSubmittedToAUSTRAC}
            </Typography>
          }
          {/* {transaction!.ReviewReason &&
            <Typography color="#777" variant="body1" component="div">
              Review Reason: {transaction!.ReviewReason}
            </Typography>
          } */}
          {transaction!.ExternalBankResponse &&
            <Typography color="#777" variant="body1" component="div">
              External Bank Response: {transaction!.ExternalBankResponse}
            </Typography>
          }
        </Stack>
        <Divider sx={{ py: 1 }} />
        {/* <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
      <Typography variant="h5" component="div" sx={{ py: 1, mt: 1 }}>
        Send To
      </Typography>
      <Typography color="#777" variant="body1" component="div">
        Name: Robin Rodriguez
      </Typography>
      <Typography color="#777" variant="body1" component="div">
        Mobile: 061414543332
      </Typography>
      <Typography color="#777" variant="body1" component="div">
        Email: robert.rodriguez@gmail.com
      </Typography>
      <Typography color="#777" variant="body1" component="div">
        Address: 14 Epping Road, Epping, NSW 2121, Australia
      </Typography>
    </Stack> */}
      </CardContent>
    </Card>
    );
  }

  return (
    <Layout>
      <Container maxWidth="xl" sx={{ p: 3 }}>
        <Grid container spacing={5} justifyContent="center"
          alignItems="flex-start">
          <Grid item xs={2} sm={2} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <AccountMenu selected="transactions" />
          </Grid>
          <Grid item xs={12} sm={10}>
            <List sx={{ width: '100%', }}>
              <Typography variant="h4" component="div" sx={{ pb: 2 }} >
                Transaction
              </Typography>
              <Divider />
              {getTransactionWidget()}
            </List>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default withAuthenticationRequired(TransactionDetail, {
  onRedirecting: () => <Loading />,
});
