import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Link as RouterLink } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { toast } from "react-toastify";
import { Avatar, Card, Container, Grid, Typography, Divider, Link, CardHeader, Box, Stack, Button, DialogTitle, IconButton } from "@mui/material";
import AccountMenu from "../../components/AccountMenu";
import { CreateOutboundReceiverRequest, OutboundReceiver } from "../../models/Outbound";
import OutboundReceiverForm from "../../components/outbound/ReceiverForm";
import Loading from "../../components/Loading";
import Layout from "../../components/Layout";
import { useStateStore } from "../../store";
import { fetchOutboundReceivers } from "../../services/OutboundApis";
import { SenderProfile } from "../../models/common";

export const initialOutboundReceiver: CreateOutboundReceiverRequest = {
  AffiliateReceiverID: '',
  SenderID: 0,
  FirstName: '',
  LastName: '',
  EmailAddress: '',
  AddressLine1: '',
  Suburb: '',
  State: '',
  Postcode: '',
  Country: '',
  MobileInternationalDialCode: '',
  Mobile: '',
  Relationship: '',
  Nationality: '',
  IsIndividual: true,
  DateOfBirth: '',
};

const convertReceiverToReceiverFormValue = (receiver: OutboundReceiver): CreateOutboundReceiverRequest => {
  return {
    AffiliateReceiverID: receiver.affiliateReceiverId,
    SenderID: receiver.kaasiSenderID,
    FirstName: receiver.firstName,
    LastName: receiver.lastName,
    EmailAddress: receiver.email,
    AddressLine1: receiver.addressLine1,
    Suburb: receiver.suburb,
    State: receiver.state,
    Postcode: receiver.postcode,
    Country: receiver.country,
    MobileInternationalDialCode: receiver.metaData.MobileInternationalDialCode,
    Mobile: receiver.mobile,
    Relationship: receiver.metaData.Relationship,
    Nationality: receiver.nationality,
    IsIndividual: receiver.isIndividual,
    DateOfBirth: receiver.dob,
    kaasiReceiverID: receiver.kaasiReceiverID,
  }
}

const Receivers = () => {
  const [receiverFormValue, setReceiverFormValue] = React.useState<CreateOutboundReceiverRequest>(initialOutboundReceiver);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [receivers, setReceivers] = React.useState<OutboundReceiver[]>([]);
  const state = useStateStore();

  React.useEffect(() => {
    if (state.profile) getReceivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.profile]);

  const getReceivers = async () => {
    try {
      setLoading(true);
      const res = await fetchOutboundReceivers(state.affiliate.affiliateId, (state.profile as unknown as SenderProfile)?.ExSenderId);
      setReceivers(res.receivers);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const handleNewReceiver = () => {
    setReceiverFormValue(initialOutboundReceiver);
    setOpen(true);
  };

  const handleUpdateReceiveProfile = (index: number) => {
    const receiver = receivers[index];
    setReceiverFormValue(convertReceiverToReceiverFormValue(receiver));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReceiverModalSuccess = (receiverId: string, receiverList: OutboundReceiver[]) => {
    toast.success('Your information has been submitted.', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    setOpen(false);
    setReceivers(receiverList);
  }

  const getReceiversWidget = () => {
    if (loading) return <Loading />;
    if (!loading && receivers.length === 0) {
      return (
        <Box sx={{ textAlign: 'center', p: 8 }}><Typography sx={{ color: '#666' }} variant="caption" component="span">
          No Receiver Found
        </Typography></Box>
      );
    }

    return receivers.map((receiver: OutboundReceiver, index: number) =>
      <Card key={receiver.kaasiReceiverID} variant="outlined" sx={{ my: 2, pr: 2 }}>
        <CardHeader
          avatar={
            <Box sx={{ display: 'flex', alignItems: 'center', opacity: 0.6 }}>
              <Avatar sx={{ bgcolor: "#" + Math.floor(Math.random() * 0xFFFFFF).toString(16) }} aria-label="recipe">
                {receiver.firstName.substring(0, 1)}
              </Avatar>
              <Typography component="span" sx={{ ml: 1, fontSize: { xs: 18, sm: 22 } }}>
                {receiver.firstName} {receiver.lastName}
              </Typography>
            </Box>
          }
          action={
            <Box sx={{ display: 'flex', mt: 2 }}>
              <Link sx={{ mr: 2 }} component="button" onClick={() => handleUpdateReceiveProfile(index)}><Typography variant="body1">Profile</Typography></Link>
              <Link component={RouterLink} to={`/${state.affiliate.shortName}/outbound/receiver/accounts/${receiver.kaasiReceiverID}/${receiver.firstName} ${receiver.lastName}/${receiver.country}`}>Payment Methods</Link>
            </Box>
          }
        />
      </Card>
    );
  }

  return (
    <Layout>
      <Container maxWidth="xl" sx={{ p: 3 }}>
        <Grid container spacing={5} justifyContent="center"
          alignItems="flex-start">
          <Grid item xs={2} sm={2} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <AccountMenu selected="receivers" />
          </Grid>
          <Grid item xs={12} sm={10}>

            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" sx={{ pb: 2 }} >
              <Typography variant="h4" component="div" >
                Receivers
              </Typography>
              <Button sx={{ mt: { xs: 2, sm: 0 } }} variant="outlined" startIcon={<AddIcon />} onClick={handleNewReceiver}>
                Add New Receiver
              </Button>
            </Stack>
            <Divider />
            {getReceiversWidget()}
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between" >
              <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
                {receiverFormValue.AffiliateReceiverID ? 'Receiver Profile': 'Add New Receiver'}
              </Typography>
              <IconButton aria-label="close" color="secondary" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Container disableGutters sx={{ py: 1, my: 1 }}>
              <OutboundReceiverForm cb={handleReceiverModalSuccess} formValues={receiverFormValue} />
            </Container>
          </DialogContent>
        </Dialog>
      </Container >
    </Layout>
  );
};

export default withAuthenticationRequired(Receivers, {
  onRedirecting: () => <Loading />,
});
