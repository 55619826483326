import { FormItem, FormItemType } from "../../models/common";
import { AUSTATES } from "../../utils/common";
import Countries from '../../utils/countryCodeList.json';
import Nationalities from '../../utils/Nationalities.json';

export const ProfileFormItems: { [key: string]: FormItem; } = {
  FirstName: {
    key: 'FirstName',
    label: 'Given Name(s)',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 70,
    helpText: 'Must match primary document name.',
    isRequired: true,
  },
  LastName: {
    key: 'LastName',
    label: 'Last Name',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 70,
    helpText: 'Must match primary document name.',
    isRequired: true,
  },
  AddressLine1: {
    key: 'AddressLine1',
    label: 'Address',
    type: FormItemType.TEXTINPUT,
    width: 12,
    maxLength: 140,
    isRequired: true,
  },
  Suburb: {
    key: 'Suburb',
    label: 'City/Suburb',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 35,
    isRequired: true,
  },
  State: {
    key: 'State',
    label: 'State',
    type: FormItemType.SELECT,
    width: 2,
    selectValues: AUSTATES,
    isRequired: true,
  },
  Postcode: {
    key: 'Postcode',
    label: 'Postcode',
    type: FormItemType.TEXTINPUT,
    width: 2,
    maxLength: 4,
    minLength: 4,
    isRequired: true,
  },
  Country: {
    key: 'Country',
    label: 'Country',
    type: FormItemType.SELECT,
    width: 2,
    selectValues: Countries.map((i: any) => ({ value: i.value, label: i.label })),
    isRequired: true,
  },
  MobileInternationalDialCode: {
    key: 'MobileInternationalDialCode',
    label: 'Country Code',
    type: FormItemType.SELECT,
    width: 2,
    selectValues: Countries.map((i: any) => ({ value: i.phone, label: i.phone })),
  },
  Mobile: {
    key: 'Mobile',
    label: 'Mobile',
    type: FormItemType.TEXTINPUT,
    isNumberOnly: true,
    isPhoneNumber: true,
    width: 4,
  },
  EmailAddress: {
    key: 'EmailAddress',
    label: 'Email Address',
    type: FormItemType.TEXTINPUT,
    width: 12,
    maxLength: 200,
    isRequired: true,
  },
  DateOfBirth: {
    key: 'DateOfBirth',
    label: 'Date Of Birth',
    type: FormItemType.DATEPICKER,
    width: 6,
    isRequired: true,
  },
  Occupation: {
    key: 'Occupation',
    label: 'Occupation',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 150,
  },
  CountryOfBirth: {
    key: 'CountryOfBirth',
    label: 'Country Of Birth',
    type: FormItemType.SELECT,
    width: 6,
    selectValues: Countries.map((i: any) => ({ value: i.value, label: i.label })),
  },
  Nationality: {
    key: 'Nationality',
    label: 'Nationality',
    type: FormItemType.SELECT,
    width: 6,
    selectValues: Nationalities.map((i: any) => ({ value: i.Nationality, label: i.Nationality })),
  },
  PlaceOfBirth: {
    key: 'PlaceOfBirth',
    label: 'Place Of Birth',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 100,
  },
  AKA: {
    key: 'AKA',
    label: 'AKA',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 140,
  },
}

