import React from "react";
import { MenuItem, Typography, Paper, FormControl, Select, Box, CircularProgress, Button, Stack, Container, Grid, } from "@mui/material";
import _ from "lodash";
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, IconButton } from "@mui/material";
import { useSnackbar } from 'react-simple-snackbar'
import CheckCircle from '@mui/icons-material/CheckCircle';
import { snackBarOptions } from "../../utils/snackBar";
import { InboundReceiver } from "../../models/Inbound";
import { useStateStore } from "../../store";
import { fetchInboundReceivers } from "../../services/InboundApis";
import { SenderProfile } from "../../models/common";
import InboundConfirmPayment from "./InboundConfirmPayment";
import InboundReceiverForm from "../inbound/ReceiverForm";
import { initialInboundReceiver } from "../../routes/inbound/Receivers";

const CreateInBoundTransaction = (props: { DollarAmount: number }) => {
  const [openPaymentDialog, setOpenPaymentDialog] = React.useState(false);
  const [openNewReceiverDialog, setOpenNewReceiverDialog] = React.useState(false);
  const [openTransactionCompleteDialog, setOpenTransactionCompleteDialog] = React.useState(false);
  const [openSnackbar] = useSnackbar(snackBarOptions)
  const [loading, setLoading] = React.useState<boolean>(true);
  const [receivers, setReceivers] = React.useState<InboundReceiver[]>([]);
  const [receiver, setReceiver] = React.useState<InboundReceiver>();
  const [KAASITransactionID, setKAASITransactionID] = React.useState<string>();
  const state = useStateStore();

  React.useEffect(() => {
    if (state.profile) getReceivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.profile]);

  const getReceivers = async () => {
    try {
      setLoading(true);
      const res = await fetchInboundReceivers(state.affiliate.affiliateId, (state.profile as unknown as SenderProfile)?.ExSenderId);
      const fetchedRecivers = res.receivers as InboundReceiver[];
      setReceivers(fetchedRecivers);
      if (fetchedRecivers.length > 0) {
        setReceiver(fetchedRecivers[0]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const selectReceiver = (event: any) => {
    const receiverId: number = event.target.value;
    const receiver: InboundReceiver = _.find(receivers, e => e.kaasiReceiverID === receiverId)!;
    setReceiver(receiver);
  };

  const handleOpenPaymentDialog = () => {
    if (!receiver) {
      openSnackbar('Please select receiver', 2000,);
      return;
    }
    setOpenPaymentDialog(true);
  };

  const handleClosePaymentDialog = () => {
    setOpenPaymentDialog(false);
  };


  const handleConfirmPaymentSuccess = (transactionId: string) => {
    setOpenPaymentDialog(false);
    setKAASITransactionID(transactionId)
    handleOpenTransactionCompletedDialog();
    // openSnackbar('Successfully Submitted.', 2000,);
  }


  const handleOpenNewReceiverDialog = () => {
    setOpenNewReceiverDialog(true);
  };

  const handleCloseNewReceiverDialog = () => {
    setOpenNewReceiverDialog(false);
  };

  const handleNewReceiverSuccess = (receiverId: string, receiverList: InboundReceiver[]) => {
    setReceivers(receiverList);
    setReceiver(_.find(receiverList, r => String(r.kaasiReceiverID) === receiverId));
    setOpenNewReceiverDialog(false);
    // setOpenPaymentDialog(true);
  }

  const handleOpenTransactionCompletedDialog = () => {
    setOpenTransactionCompleteDialog(true);
  };

  const handleCloseTransactionCompletedDialog = () => {
    setOpenTransactionCompleteDialog(false);
  };


  return (
    <React.Fragment>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
          Select receiver:
        </Typography>
        <Button variant="text" onClick={handleOpenNewReceiverDialog}>
          Add New
        </Button>
      </Stack>
      <Paper elevation={1} sx={{ px: 2, py: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {loading && <CircularProgress size={33} sx={{ color: '#ccc', }} />}
        {(!loading && receivers.length === 0) &&
          <Box sx={{ textAlign: 'center', p: 0 }}>
            <Typography sx={{ color: '#666' }} variant="caption" component="span">
              No Receiver Found
            </Typography>
          </Box>
        }
        {(!loading && receivers.length > 0 && receiver) &&
          <FormControl variant="standard" fullWidth  >
            <Select
              disableUnderline
              value={receiver.kaasiReceiverID}
              renderValue={(() => <Typography variant="body1" component="span" sx={{ px: 1 }}>
                ({receiver.country})-{receiver.firstName} {receiver.lastName}
              </Typography>)}
              onChange={selectReceiver}
            >
              {receivers.map((item: InboundReceiver) => (
                <MenuItem key={item.kaasiReceiverID} value={item.kaasiReceiverID}>({item.country})-{item.firstName} {item.lastName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        }

      </Paper>
      {!loading &&
        <Button
          sx={{ textTransform: 'none', fontSize: 20, fontWeight: 'bold', lineHeight: 2, width: 1, mt: 4 }}
          disableElevation
          variant="contained"
          onClick={handleOpenPaymentDialog}
        // disabled={!receiver}
        >
          Confirm Payment
        </Button>
      }
      <Dialog open={openPaymentDialog} onClose={handleClosePaymentDialog}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" >
            <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
              Confirm Payment
            </Typography>
            <IconButton aria-label="close" color="secondary" onClick={handleClosePaymentDialog}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Container disableGutters sx={{ py: 1, }}>
            <InboundConfirmPayment cb={handleConfirmPaymentSuccess} DollarAmount={props.DollarAmount} receiver={receiver!} />


          </Container>
        </DialogContent>
      </Dialog>
      <Dialog open={openNewReceiverDialog} onClose={handleCloseNewReceiverDialog}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" >
            <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
              New Receiver
            </Typography>
            <IconButton aria-label="close" color="secondary" onClick={handleCloseNewReceiverDialog}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Container disableGutters sx={{ py: 1, my: 1 }}>
            <InboundReceiverForm cb={handleNewReceiverSuccess} formValues={initialInboundReceiver} buttonText="Next" />
          </Container>
        </DialogContent>
      </Dialog>
      {/* transaction completed */}
      <Dialog open={openTransactionCompleteDialog} onClose={handleCloseTransactionCompletedDialog}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" >
            <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
              Transaction Submitted
            </Typography>
            <IconButton aria-label="close" color="secondary" onClick={handleCloseTransactionCompletedDialog}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ textAlign: 'center', minWidth: 500 }}>
          <CheckCircle sx={{ color: '#60C637', fontSize: 100, m: 3 }} />
          <Typography variant="h6" sx={{ fontSize: 24 }} component="div" >
            Thank you for your payment.
          </Typography>
          <Typography variant="body1" component="div" >
            Please Deposit funds into the below bank
          </Typography>
          <Divider sx={{ my: 1 }} />

          <Grid container spacing={1} sx={{ textAlign: 'left', pl: 20 }}>
            <Grid item xs={4}>
              <Typography variant="body1" component="div" >
                PAYID:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" component="div" >
                {state.affiliate.bankDetail.payId}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" component="div" >
                BSB:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" component="div" >
                {state.affiliate.bankDetail.bsb}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" component="div" >
                ACCOUNT:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" component="div" >
                {state.affiliate.bankDetail.accountNumber}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" component="div" >
                REF:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" component="div" >
                {KAASITransactionID}
              </Typography>
            </Grid>
          </Grid>
          <Container disableGutters sx={{ py: 1, my: 1, mt: 2 }}>

            <Button variant="contained" onClick={handleCloseTransactionCompletedDialog}>Close</Button>
          </Container>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateInBoundTransaction;
