
import React from "react";
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, Backdrop, Button, CircularProgress, Divider, Grid, MenuItem, TextField } from "@mui/material";
import { useStateStore } from "../../store";
import { FormItem } from "../../models/common";
import { OutboundQuote } from "../../models/Outbound";
import { formatMoney, getBankingTypeText } from "../../utils/common";
import { OutboundReceiver, OutboundReceiverAccount } from "../../models/Outbound";
import { getOutboundQuote } from "../../services/OutboundApis";
import { SelectOutboundReceiverProps } from "./CreateOutBoundTransaction";
import { TransactionFormItems } from "./TransactionFormItems";
import FormItemComponent from "../FormItem";
import { toast } from "react-toastify";

const outboundTransaction: any = {
  Purpose: '',
  SourceOfFunds: '',
  // RouteToExternalBank: '',
};

const validationSchema = yup.object({
  Purpose: yup
    .string()
    .max(250, 'Maximum 250 letters')
    .required('Purpose is required'),
  SourceOfFunds: yup
    .string()
    .max(50, 'Maximum 50 letters')
    .required('Source Of Funds is required'),
  RouteToExternalBank: yup
    .string(),
});

const formValues2FormItemsArray = (formValues: any): FormItem[] => {
  const formItemsArray: FormItem[] = [];
  for (var key of Object.keys(formValues)) {
    if (TransactionFormItems[key]) {
      switch (key) {
        // case 'QuoteId':
        //   var t = TransactionFormItems[key];
        //   t.isHidden = true;
        //   formItemsArray.push(t)
        //   break;
        default:
          formItemsArray.push(TransactionFormItems[key])
          break;
      }
    };
  }
  return formItemsArray;
}

const OutboundConfirmPayment = (props: { cb: Function, receiver: OutboundReceiver, receiverAccount: OutboundReceiverAccount, transactionDetail: SelectOutboundReceiverProps }) => {
  const state = useStateStore();
  const [showQuote, setShowQuote] = React.useState<boolean>(false);
  const [loadingQuotes, setLoadingQuotes] = React.useState<boolean>(false);
  const [quotes, setQuotes] = React.useState<OutboundQuote[]>([]);
  const [quoteId, setQuoteId] = React.useState<string>();
  const [amountLock, setamountLock] = React.useState<string>('Fixed AUD');
  const totalAmountPayable = Number(props.transactionDetail.ServiceCharges) + Number(props.transactionDetail.SendingAmount);

  const formik = useFormik({
    initialValues: {
      Purpose: '',
      SourceOfFunds: '',
      RouteToExternalBank: '',
      QuoteId: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
        if (showQuote && !quoteId) {
          toast.error('Please get quote.', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          return;
        }
        props.cb(values.Purpose, values.SourceOfFunds);
    },
  });

  React.useEffect(() => {
    var routeToExternalBank = (formik.values.RouteToExternalBank) as unknown as number;
    if (routeToExternalBank === 8) {
      setShowQuote(true);
    }
  }, [formik.values]);

  const getQuote = async () => {
    try {
      setLoadingQuotes(true);
      const res = await getOutboundQuote(state.affiliate.affiliateId, {
        CountryCode: props.transactionDetail.ReceivingCountryCode,
        CurrencyCode: props.transactionDetail.ReceivingCurrency,
        AmountLock: amountLock,
        Amount: String(props.transactionDetail.SendingAmount),
      });
      console.log(res.ExternalProviderQuotes);
      const fetchedQuotes = res.ExternalProviderQuotes as OutboundQuote[];
      if (fetchedQuotes.length > 0) {
        setQuotes(fetchedQuotes);
        setQuoteId(fetchedQuotes[0].QuoteId);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingQuotes(false);
    }

  }


  const formItemsArray = formValues2FormItemsArray(outboundTransaction)

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="center" sx={{ pb: 1, borderBottom: 1, borderBottomColor: '#ccc', width: 1 }}>
            <Typography variant="h4" component="span" sx={{ fontWeight: 'bold', fontSize: 29 }}>
              {props.transactionDetail.SendingCurrency} {formatMoney(props.transactionDetail.SendingAmount)}
            </Typography>
            <NavigateNextIcon fontSize="large" sx={{ display: { xs: 'none', sm: 'inline' } }} />
            <ExpandMoreIcon fontSize="large" sx={{ display: { xs: 'block', sm: 'none' } }} />
            <Typography variant="h4" component="span" sx={{ fontWeight: 'bold', fontSize: 29 }}>
              {props.transactionDetail.ReceivingCurrency} {formatMoney(props.transactionDetail.ReceivingForeignAmount)}
            </Typography>
          </Stack>
          <Typography color="#777" variant="body2" component="div" sx={{ pt: 0 }}>
            Exchange Rate: 1.00 {props.transactionDetail.SendingCurrency} = {formatMoney(props.transactionDetail.Rate)} {props.transactionDetail.ReceivingCurrency}
          </Typography>
          <Typography color="#777" variant="body2" component="div" sx={{ pt: 0 }}>
            Service Charges: {formatMoney(props.transactionDetail.ServiceCharges)} {props.transactionDetail.SendingCurrency}
          </Typography>
          <Typography color="#777" variant="body2" component="div" sx={{ pt: 0, fontWeight: 'bold', color: '000' }}>
            Total Amount Payable: {formatMoney(totalAmountPayable)} {props.transactionDetail.SendingCurrency}
          </Typography>
        </Stack>
        <Divider sx={{ py: 1 }} />
        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
          <Typography variant="h6" component="div" sx={{ mt: 1 }}>
            Send To
          </Typography>
          <Typography color="#777" variant="body2" component="div">
            Country: {props.receiver.country}
          </Typography>
          <Typography color="#777" variant="body2" component="div">
            Name: {props.receiver.firstName} {props.receiver.lastName}
          </Typography>
          <Typography color="#777" variant="body2" component="div">
            Account: {getBankingTypeText(props.receiverAccount.bankingType)} - {props.receiverAccount.accountNumber}
          </Typography>
        </Stack>
        <Divider sx={{ py: 1, mb: 2 }} />
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Grid container spacing={2} alignItems='center'>
            {formItemsArray.map((item: FormItem) =>
              <FormItemComponent isDense key={item.key} item={item} formik={formik} validationSchema={validationSchema} />
            )}
            {showQuote &&
              <React.Fragment>
                <Grid item xs={12} sm={12}>
                    <Alert sx={{ width: 1 }} severity="info">Need get quote(s) first.</Alert>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    select
                    size="small"
                    variant="outlined"
                    fullWidth
                    id='AmountLock'
                    name='AmountLock'
                    label='Amount Lock'
                    value={amountLock}
                    onChange={(e) => setamountLock(e.target.value)}
                  >
                    <MenuItem value='Fixed AUD'>Fixed AUD</MenuItem>
                    <MenuItem value='Fixed Foreign'>Fixed Foreign</MenuItem>
                  </TextField>
                </Grid>
                <Box sx={{ width: 1, textAlign: 'center', mt: 1 }}><Button onClick={getQuote} >Get quote</Button></Box>
                {(quotes.length > 0) &&
                  <Grid item xs={12} sm={12}>
                    <TextField
                      size="small"
                      select
                      variant="outlined"
                      fullWidth
                      id='quoteId'
                      name='quoteId'
                      label='Select quote'
                      value={quoteId || quotes[0].QuoteId}
                      onChange={(e) => setQuoteId(e.target.value)}
                    >
                      {quotes.map(quote =>
                        <MenuItem key={quote.QuoteId} value={quote.QuoteId}>Rate: {quote.Rate}({quote.RateProvider})</MenuItem>
                      )}

                    </TextField>
                  </Grid>
                }

                <Divider />
              </React.Fragment>

            }
            <Box sx={{ width: 1, pt: 3, display: 'flex', justifyContent: 'center' }}>
              <Button size="large" color="primary" variant="contained" sx={{ width: 1 / 2, }} type="submit">
                Submit
              </Button>
            </Box>
          </Grid>
        </Stack>
      </form>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingQuotes}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default OutboundConfirmPayment;
