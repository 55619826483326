import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import Layout from '../components/Layout';
import { useStateStore } from '../store';
import { Container, Divider, Typography } from '@mui/material';

const ContactUs = () => {
  const state = useStateStore();
  return (
    <Layout>
      <Container maxWidth="xl" disableGutters sx={{ p: { xs: 5, sm: 8 } }}>
        <Typography variant='h4' sx={{ my: 2 }} >Contact Us</Typography>
        <Divider />
        {state.affiliate.contactUs && documentToReactComponents(state.affiliate.contactUs['json'] as Document)}
      </Container>
    </Layout>
  )
};

export default ContactUs;
