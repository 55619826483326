import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { toast } from "react-toastify";
import { Avatar, Card, Container, Grid, Typography, Divider, CardHeader, Box, Stack, Button, DialogTitle, IconButton, Link } from "@mui/material";
import AccountMenu from "../../components/AccountMenu";
import InboundReceiverForm from "../../components/inbound/ReceiverForm";
import Loading from "../../components/Loading";
import Layout from "../../components/Layout";
import { CreateInboundReceiverRequest, InboundReceiver } from "../../models/Inbound";
import { useStateStore } from "../../store";
import { fetchInboundReceivers } from "../../services/InboundApis";
import { SenderProfile } from "../../models/common";

export const initialInboundReceiver: CreateInboundReceiverRequest = {
  AffiliateReceiverID: '',
  KAASISenderID: 0,
  FullName: '',
  EmailAddress: '',
  AddressLine1: '',
  Suburb: '',
  State: '',
  Postcode: '',
  Country: 'AUS',
  MobileInternationalDialCode: '61',
  Mobile: '',
  BankCode: '',
  BSB: '',
  AccountNumber: '',
  IndividualOrBusiness: '',
  ABN: '',
  ACN: '',
  Occupation: '',
  DOB: '',
  // AKA: '',
};


const Receivers = () => {
  const [receiverFormValue, setReceiverFormValue] = React.useState<CreateInboundReceiverRequest>(initialInboundReceiver);
  const [openNewReceiver, setOpenNewReceiver] = React.useState(false);
  const [openReceiverDetail, setOpenReceiverDetail] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [receivers, seteceivers] = React.useState<InboundReceiver[]>([]);
  const [receiverDetail, setReceiverDetail] = React.useState<InboundReceiver>();
  const state = useStateStore();


  React.useEffect(() => {
    if (state.profile) getReceivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.profile]);

  const getReceivers = async () => {
    try {
      setLoading(true);
      const res = await fetchInboundReceivers(state.affiliate.affiliateId, (state.profile as unknown as SenderProfile)?.ExSenderId);
      seteceivers(res.receivers);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const handleNewReceiver = () => {
    setReceiverFormValue(initialInboundReceiver);
    setOpenNewReceiver(true);
  };


  const viewReceiverDetail = (index: number) => {
    const receiver = receivers[index];
    setReceiverDetail(receiver);
    setOpenReceiverDetail(true);
  };

  const handleCloseNewReceiver = () => {
    setOpenNewReceiver(false);
  };

  const handleCloseReceiverDetail = () => {
    setOpenReceiverDetail(false);
  };

  const handleReceiverModalSuccess = (receiverId: string, receiverList: InboundReceiver[]) => {
    toast.success('Your information has been submitted.', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    setOpenNewReceiver(false);
    seteceivers(receiverList);
  }

  const getReceiversWidget = () => {
    if (loading) return <Loading />;
    if (!loading && receivers.length === 0) {
      return (
        <Box sx={{ textAlign: 'center', p: 8 }}><Typography sx={{ color: '#666' }} variant="caption" component="span">
          No Receiver Found
        </Typography></Box>
      );
    }

    return receivers.map((receiver: InboundReceiver, index: number) =>
      <Card key={receiver.kaasiReceiverID} variant="outlined" sx={{ my: 2, pr: 2 }}>
        <CardHeader
          avatar={
            <Box sx={{ display: 'flex', alignItems: 'center', opacity: 0.6 }}>
              <Avatar sx={{ bgcolor: "#" + Math.floor(Math.random() * 0xFFFFFF).toString(16) }} aria-label="recipe">
                {receiver.firstName.substring(0, 1)}
              </Avatar>
              <Typography component="span" sx={{ ml: 1, fontSize: { xs: 18, sm: 22 } }}>
                {receiver.firstName} {receiver.lastName}
              </Typography>
            </Box>
          }
          action={
            <Box sx={{ display: 'flex', mt: 2 }}>
              <Link sx={{ mr: 1 }} component="button" onClick={() => viewReceiverDetail(index)}><Typography variant="body1">Detail</Typography></Link>
            </Box>
          }
        />
      </Card>
    );
  }

  return (
    <Layout>
      <Container maxWidth="xl" sx={{ p: 3 }}>
        <Grid container spacing={5} justifyContent="center"
          alignItems="flex-start">
          <Grid item xs={2} sm={2} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <AccountMenu selected="receivers" />
          </Grid>
          <Grid item xs={12} sm={10}>

            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" sx={{ pb: 2 }} >
              <Typography variant="h4" component="div" >
                Receivers
              </Typography>
              <Button sx={{ mt: { xs: 2, sm: 0 } }} variant="outlined" startIcon={<AddIcon />} onClick={handleNewReceiver}>
                Add New Receiver
              </Button>
            </Stack>
            <Divider />
            {getReceiversWidget()}
          </Grid>
        </Grid>
        <Dialog open={openNewReceiver} onClose={handleCloseNewReceiver}>
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between" >
              <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
                Add New Receiver
              </Typography>
              <IconButton aria-label="close" color="secondary" onClick={handleCloseNewReceiver}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Container disableGutters sx={{ py: 1, my: 1 }}>
              <InboundReceiverForm cb={handleReceiverModalSuccess} formValues={receiverFormValue} />
            </Container>
          </DialogContent>
        </Dialog>
        {receiverDetail &&
          <Dialog open={openReceiverDetail} onClose={handleCloseReceiverDetail}>
            <DialogTitle>
              <Stack direction="row" justifyContent="space-between" >
                <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
                  Receiver Detail
                </Typography>
                <IconButton aria-label="close" color="secondary" onClick={handleCloseReceiverDetail}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Container disableGutters sx={{ py: 1, my: 1, minWidth: 400 }}>
                <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
                  <Typography color="#777" variant="body1" component="div">
                    First Name: {receiverDetail!.firstName}
                  </Typography>
                  <Typography color="#777" variant="body1" component="div">
                    Last Name: {receiverDetail!.lastName}
                  </Typography>
                  <Typography color="#777" variant="body1" component="div">
                    Email Address: {receiverDetail!.email}
                  </Typography>
                  <Typography color="#777" variant="body1" component="div">
                    Mobile International Dial Code: {receiverDetail!.metaData.MobileInternationalDialCode}
                  </Typography>
                  <Typography color="#777" variant="body1" component="div">
                    Mobile: {receiverDetail!.mobile}
                  </Typography>
                  <Typography color="#777" variant="body1" component="div">
                    Address: {receiverDetail!.addressLine1}
                  </Typography>
                  <Typography color="#777" variant="body1" component="div">
                    Suburb: {receiverDetail!.suburb}
                  </Typography>
                  <Typography color="#777" variant="body1" component="div">
                    State: {receiverDetail!.state}
                  </Typography>
                  <Typography color="#777" variant="body1" component="div">
                    Postcode: {receiverDetail!.postcode}
                  </Typography>
                  <Typography color="#777" variant="body1" component="div">
                    Country: {receiverDetail!.country}
                  </Typography>
                  <Typography color="#777" variant="body1" component="div">
                    Occupation: {receiverDetail!.occupation}
                  </Typography>
                  <Typography color="#777" variant="body1" component="div">
                    Date Of Birth: {receiverDetail!.dob}
                  </Typography>
                  <Typography color="#777" variant="body1" component="div">
                    Bank Code: {receiverDetail!.metaData.BankCode}
                  </Typography>
                  <Typography color="#777" variant="body1" component="div">
                    BSB: {receiverDetail!.metaData.Bsb}
                  </Typography>
                  <Typography color="#777" variant="body1" component="div">
                    Account Number: {receiverDetail!.metaData.AccountNumber}
                  </Typography>
                  <Typography color="#777" variant="body1" component="div">
                    is Individual: {receiverDetail!.isIndividual}
                  </Typography>
                  {!receiverDetail!.isIndividual ?
                    <React.Fragment>
                      <Typography color="#777" variant="body1" component="div">
                        ABN: {receiverDetail!.metaData.Abn}
                      </Typography>
                      <Typography color="#777" variant="body1" component="div">
                        ACN: {receiverDetail!.metaData.Acn}
                      </Typography>
                    </React.Fragment>
                    : null}
                </Stack>

              </Container>
            </DialogContent>
          </Dialog>
        }
      </Container >
    </Layout>
  );
};

export default withAuthenticationRequired(Receivers, {
  onRedirecting: () => <Loading />,
});
