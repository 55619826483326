

const devContentful = {
  CONTENTFUL_SPACEID: 'xv3o7n0dmqrh',
  CONTENTFUL_ACCESSTOKEN: 'Rtq97Kun7CUWwc8pKRayJfzwuJjB0_lyBj_iOsPtG_Q',
  CONTENTFUL_ENV: 'dev',
}

const prodContentful = {
  CONTENTFUL_SPACEID: 'xv3o7n0dmqrh',
  CONTENTFUL_ACCESSTOKEN: 'Rtq97Kun7CUWwc8pKRayJfzwuJjB0_lyBj_iOsPtG_Q',
  CONTENTFUL_ENV: 'master',
}

const getContentfulConfig = (env: string) => {
  if (env === 'production') {
    return prodContentful;
  }
  return devContentful;
}

export default getContentfulConfig(process.env.REACT_APP_ENV!);