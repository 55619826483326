import { Shadows } from "@mui/material/styles/shadows";

const themeOptions = {
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#074898',
      light: '#E6ECF4',
    },
    secondary: {
      main: '#4d4d4d',
    },
  },
  shadows: [
    "none",
    "0px 2px 4px -1px rgba(0,0,0,0.15)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "none", "none", "none", "none", "none", "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none",
  ] as Shadows,
  components: {
    // Name of the component
    MuiStepLabel: {
      styleOverrides: {
        // Name of the slot
        label: {
          // Some CSS
          fontSize: '1rem',
          "&.Mui-active": {
            fontWeight: 600,
          }
        },
      },
    },
  },
};

export default themeOptions;
