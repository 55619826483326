
import React from "react";
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AppStore from '../images/app-store.svg';
import PlayStore from '../images/google-play.svg';
import { useStateStore } from "../store";

const Footer = () => {
  const state = useStateStore();
  return (
    <Container maxWidth={false} sx={{ p: 3, bgcolor: '#323232' }}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        {state.affiliate.app?.showAppDownload && (
          <>
            <Typography variant="body1" sx={{ color: '#ccc' }} component="span">
              Download our App
            </Typography>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Link href={state.affiliate.app?.appStoreUrl}>
                <img src={AppStore} alt="app store" />
              </Link>
              <Link href={state.affiliate.app?.googlePlayUrl}>
                <img src={PlayStore} alt="play store" />
              </Link>
            </Stack>
          </>
        )}
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems='center'
          justifyContent='center'
          spacing={{ xs: 1, sm: 3 }}
          sx={{
            typography: 'body1',
            '& > :not(style)': {
              color: '#ccc',
            },
          }}
        >
          <Link href={`/${state.affiliate.shortName}/terms`} underline="none">
            Terms and Conditions
          </Link>
          <Link href={`/${state.affiliate.shortName}/privacy`} underline="none">
            Privacy
          </Link>
          <Link href={`/${state.affiliate.shortName}/contact`} underline="none">
            Contact Us
          </Link>
        </Stack>
        <Typography variant="body1" sx={{ color: '#ccc' }} component="span">
          © {state.affiliate.name} {(new Date()).getFullYear()} (v{process.env.REACT_APP_VERSION})
        </Typography>
      </Stack>
    </Container>
  );
};

export default Footer;
