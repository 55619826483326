import { FormItem, FormItemType } from "../../models/common";
import { AUSTATES } from "../../utils/common";
import Countries from '../../utils/countryCodeList.json';

export const UploadDocumentFormItems: { [key: string]: FormItem; } = {
  DocumentType: {
    key: 'DocumentType',
    label: 'Document Type',
    type: FormItemType.SELECT,
    width: 6,
    isRequired: true,
    selectValues: [
      {
        label: 'Passport',
        value: 'P',
      },
      {
        label: "Driver's license",
        value: 'DL',
      },
      {
        label: 'Photo ID',
        value: 'PID',
      },
    ]
  },
  DocumentNumber: {
    key: 'DocumentNumber',
    label: 'Document Number',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 50,
    isRequired: true,
  },
  DocIssueCountry: {
    key: 'DocIssueCountry',
    label: 'Issuing Country',
    type: FormItemType.SELECT,
    width: 6,
    selectValues: Countries.map((i: any) => ({ value: i.value, label: i.label })),
    isRequired: false,
    isHidden: true,
  },
  DocIssueState: {
    key: 'DocIssueState',
    label: 'Issuing State',
    type: FormItemType.SELECT,
    width: 6,
    selectValues: AUSTATES,
    isRequired: false,
  },
  CardNumber: {
    key: 'CardNumber',
    label: 'Card Number',
    type: FormItemType.TEXTINPUT,
    width: 3,
    isRequired: false,
    isHidden: true,
  },
  ExpiryDate: {
    key: 'ExpiryDate',
    label: 'Expiry Date',
    type: FormItemType.DATEPICKER,
    width: 3,
    minDate: new Date((new Date()).getTime() + 86400000),
    maxDate: new Date('2121-01-01T00:00:00Z'),
    isRequired: false,
  },
  IssueDate: {
    key: 'IssueDate',
    label: 'Issue Date',
    type: FormItemType.DATEPICKER,
    width: 3,
    isRequired: false,
  },
  DocumentFront: {
    key: 'DocumentFront',
    label: 'Front of Document',
    type: FormItemType.DOCUMENT,
    width: 12,
    isRequired: true,
  },
  DocumentBack: {
    key: 'DocumentBack',
    label: 'Back of Document',
    type: FormItemType.DOCUMENT,
    width: 12,
    isRequired: false,
  },
  Support_DocumentType: {
    key: 'Support_DocumentType',
    label: 'Supporting Document Type',
    type: FormItemType.SELECT,
    width: 6,
    isRequired: false,
    selectValues: [
      {
        label: 'Invoice',
        value: 'Invoice',
      },
      {
        label: "Pay Slips",
        value: 'Pay Slips',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ]
  },
  Support_DocumentName: {
    key: 'Support_DocumentName',
    label: 'Supporting Document Name',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 495,
    isRequired: false,
  },
  Support_Description: {
    key: 'Support_Description',
    label: 'Supporting Document Description',
    type: FormItemType.TEXTINPUT,
    width: 12,
    maxLength: 1500,
    maxRows: 3,
    isRequired: false,
  },
  Support_TransactionID: {
    key: 'Support_TransactionID',
    label: 'Transaction ID',
    type: FormItemType.TEXTINPUT,
    width: 6,
    isRequired: false,
  },
  Support_Document: {
    key: 'Support_Document',
    label: 'Front of Supporting Document',
    type: FormItemType.SUPPORT_DOCUMENT,
    width: 12,
    isRequired: false,
  },
}

