import React from "react";
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import _ from "lodash";
import { Button, Stack, Grid, } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { FormItem, SenderProfile } from "../../models/common";
import { ReceiverFormItems } from "./ReceiverFormItems";
import FormItemComponent from "../FormItem";
import { useStateStore } from "../../store";
import { CreateOutboundReceiver, fetchOutboundReceivers, UpdateOutboundReceiver } from "../../services/OutboundApis";
import { CreateOutboundReceiverRequest, OutboundReceiver, UpdateOutboundReceiverRequest } from "../../models/Outbound";
import Countries from '../../utils/countryCodeList.json';

const validationSchema = yup.object({
  FirstName: yup
    .string()
    .required('First Name is required')
    .matches(/^[a-zA-Z\s-]+$/, "Only allow alphabet letters, spaces and hyphens.")
    .max(70, 'Maximum 70 letters'),
  LastName: yup
    .string()
    .required('Last Name is required')
    .matches(/^[a-zA-Z\s-]+$/, "Only allow alphabet letters, spaces and hyphens.")
    .max(70, 'Maximum 70 letters'),
  AddressLine1: yup
    .string()
    .required('Address is required')
    .max(140, 'Maximum 140 letters')
    .matches(/^[a-zA-Z0-9\s-,/]+$/, "Only allow characters, spaces, hyphens, commas and forward slash."),

  Suburb: yup
    .string()
    .max(35, 'Maximum 35 letters')
    .matches(/^[a-zA-Z0-9\s-]+$/, "Only allow alphabet letters, numbers, spaces and hyphens."),
  State: yup
    .string()
    .max(35, 'Maximum 35 letters')
    .matches(/^[a-zA-Z\s-]+$/, "Only allow alphabet letters, spaces and hyphens."),
  Postcode: yup
    .string()
    .max(15, 'Maximum 15 letters')
    .matches(/^[a-zA-Z0-9\s-/]+$/, "Only allow characters, spaces, hyphens and forward slash."),
  Country: yup
    .string()
    .required('Country is required'),
  MobileInternationalDialCode: yup
    .string(),
  Mobile: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(13, 'Maximum 13 digits'),
  EmailAddress: yup
    .string()
    .email('Enter a valid email')
    .max(200, 'Maximum 200 letters'),
  // .required('Email is required'),
  DateOfBirth: yup
    // .date()
    .string()
    .nullable(),
  // .required('DateOfBirth is required'),
  Occupation: yup.string(),
  Nationality: yup.string().max(200, 'Maximum 200 letters'),
  Relationship: yup.string().max(100, 'Maximum 100 letters'),
});

const formValues2FormItemsArray = (formValues: any): FormItem[] => {
  const formItemsArray: FormItem[] = [];
  for (var key of Object.keys(formValues)) {
    if (ReceiverFormItems[key]) {
      switch (key) {
        case 'MobileInternationalDialCode':
        case 'TelephoneInternationalDialCode':
          formItemsArray.push({
            ...ReceiverFormItems[key],
            isDisabled: true,
          })
          break;
        default:
          formItemsArray.push(ReceiverFormItems[key])
          break;
      }
    };
  }
  return formItemsArray;
}

interface ReceiverFormProps {
  formValues: CreateOutboundReceiverRequest;
  cb: Function,
  buttonText?: string;
}


const OutboundReceiverForm = ({ formValues, cb, buttonText }: ReceiverFormProps) => {
  const [submitting, setSubmitting] = React.useState(false);
  const state = useStateStore();
  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit: async (values: CreateOutboundReceiverRequest) => {
      try {
        setSubmitting(true);
        if (values.kaasiReceiverID) { // TODO: update
          await UpdateOutboundReceiver(state.affiliate.affiliateId, {
            ...values,
            ReceiverID: values.kaasiReceiverID,
          } as UpdateOutboundReceiverRequest,)
          const listRes = await fetchOutboundReceivers(state.affiliate.affiliateId, (state.profile as unknown as SenderProfile)?.ExSenderId);

          cb(values.AffiliateReceiverID, listRes.receivers as OutboundReceiver[]);
        } else { // create
          const res = await CreateOutboundReceiver(state.affiliate.affiliateId,
            {
              ...values,
              AffiliateReceiverID: uuidv4(),
              SenderID: parseInt((state.profile as unknown as SenderProfile)?.ExSenderId),
            } as CreateOutboundReceiverRequest,
          );
          const listRes = await fetchOutboundReceivers(state.affiliate.affiliateId, (state.profile as unknown as SenderProfile)?.ExSenderId);
          cb(res.KAASIReceiverID,  listRes.receivers as OutboundReceiver[]);
        }
      } catch (error) {
        console.log(error)
      } finally {
        setSubmitting(false);
      }
    },
  });

  // set mobile country code by selected country
  React.useEffect(() => {
    if (formik.values.Country) {
      formik.values.MobileInternationalDialCode = _.find(Countries, e => e.value === formik.values.Country)?.phone;
    }
  }, [formik.values]);

  const formItemsArray = formValues2FormItemsArray(formValues);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack alignItems='center' spacing={4}>
        <Grid container spacing={2} alignItems='center'>
          {formItemsArray.map((item: FormItem) =>
            <FormItemComponent key={item.key} item={item} formik={formik} validationSchema={validationSchema} />
          )}
        </Grid>
        <Button size="large" color="primary" variant="contained" sx={{ width: 1 / 2 }} type="submit">
          {buttonText || 'Submit'}
        </Button>
      </Stack>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  );
};

export default OutboundReceiverForm;
