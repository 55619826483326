import { Typography, Stack, IconButton, Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface DepositMethodDialogProps {
  title: string,
  url: string
  open: boolean,
  onClose: Function
}

// This component can be use for payment method which connects by link
const DepositMethodDialog = ({title, url, open, onClose}: DepositMethodDialogProps)  => {
  return (
    <Dialog open={open} onClose={() => onClose()}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" >
            <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>Make Payment</Typography>
            <IconButton aria-label="close" color="secondary" onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ padding: 0, overflowX: "hidden" }}>
          <iframe title={title} src={url} width="450" height="610" />
        </DialogContent>
      </Dialog>
  );
};

export default DepositMethodDialog;
