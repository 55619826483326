import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Stack, Typography, Box, Button } from '@mui/material';
import qs from 'qs';
import { getConfig } from '../config';
import getAffiliateCodeByDomain from '../affiliate_domain';

const config = getConfig();

const EmptyHome = (props: any) => {
  let affiliationShortName =  qs.parse(props.location.search, { ignoreQueryPrefix: true }).affiliationShortName;
  const domain = window.location.host;
  if (!affiliationShortName) {
    affiliationShortName = getAffiliateCodeByDomain(domain);
  }

  if (affiliationShortName) props.history.push(`/${affiliationShortName}`);
  return (
    <Stack direction="column" spacing={4} alignItems="center" sx={{ py: { xs: 2, sm: 10 } }}>
      <Box sx={{ textAlign: 'center' }}>
        <Box>
          <Typography component="span" sx={{ fontSize: { xs: 26, sm: 32, color: '#074898' }, }}>
            NOVA
          </Typography>
          <Typography sx={{ fontWeight: 'Bold', color: '#333', fontSize: { xs: 26, sm: 32 }, }} component="span">
            REMIT
          </Typography>
        </Box>
        <Typography variant="body1" gutterBottom component="div">
          Welcome to NovaRemit!
        </Typography>

      </Box>
      <Button color="info" component={RouterLink} to={`/${config.demoAffiliates.outbound}`}  variant="outlined" sx={{ height: 40,  }}>Test Outbound Affiliate</Button>
        <Button color="info" component={RouterLink} to={`/${config.demoAffiliates.inbound}`}  variant="outlined" sx={{ height: 40, }}>Test Inbound Affiliate</Button>
    </Stack>
  );
}

export default EmptyHome;
