import React from 'react';
import { Button, Stack, Typography, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircle';
import { useAuth0 } from '@auth0/auth0-react';
import Layout from '../components/Layout';
import { useStateStore } from '../store';

const EmailVerificationSuccessForAffiliate = (props: any) => {
  const {
    error,
    isAuthenticated,
    loginWithRedirect
  } = useAuth0();
  const state = useStateStore();

  if (isAuthenticated) props.history.push(`/${state.affiliate.shortName}`)
  if (error) props.history.push(`/callback?affiliationShortName=${state.affiliate.shortName}`)

  const redirectUrl: string = `${window.location.origin}/callback?affiliationShortName=${state.affiliate.shortName}`;

  return (
    <Layout>
      <Stack direction="column" spacing={4} alignItems="center" sx={{ py: { xs: 2, sm: 10 } }}>
        <CheckCircleOutlineIcon sx={{ color: '#4caf50', fontSize: 80, }} />
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom component="div" sx={{ fontWeight: 'bold' }}>
            Email Verified!
          </Typography>
          <Typography variant="body1" gutterBottom component="div">
            You have successfully verified email.
          </Typography>
        </Box>
        <Button size="large" onClick={() => loginWithRedirect({ prompt: 'login', redirectUri: redirectUrl })} variant="contained">Log in</Button>
      </Stack>
    </Layout>
  );
}



export default EmailVerificationSuccessForAffiliate;
