import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Alert, Button, Stack, AlertTitle } from '@mui/material';


const NoMatch = () => {
  return (
    <Stack direction="column" spacing={4} alignItems="center" sx={{ py: { xs: 4, sm: 10 } }}>
      <Alert severity="info">
        <AlertTitle>404 Page Not Found</AlertTitle>
      </Alert>
      <Button component={RouterLink} to="/" variant="contained">Home</Button>
    </Stack>
  );
}



export default NoMatch;
