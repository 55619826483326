import { FormItem, FormItemType } from "../../models/common";
// import Countries from '../../utils/countryCodeList.json';
// import Nationalities from '../../utils/Nationalities.json';

export const ReceiverAccountEditFormItems: { [key: string]: FormItem; } = {
  BankIdentification: {
    key: 'BankIdentification',
    label: 'Bank Name',
    type: FormItemType.SELECT,
    width: 12,
    selectValues: [],
    isHidden: true,
  },
  BranchIdentification: {
    key: 'BranchIdentification',
    label: 'Branch Name',
    type: FormItemType.SELECT,
    width: 8,
    selectValues: [],
    isHidden: true
  },
  AccountName: {
    key: 'AccountName',
    label: 'Account Name',
    type: FormItemType.TEXTINPUT,
    width: 6,
    isRequired: true,
  },
  AccountNumber: {
    key: 'AccountNumber',
    label: 'Account Number',
    type: FormItemType.TEXTINPUT,
    width: 6,
    isRequired: true,
  },
  CurrencyCode: {
    key: 'CurrencyCode',
    label: 'Currency Code',
    type: FormItemType.CURRENCY,
    width: 6,
    isRequired: true,
  },
  BankCountryCode: {
    key: 'BankCountryCode',
    label: 'Country Code',
    type: FormItemType.TEXTINPUT,
    width: 6,
    isRequired: true,
    isDisabled: true,
  },
  IsReceiverAccountActive: {
    key: 'IsReceiverAccountActive',
    label: 'Is Receiver Account Active',
    type: FormItemType.SWITH,
    width: 12,
  },
  IFSC: {
    key: 'IFSC',
    label: 'IFSC',
    type: FormItemType.TEXTINPUT,
    width: 12,
  },
  BankCode: {
    key: 'BankCode',
    label: 'Bank Code',
    type: FormItemType.TEXTINPUT,
    width: 6,
    isHidden: true
  },
  SWIFT: {
    key: 'SWIFT',
    label: 'SWIFT',
    type: FormItemType.TEXTINPUT,
    width: 12,
  },
  WalletProviderId: {
    key: 'WalletProviderId',
    label: 'Wallet Provider Id',
    type: FormItemType.TEXTINPUT,
    width: 12,
    isHidden: true
  },
  IBAN: {
    key: 'IBAN',
    label: 'IBAN',
    type: FormItemType.TEXTINPUT,
    width: 12,
  },
  CNAP: {
    key: 'CNAP',
    label: 'CNAP',
    type: FormItemType.TEXTINPUT,
    width: 12,
  },
  RippleServiceCenterCode: {
    key: 'RippleServiceCenterCode',
    label: 'Ripple Service Center Code',
    type: FormItemType.TEXTINPUT,
    width: 12,
  },
  PayoutPartnerCode: {
    key: 'PayoutPartnerCode',
    label: 'Payout Partner Code',
    type: FormItemType.TEXTINPUT,
    width: 12,
  },
  BankBranchCode: {
    key: 'BankBranchCode',
    label: 'Bank Branch Code',
    type: FormItemType.TEXTINPUT,
    width: 6,
    isHidden: true
  },
  BankingType: {
    key: 'BankingType',
    label: 'Payment Method',
    type: FormItemType.SELECT,
    width: 12,
    isRequired: true,
    selectValues: [
      {
        label: 'Bank Transfer',
        value: '2',
      },
      {
        label: 'Transfer to Wallet',
        value: '4',
      },
    ]
  },
  BranchCity: {
    key: 'BranchCity',
    label: 'City',
    type: FormItemType.SELECT,
    width: 4,
    selectValues: [],
    isHidden: true
  },
}

