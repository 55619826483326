import React from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MoneyInput from "./MoneyInput";
// import DeliveryMethod from "./DeliveryMethod";
import { Box } from "@mui/system";
import { useAuth0 } from "@auth0/auth0-react";
import { Affiliate, CFCountry, Country, Currency } from "../models/common";
import { useStateStore } from "../store";
import CreateOutBoundTransaction from "./transaction/CreateOutBoundTransaction";
import CreateInBoundTransaction from "./transaction/CreateInBoundTransaction";
import { CreateOutboundAffiliateQuoteRequest, OutboundAffiliateQuote } from "../models/Outbound";
import { getOutboundAffiliateQuote } from "../services/OutboundApis";
import { Backdrop, CircularProgress } from "@mui/material";
import _ from "lodash";
//@ts-ignore
import ReactCountryFlag from "react-country-flag"
import { toast } from "react-toastify";
import {
  // inboundRemittances,
  // outboundRemittances,
  Remittance
} from "./RemitData";
import { getCountry2Code } from "../utils/common";



// const getReceiverCountries = (type: string, sendCountry: Country): Country[] => {
//   const remittances = type === 'inbound' ? inboundRemittances : outboundRemittances;
//   return _.find(remittances, r => r.sendCountry.code === sendCountry.code)!.receiveCountries;
// }

const getRemittancesFromAffiliate = (affiliate: Affiliate): Remittance[] => {
  const remittances: Remittance[] = [];
  for (let index = 0; index < affiliate.sendCountriesCollection.items.length; index++) {
    const sendCountry = affiliate.sendCountriesCollection.items[index];
    remittances.push({
      sendCountry: {
        code: sendCountry.code,
        name: sendCountry.name,
        flag: <ReactCountryFlag style={{ fontSize: 26 }} svg countryCode={getCountry2Code(sendCountry.code)} />,
        currency: sendCountry.currencyCollection.items.map((c: Currency) => c.code),
      },
      receiveCountries: affiliate.receiveCountriesCollection.items.map((country: CFCountry) => ({
        code: country.code,
        name: country.name,
        flag: <ReactCountryFlag style={{ fontSize: 26 }} svg countryCode={getCountry2Code(country.code)} />,
        currency: country.currencyCollection.items.map((c: Currency) => c.code),
      }))
    })

  }
  return remittances;
}

const roundTo = function (num: number, places: number) {
  const factor = 10 ** places;
  return Math.round(num * factor) / factor;
};


const Remit = () => {
  const store = useStateStore();
  const {
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();
  const [remittances, setRemittances] = React.useState<Remittance[]>([]);
  const [senderCountries, setSenderCountries] = React.useState<Country[]>([]);
  const [receiverCountries, setReceiverCountries] = React.useState<Country[]>([]);
  const [senderCountry, setSenderCountry] = React.useState<Country>();
  const [senderCurrency, setSenderCurrency] = React.useState<string>();
  const [receiverCountry, setReceiverCountry] = React.useState<Country>();
  const [receiverCurrency, setReceiverCurrency] = React.useState<string>();
  const [sendAmount, setSendAmount] = React.useState<number>(1000);
  const [receiveAmount, setReceiveAmount] = React.useState<number>(0);
  const [rate, setRate] = React.useState<number>(0);
  const [serviceCharge, setServiceCharge] = React.useState<number>(0);
  const [initLoading, setInitLoading] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
    setInitLoading(true);
    // get remittances
    const remittances = getRemittancesFromAffiliate(store.affiliate);
    setRemittances(remittances);

    let senderCountries: Country[] = [];
    let receiverCountries: Country[] = []
    senderCountries = remittances.map((r: Remittance) => r.sendCountry);
    receiverCountries = remittances[0].receiveCountries;

    // if (store.affiliate.type === 'outbound') {
    //   senderCountries = getRemittancesFromAffiliate(store.affiliate).map((r: Remittance) => r.sendCountry);
    //   receiverCountries = outboundRemittances[0].receiveCountries;
    // }
    // if (store.affiliate.type === 'inbound') {
    //   senderCountries = getRemittancesFromAffiliate(store.affiliate).map((r: Remittance) => r.sendCountry);
    //   receiverCountries = inboundRemittances[0].receiveCountries;
    // }

    setSenderCountries(senderCountries);
    setSenderCountry(senderCountries[0]);
    setSenderCurrency(senderCountries[0].currency[0])
    setReceiverCountries(receiverCountries);
    setReceiverCountry(receiverCountries[0]);
    setReceiverCurrency(receiverCountries[0].currency[0])
    const payload: CreateOutboundAffiliateQuoteRequest = {
      originatingCountry: senderCountries[0].code,
      originatingCurrency: senderCountries[0].currency[0],
      destinationCountry: receiverCountries[0].code,
      destinationCurrency: receiverCountries[0].currency[0],
      amount: Number(sendAmount),
    };
    const quote = await getAffiliateQuote(payload, false);
    setReceiveAmount(roundTo(sendAmount * quote.Rate, 2));
    setInitLoading(false);
  }

  const handleChangeSenderCountry = async (country: Country) => {
    if (country.code === senderCountry!.code) return;
    setSenderCountry(country);
    setSenderCurrency(country.currency[0])
    const receiverCountries = _.find(remittances, r => r.sendCountry.code === country.code)!.receiveCountries;
    ;
    setReceiverCountries(receiverCountries)
    setReceiverCountry(receiverCountries[0]);
    setReceiverCurrency(receiverCountries[0].currency[0]);
    const payload: CreateOutboundAffiliateQuoteRequest = {
      originatingCountry: country.code,
      originatingCurrency: country.currency[0],
      destinationCountry: receiverCountries[0].code,
      destinationCurrency: receiverCountries[0].currency[0],
      amount: Number(sendAmount),
    };
    const quote = await getAffiliateQuote(payload);
    setReceiveAmount(roundTo(sendAmount * quote.Rate, 2));
  }

  const handleChangeSendAmount = (amount: number) => {
    setSendAmount(amount);
    setReceiveAmount(roundTo(amount * rate, 2));
  }

  const handleChangeReceiverCountry = async (country: Country) => {
    setReceiverCountry(country);
    setReceiverCurrency(country.currency[0])
    const payload: CreateOutboundAffiliateQuoteRequest = {
      originatingCountry: senderCountry!.code,
      originatingCurrency: senderCurrency!,
      destinationCountry: country.code,
      destinationCurrency: country.currency[0],
      amount: Number(sendAmount),
    };
    const quote = await getAffiliateQuote(payload);
    setReceiveAmount(roundTo(sendAmount * quote.Rate, 2));
  }

  const handleChangeReceiveAmount = (amount: number) => {
    setReceiveAmount(amount);
    if (rate > 0) setSendAmount(roundTo(amount / rate, 2));
  }

  const handleChangeReceiverCurrency = async (currency: string) => {
    setReceiverCurrency(currency);
    const payload: CreateOutboundAffiliateQuoteRequest = {
      originatingCountry: senderCountry!.code,
      originatingCurrency: senderCurrency!,
      destinationCountry: receiverCountry!.code,
      destinationCurrency: currency,
      amount: Number(sendAmount),
    };
    const quote = await getAffiliateQuote(payload);
    setReceiveAmount(roundTo(sendAmount * quote.Rate, 2))
  }

  const getAffiliateQuote = async (payload: CreateOutboundAffiliateQuoteRequest, showLoading: boolean = true): Promise<OutboundAffiliateQuote> => {
    if (store.affiliate.type === 'inbound') {
      toast.warn('Need implement of inbound affiliate quote api.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      return {
        Rate: 1,
        Fee: 0,
      };
    }

    if (showLoading) setLoading(true);
    const res = await getOutboundAffiliateQuote(store.affiliate.affiliateId, payload);
    const quote: OutboundAffiliateQuote = res.Result;
    setRate(quote.Rate);
    setServiceCharge(quote.Fee);
    if (showLoading) setLoading(false);
    return quote;
  }

  return (
    <Container maxWidth={false} disableGutters sx={{ py: { xs: 2, sm: 4 }, bgcolor: 'primary.light' }}>
      <Container maxWidth="xl" disableGutters sx={{ px: { xs: 2, sm: 6 } }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Typography component="div" sx={{ py: { xs: 2, sm: 3 }, fontWeight: 'bold', fontSize: { xs: 34, sm: 48 }, textAlign: 'center' }}>
            {store.affiliate.headline}
          </Typography>
          {initLoading ? <CircularProgress size={33} sx={{ color: '#ccc', }} /> :
            <React.Fragment>
              <Box sx={{ flexGrow: 1 }}>
                <Grid sx={{ pt: 0, pb: 4 }} container spacing={4} justifyContent="center"
                  alignItems="center">
                  <Grid item xs={12} lg={6}>
                    <Typography variant="subtitle1" component="div" sx={{ pb: 1 }}>
                      You send
                    </Typography>
                    <MoneyInput
                      // key={`send${moneyInputRef}`}
                      counties={senderCountries}
                      country={senderCountry!}
                      amount={sendAmount}
                      currency={senderCurrency!}
                      changeCountry={handleChangeSenderCountry}
                      changeAmount={handleChangeSendAmount}
                      changeCurrency={() => null}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Typography variant="subtitle1" component="div" sx={{ pb: 1 }}>
                      They get
                    </Typography>
                    <MoneyInput
                      // key={`receive${moneyInputRef}`}
                      counties={receiverCountries}
                      country={receiverCountry!}
                      amount={receiveAmount}
                      currency={receiverCurrency!}
                      changeCountry={handleChangeReceiverCountry}
                      changeAmount={handleChangeReceiveAmount}
                      changeCurrency={handleChangeReceiverCurrency}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Typography variant="body1" component="div">
                Exchange Rate: 1.00 {senderCurrency} = {rate} {receiverCurrency}
              </Typography>
              <Typography variant="body1" component="div" sx={{ pb: 2 }}>
                Fees: {serviceCharge} {senderCurrency}
              </Typography>
              <Grid sx={{ pt: 0, pb: 4 }} container spacing={0} justifyContent="center" >
                <Grid item xs={12} sm={4}>
                  {isAuthenticated ?
                    <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
                      {/* <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                    Send to:
                  </Typography> */}
                      <Container disableGutters>
                        {store.affiliate.type === 'outbound' &&
                          <Grid container spacing={2} >
                            <Grid item xs={12}><CreateOutBoundTransaction
                              Rate={rate}
                              SendingAmount={sendAmount}
                              ServiceCharges={serviceCharge}
                              ReceivingForeignAmount={receiveAmount}
                              ReceivingCurrency={receiverCurrency!}
                              SendingCurrency={senderCurrency!}
                              ReceivingCountryCode={receiverCountry!.code}
                            /></Grid>
                          </Grid>
                        }
                        {store.affiliate.type === 'inbound' &&
                          <Grid container spacing={2} >
                            <Grid item xs={12}><CreateInBoundTransaction DollarAmount={receiveAmount} /></Grid>
                          </Grid>
                        }
                      </Container>
                    </Stack>
                    :
                    <Button
                      sx={{ textTransform: 'none', fontSize: 20, fontWeight: 'bold', lineHeight: 2, width: 1 }}
                      disableElevation
                      variant="contained"
                      onClick={() => loginWithRedirect({ display: 'popup' })}
                    >
                      Get Started
                    </Button>
                  }
                </Grid>
              </Grid>
            </React.Fragment>
          }



        </Stack>
      </Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container >
  );
};

export default Remit;
