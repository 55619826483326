
import React from "react";
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Backdrop, Button, CircularProgress, Divider, TextField } from "@mui/material";
import { CreateInboundTransactionRequest, InboundReceiver } from "../../models/Inbound";
import { CreateInboundTransaction } from "../../services/InboundApis";
import { useStateStore } from "../../store";
import { SenderProfile } from "../../models/common";
import { formatMoney } from "../../utils/common";

const validationSchema = yup.object({
  Purpose: yup
    .string()
    .max(250, 'Maximum 250 letters')
    .required('Purpose is required'),
});


const InboundConfirmPayment = (props: { cb: Function, receiver: InboundReceiver, DollarAmount: number }) => {
  const state = useStateStore();
  const [submitting, setSubmitting] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      Purpose: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setSubmitting(true);
        const res = await CreateInboundTransaction(state.affiliate.affiliateId,
          {
            ...values,
            AffiliateTransactionID: uuidv4(),
            SenderID: parseInt((state.profile as unknown as SenderProfile)?.ExSenderId),
            ReceiverID: props.receiver.kaasiReceiverID,
            DollarAmount: Number(props.DollarAmount),
            DateOfTransaction: (new Date()).toLocaleDateString("en-AU"),
          } as CreateInboundTransactionRequest,
        );
        if (props.cb) props.cb(res.TransactionID);
      } catch (error) {
        console.log(error)
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>

        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="center" sx={{ pb: 1 }}>
            <Typography variant="h4" component="span" sx={{ fontWeight: 'bold', fontSize: 29 }}>
              Amount: {formatMoney(props.DollarAmount)}
            </Typography>
          </Stack>
        </Stack>
        <Divider sx={{ py: 1, minWidth: 300, }} />
        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
          <Typography variant="h6" component="div" sx={{ mt: 1 }}>
            Send To
          </Typography>
          <Typography color="#777" variant="body2" component="div">
            Name: {props.receiver.firstName} {props.receiver.lastName}
          </Typography>
          <Typography color="#777" variant="body2" component="div">
            Account: {props.receiver.metaData.BankCode}-{props.receiver.metaData.AccountNumber}
          </Typography>
        </Stack>
        <Divider sx={{ py: 1, mb: 2 }} />
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <TextField
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            fullWidth
            id='Purpose'
            name='Purpose'
            label='Purpose'
            value={formik.values.Purpose}
            required
            onChange={formik.handleChange}
            error={formik.touched.Purpose && Boolean(formik.errors.Purpose)}
            helperText={formik.touched.Purpose && formik.errors.Purpose}
          />
          <Box sx={{ width: 1, pt: 2, display: 'flex', justifyContent: 'center' }}>
            <Button size="large" color="primary" variant="contained" sx={{ width: 1 / 2, }} type="submit">
              Submit
            </Button>
          </Box>
        </Stack>
      </form>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default InboundConfirmPayment;
