import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import Home from "./routes/Home";
import OutboundProfile from './routes/outbound/Profile';
import OutboundReceivers from './routes/outbound/Receivers';
import OutboundTransactions from './routes/outbound/Transactions';
import OutboundTransaction from './routes/outbound/Transaction';
import InboundProfile from './routes/inbound/Profile';
import InboundReceivers from './routes/inbound/Receivers';
import InboundTransactions from './routes/inbound/Transactions';
import InboundTransaction from './routes/inbound/Transaction';
import Callback from './routes/Callback';
import NoMatch from './routes/NoMatch';
import EmptyHome from './routes/EmptyHome';
import EmailVerificationSuccess from './routes/EmailVerificationSuccess';
import Loading from './components/Loading';
import { ThemeProvider } from '@mui/system';
import { createTheme, CssBaseline } from '@mui/material';
import SnackbarProvider from 'react-simple-snackbar';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import themeOptions from './theme';
import { useStateStore } from './store';
import { addAlpha } from './utils/theme';
import EmailVerificationSuccessForAffiliate from './routes/EmailVerificationSuccessForAffiliate';
import UploadDocument from './routes/outbound/UploadDocument';
import OutboundRegistration from './routes/outbound/Registration';
import InboundRegistration from './routes/inbound/Registration';
import ReceiverAccounts from './routes/outbound/ReceiverAccounts';
import Privacy from './routes/Privacy';
import ContactUs from './routes/Contact';
import Terms from './routes/Terms';

const App = () => {
  const { isLoading: auth0Loading } = useAuth0();
  const state = useStateStore();
  const theme = React.useMemo(
    () => {
      const newThemeOptions = { ...themeOptions };
      if (state.affiliate.themeColor) {
        newThemeOptions.palette.primary.main = state.affiliate.themeColor;
        newThemeOptions.palette.primary.light = addAlpha(state.affiliate.themeColor, 0.09);
      }
      return createTheme(newThemeOptions);
    },
    [state],
  );

  return (
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <SnackbarProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {auth0Loading ? <Loading /> :
              <Router>
                <Switch>
                <Route path="/" exact component={EmptyHome} />
                  <Route path="/callback" component={Callback} />
                  <Route path="/404" component={NoMatch} />
                  <Route path="/email/verification/success" component={EmailVerificationSuccess} />
                  <Route path="/:affiliationShortName" exact component={Home} />
                  <Route path="/:affiliationShortName/outbound/registration" component={OutboundRegistration} />
                  <Route path="/:affiliationShortName/outbound/profile" component={OutboundProfile} />
                  <Route path="/:affiliationShortName/outbound/upload" component={UploadDocument} />
                  <Route path="/:affiliationShortName/outbound/receivers" component={OutboundReceivers} />
                  <Route path="/:affiliationShortName/outbound/receiver/accounts/:receiverId/:receiverName/:countryCode" component={ReceiverAccounts} />
                  <Route path="/:affiliationShortName/outbound/transactions" component={OutboundTransactions} />
                  <Route path="/:affiliationShortName/outbound/transaction/:id" component={OutboundTransaction} />
                  <Route path="/:affiliationShortName/inbound/registration" component={InboundRegistration} />
                  <Route path="/:affiliationShortName/inbound/profile" component={InboundProfile} />
                  <Route path="/:affiliationShortName/inbound/receivers" component={InboundReceivers} />
                  <Route path="/:affiliationShortName/inbound/transactions" component={InboundTransactions} />
                  <Route path="/:affiliationShortName/inbound/transaction/:id" component={InboundTransaction} />
                  <Route path="/:affiliationShortName/email/verification/success" component={EmailVerificationSuccessForAffiliate} />
                  <Route path="/:affiliationShortName/privacy/" component={Privacy} />
                  <Route path="/:affiliationShortName/contact" component={ContactUs} />
                  <Route path="/:affiliationShortName/terms" component={Terms} />
                  
                  <Route path="*" component={NoMatch} />
                </Switch>
              </Router>
            }
          </LocalizationProvider>
        </SnackbarProvider>
        <ToastContainer />
      </ThemeProvider>
  );
}

export default App;
