import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuIcon from '@mui/icons-material/Menu';
import Home from '@mui/icons-material/Home';
import ListAlt from '@mui/icons-material/ListAlt';
import People from '@mui/icons-material/People';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "@mui/material";
import { useStateStore } from "../store";
import { SenderProfile } from "../models/common";

interface NavBarProps {
  hideBorder?: any
}

const NavBar = ({ hideBorder }: NavBarProps) => {
  const store = useStateStore();
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const state = useStateStore();


  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [anchorElSign, setAnchorElSign] = React.useState<null | HTMLElement>(null);
  const openSign = Boolean(anchorElSign);

  const logoutWithRedirect = () =>
    logout({
      returnTo: `${window.location.origin}?affiliationShortName=${state.affiliate.shortName}`,
    });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickSign = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSign(event.currentTarget);
  };

  const handleCloseSign = () => {
    setAnchorElSign(null);
  };

  const redirectUrl: string = `${window.location.origin}/callback?affiliationShortName=${state.affiliate.shortName}`;

  const getUserName = () => {
    if (state.profile) return `${(state.profile as SenderProfile).FirstName} ${(state.profile as SenderProfile).LastName}`;
    if (user) return user?.name;
    return '';

  }

  return (
    <Box sx={{ borderBottomColor: 'rgba(0, 0, 0, 0.12)', borderBottomWidth: hideBorder ? 0 : 2, borderBlockEndStyle: 'solid' }}>
      <Container maxWidth="xl" sx={{ p: { xs: 2, sm: 3 }, pr: { xs: 1, sm: 2 } }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={{ xs: 0, sm: 2 }}
        >
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 2 }}
            alignItems={{ xs: 'flex-start', sm: 'flex-end' }}
          >
            <Box >
              <Link sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} component={RouterLink} to={`/${state.affiliate.shortName}`} underline="none">
                {/* <Typography color="primary" component="span" sx={{ fontSize: { xs: 26, sm: 32 }, }}>
                NOVA
              </Typography>
              <Typography sx={{ fontWeight: 'Bold', color: '#333', fontSize: { xs: 26, sm: 32 }, }} component="span">
                REMIT
              </Typography> */}
                <img src={store.affiliate.logoUrl.url} alt={store.affiliate.name} style={{ maxHeight: 50, maxWidth: 200, }} />
              </Link>
            </Box>
            <Typography variant="body2" sx={{ color: '#999', pl: '2px', pt: { xs: '3px', sm: 0 }, fontSize: { xs: 12, sm: 14 }, }}>
              Powered by NovaRemit
            </Typography>
          </Stack>
          <Box sx={{ pt: '5px' }}>
            {!isAuthenticated && (
              <React.Fragment>
                <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                  <Button
                    sx={{ mr: { xs: 1, sm: 2 }, textTransform: 'none', }}
                    variant="outlined"
                    onClick={() => loginWithRedirect({ prompt: 'login', redirectUri: redirectUrl })}
                  >
                    Log in
                  </Button>
                  <Button
                    sx={{ textTransform: 'none', }}
                    disableElevation
                    variant="contained"
                    onClick={() => loginWithRedirect({ screen_hint: 'signup', prompt: 'login', redirectUri: redirectUrl })}
                  >
                    Sign up
                  </Button>
                </Box>
                <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
                  <IconButton aria-label="signup" onClick={handleClickSign}>
                    <AccountCircle sx={{ fontSize: 32, color: '#999' }} />
                  </IconButton>
                  <Menu
                    id="signup-menu"
                    anchorEl={anchorElSign}
                    open={openSign}
                    onClose={handleCloseSign}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 18,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <MenuItem onClick={() => loginWithRedirect({ screen_hint: 'signup', display: 'popup', redirectUri: redirectUrl })}>Sign up</MenuItem>
                    <MenuItem onClick={() => loginWithRedirect({ display: 'popup', redirectUri: redirectUrl })}>Log in</MenuItem>
                  </Menu>
                </Box>
              </React.Fragment>
            )}
            {isAuthenticated && (
              <React.Fragment>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2" sx={{ mr: 2, color: '#999', display: { xs: 'none', sm: 'inline' } }} component="span">
                    Welcome {getUserName()}
                  </Typography>
                  <Avatar alt={getUserName()} src={user?.picture} sx={{ width: 32, height: 32 }} />
                  <IconButton onClick={handleClick} size="small" sx={{ ml: 1 }}>
                    <MenuIcon sx={{ fontSize: 32, }} />
                  </IconButton>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 18,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem component={RouterLink} to={`/${state.affiliate.shortName}`} >
                    <ListItemIcon>
                      <Home fontSize="small" />
                    </ListItemIcon>
                    Home
                  </MenuItem>
                  <MenuItem component={RouterLink} to={`/${state.affiliate.shortName}/${state.affiliate.type}/profile`} >
                    <ListItemIcon>
                      <AccountCircle fontSize="small" />
                    </ListItemIcon>
                    Profile
                  </MenuItem>
                  <MenuItem component={RouterLink} to={`/${state.affiliate.shortName}/${state.affiliate.type}/receivers`} >
                    <ListItemIcon>
                      <People fontSize="small" />
                    </ListItemIcon>
                    Receivers
                  </MenuItem>
                  <MenuItem component={RouterLink} to={`/${state.affiliate.shortName}/${state.affiliate.type}/transactions`} >
                    <ListItemIcon>
                      <ListAlt fontSize="small" />
                    </ListItemIcon>
                    Transactions
                  </MenuItem>
                  <MenuItem onClick={logoutWithRedirect}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default NavBar;
