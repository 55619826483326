import _ from "lodash";
import { FormItem, FormItemType } from "../../models/common";
import Countries from '../../utils/countryCodeList.json';

const excludeAUCountries = _.filter(Countries, i => i.value !== 'AUS');

export const ProfileFormItems: { [key: string]: FormItem; } = {
  FirstName: {
    key: 'FirstName',
    label: 'First Name',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 70,
    isRequired: true,
  },
  LastName: {
    key: 'LastName',
    label: 'Last Name',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 70,
    isRequired: true,
  },
  AddressLine1: {
    key: 'AddressLine1',
    label: 'Address',
    type: FormItemType.TEXTINPUT,
    width: 12,
    maxLength: 140,
    isRequired: true,
  },
  Suburb: {
    key: 'Suburb',
    label: 'Suburb',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 35,
    isRequired: true,
  },
  State: {
    key: 'State',
    label: 'State',
    type: FormItemType.TEXTINPUT,
    width: 4,
    maxLength: 35,
    isRequired: true,
  },
  Postcode: {
    key: 'Postcode',
    label: 'Postcode/ZIP',
    type: FormItemType.TEXTINPUT,
    width: 2,
    maxLength: 10,
    isRequired: true,
  },
  Country: {
    key: 'Country',
    label: 'Country',
    type: FormItemType.SELECT,
    width: 2,
    selectValues: excludeAUCountries.map((i: any) => ({ value: i.value, label: i.label })),
    isRequired: true,
  },
  MobileInternationalDialCode: {
    key: 'MobileInternationalDialCode',
    label: 'Country Code',
    type: FormItemType.SELECT,
    width: 2,
    selectValues: excludeAUCountries.map((i: any) => ({ value: i.phone, label: i.phone })),
  },
  Mobile: {
    key: 'Mobile',
    label: 'Mobile',
    type: FormItemType.TEXTINPUT,
    width: 2,
    maxLength: 13,
  },
  EmailAddress: {
    key: 'EmailAddress',
    label: 'Email Address',
    type: FormItemType.TEXTINPUT,
    width: 12,
    maxLength: 200,
  },
  DateOfBirth: {
    key: 'DateOfBirth',
    label: 'Date Of Birth',
    type: FormItemType.DATEPICKER,
    width: 6,
  },
}

