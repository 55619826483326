import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Paper, MenuList, MenuItem, ListItemIcon, Divider } from "@mui/material";
import { AccountCircle, ListAlt, Logout, People, Home } from "@mui/icons-material";
import { useStateStore } from "../store";

const AccountMenu = (props: { selected: string }) => {
  const {
    logout,
  } = useAuth0();
  const state = useStateStore();

  const logoutWithRedirect = () =>
    logout({
      returnTo: `${window.location.origin}?affiliationShortName=${state.affiliate.shortName}`,
    });

  return (
    <Paper variant="outlined">
      <MenuList>
      <MenuItem component={RouterLink} to={`/${state.affiliate.shortName}`}>
          <ListItemIcon>
            <Home fontSize="small" />
          </ListItemIcon>
          Home
        </MenuItem>
        <Divider />
        <MenuItem component={RouterLink} to={`/${state.affiliate.shortName}/${state.affiliate.type}/profile`} selected={props.selected === 'profile'}>
          <ListItemIcon>
            <AccountCircle fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>
        <Divider />
        <MenuItem component={RouterLink} to={`/${state.affiliate.shortName}/${state.affiliate.type}/receivers`} selected={props.selected === 'receivers'}>
          <ListItemIcon>
            <People fontSize="small" />
          </ListItemIcon>
          Receivers
        </MenuItem>
        <Divider />
        <MenuItem component={RouterLink} to={`/${state.affiliate.shortName}/${state.affiliate.type}/transactions`} selected={props.selected === 'transactions'}>
          <ListItemIcon>
            <ListAlt fontSize="small" />
          </ListItemIcon>
          Transactions
        </MenuItem>
        <Divider />
        <MenuItem onClick={logoutWithRedirect}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </MenuList>
    </Paper>
  );
};

export default AccountMenu;
