import axios, { Method } from "axios";
import { toast } from 'react-toastify';
import { getConfig } from "../config";

export interface UrlParams {
  [propName: string]: string;
}

export interface RequestBody {
  [propName: string]: any;
}

export interface RequestOption {
  url: string;
  method: Method;
  params?: UrlParams;
  data?: RequestBody;
}


const config = getConfig();

export default function request(option: RequestOption, silient: boolean = false) {
  const requestConfig: any = {
    method: option.method,
    url: option.url,
    baseURL: config.apiUrl,
    params: option.params,
    data: option.data,
  };
  const sessionToken = sessionStorage.getItem('token');
  if (sessionToken) {
    requestConfig.headers = { 'authorization': `Bearer ${sessionToken}`};
  }
  return axios(requestConfig)
    .then((response) => {
      if ('Message' in response.data && response.data.Message) {
        if (!silient)
        toast.warn(response.data.Message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        throw response;
      }
      if ('errors' in response.data && response.data.errors && response.data.errors.length > 0) {
        // response.data.errors.forEach((e: any) => {
          if (!silient)
          toast.warn(response.data.errors[0].LongDescription || 'Oops! Something wrong', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        // });
        throw response;
      }
      if ('Errors' in response.data && response.data.Errors && response.data.Errors.length > 0) {
        // response.data.errors.forEach((e: any) => {
          const errorMessage = response.data.Errors[0].LongDescription || response.data.Errors[0].ErrorDescription
          if (!silient)
          toast.warn(errorMessage || 'Oops! Something wrong', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        // });
        throw response;
      }
      return response.data;
    })
    .catch((error) => {
      if (error.response && [400, 401, 403, 500].includes(error.response.status)) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 400) {
          if (!silient)
          toast.warn('The request was invalid.', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        } else if (error.response.status === 401 || error.response.status === 403) {
          if (!silient)
          toast.warn('Unauthorized', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        } else if (error.response.status === 500) {
          if (!silient)
          toast.error('An unhandled error occured.', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        }
      }
      throw error;
      // TODO: send error to sentry
    });
}
