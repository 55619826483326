import _ from "lodash";
import Countries from '../utils/countryCodeList.json';
import { OutboundReceiverAccount } from "../models/Outbound";

export function getIdFromAuth0Sub(sub: string): string {
  if (sub.includes('|')) {
    const splits: string[] = sub.split('|');
    const [id] = splits.slice(-1);
    return id || sub;
  }
  return sub;
}

export function fillValues2Form<T, U>(initialFormValues: T, values: U): T {
  let formValues: T = { ...initialFormValues };
  for (var key of Object.keys(formValues)) {
    //@ts-ignore
    if (key in values && values[key]) {
      //@ts-ignore
      formValues[key] = values[key];
    };
  }
  return formValues;
}

export const AUSTATES = [
  {
    label: 'NSW',
    value: 'NSW',
  },
  {
    label: 'VIC',
    value: 'VIC',
  },
  {
    label: 'QLD',
    value: 'QLD',
  },
  {
    label: 'ACT',
    value: 'ACT',
  },
  {
    label: 'SA',
    value: 'SA',
  },
  {
    label: 'TAS',
    value: 'TAS',
  },
  {
    label: 'WA',
    value: 'WA',
  },
  {
    label: 'NT',
    value: 'NT',
  },
]

const BANKING_TYPES = [
  {
    label: 'IFSC Code',
    value: '1',
  },
  {
    label: 'Bank Code',
    value: '2',
  },
  {
    label: 'SWIFT Code',
    value: '3',
  },
  {
    label: 'IBAN Code',
    value: '5',
  },
  {
    label: 'CNAP Code',
    value: '6',
  },
  {
    label: 'Wallet Provider Code',
    value: '4',
  },
  {
    label: 'Payout Partner',
    value: '10',
  },
  {
    label: 'Ripple Service Center',
    value: '7',
  },
]

const RouteToExternalBankJson = [
  {
    label: 'DFCC',
    value: 2,
  },
  {
    label: "Seylan",
    value: 4,
  },
  {
    label: 'Kotak-Mahindra',
    value: 5,
  },
  {
    label: 'Commercial Bank',
    value: 6,
  },
  {
    label: 'Bank Of Shanghai',
    value: 7,
  },
  {
    label: 'Ripple',
    value: 8,
  },
]

export function formatMoney(value: number | string): string {
  const v = Number(value);
  return (v).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function getBankingTypeText(type: string): string {
  if (!type) return '';
  return _.find(BANKING_TYPES, b => b.value === type)?.label || '';
}

export function getExternalBankText(type: number): string {
  if (!type) return '';
  return _.find(RouteToExternalBankJson, b => b.value === type)?.label || '';
}

export function getReceiverAccountText(account: OutboundReceiverAccount): string {
  if (!account) return '';
  let text:string = '';
  switch (account.bankingType) {
    case '2':
      text = `${account.bankIdentification}-${account.accountNumber}`;
      break;
    case '4':
      text = account.accountNumber || '';
      break;
    default:
      text = account.accountNumber;
      break;
  }
  return `(${account.currencyCode}) ${getBankingTypeText(account.bankingType)}-${text}`;
}

export function getCountry2Code(code: string): string {
  return _.find(Countries, c=>c.value === code)?.iso2Code || '';
}