import getAuth0 from "./auth_config";
import getApiUrl from "./api_config";
import getDemoAffiliates from "./affiliate_config";

export function getConfig() {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.
  const audience =
  getAuth0.audience && getAuth0.audience !== "YOUR_API_IDENTIFIER"
      ? getAuth0.audience
      : null;

  return {
    domain: getAuth0.domain,
    clientId: getAuth0.clientId,
    ...(audience ? { audience } : null),
    apiUrl: getApiUrl,
    demoAffiliates: getDemoAffiliates,
  };
}
