import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Link as RouterLink } from "react-router-dom";
import { Container, Divider, Grid, Typography, Button, Stack, Alert } from "@mui/material";
import AccountMenu from "../../components/AccountMenu";
import OutboundProfileForm from "../../components/outbound/ProfileForm";
import Loading from "../../components/Loading";
import Layout from "../../components/Layout";
// import UploadDocumentForm from "../../components/outbound/UploadDocumentForm";
import { fillValues2Form } from "../../utils/common";
import { CreateOutboundSenderRequest } from "../../models/Outbound";
import { SenderProfile } from "../../models/common";
import { useStateStore } from "../../store";

const initialProfile: CreateOutboundSenderRequest = {
  AffiliateSenderId: '',
  FirstName: '',
  LastName: '',
  EmailAddress: '',
  AddressLine1: '',
  Suburb: '',
  State: '',
  Postcode: '',
  Country: 'AUS',
  MobileInternationalDialCode: '61',
  Mobile: '',
  DateOfBirth: '',
  Occupation: '',
  CountryOfBirth: '',
  Nationality: '',
  PlaceOfBirth: '',
  SenderType: 'I'
};

const Profile = () => {
  const state = useStateStore();
  // const [loading, setLoading] = React.useState<boolean>(true);
  // const [profile, setProfile] = React.useState<CreateOutboundSenderRequest>(initialProfile);
  // const {
  //   user,
  // } = useAuth0();
  // React.useEffect(() => {
  //   if (user && user.sub) getProfile();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const getProfile = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await fetchProfile(getIdFromAuth0Sub(user?.sub!));
  //     setProfile(fillValues2Form(initialProfile, res.profile as SenderProfile));
  //   } catch (error) {
  //   } finally {
  //     setLoading(false);
  //   }

  // }

  const getFormWidget = () => {
    // check profile is value
    let isValidProfile = false;
    let senderId = undefined;
    if (state.profile) {
      senderId = (state.profile as unknown as SenderProfile)?.ExSenderId;
      if (senderId) isValidProfile = true;
    }

    return <OutboundProfileForm formValues={isValidProfile ? fillValues2Form(initialProfile, state.profile!) : initialProfile} senderId={senderId} />
  }

  const profile = state.profile as unknown as SenderProfile;

  return (
    <Layout>
      <Container maxWidth="xl" sx={{ p: 3 }}>
        <Grid container spacing={5} justifyContent="center"
          alignItems="flex-start">
          <Grid item xs={2} sm={2} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <AccountMenu selected="profile" />
          </Grid>
          <Grid item xs={12} sm={10}>
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Typography variant="h4" component="div" sx={{ pb: 2 }} >
                Profile
              </Typography>
              {(profile && profile.KycStatus !== 'SUCCESSFUL') &&
                <Button component={RouterLink} to={`/${state.affiliate.shortName}/outbound/upload`} variant="outlined" sx={{ height: 40 }}>Upload Document</Button>
              }
            </Stack>
            <Divider />

            <Container disableGutters sx={{ my: 2 }}>
              {profile &&
                <Alert severity="info" sx={{ mb: 4 }}>KYC Status: {profile.KycStatus}</Alert>
              }
              {getFormWidget()}

            </Container>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default withAuthenticationRequired(Profile, {
  onRedirecting: () => <Loading />,
});
