import React, { useCallback } from 'react'
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import ImageOutlined from '@mui/icons-material/ImageOutlined';
import ClearOutlined from '@mui/icons-material/ClearOutlined';
import { TextField, Grid, GridSize, MenuItem, Box, Alert, Typography, IconButton, Switch, FormControlLabel } from "@mui/material";
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import { FormItem, FormItemSelectValue, FormItemType } from "../models/common";

const FormItemComponent = (props: any) => {

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const binaryStr = reader.result
        formik.setFieldValue(item.key, (binaryStr as string).split(',')[1])
      }
      reader.readAsDataURL(file)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const acceptedImageFilesDropzon = useDropzone({ onDrop, accept: 'image/jpeg, image/png, image/bmp, image/tif, image/gif, image/tiff, image/jfif' })
  const acceptedFilesDropzon = useDropzone({ onDrop, accept: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/png, image/jpeg, image/jpeg, image/bmp, image/jpeg, image/tiff, image/gif, image/tiff, text/plain, application/rtf' })

  const item: FormItem = props.item;
  const formik = props.formik;
  // const validationSchema = props.validationSchema;

  const imagefiles = acceptedImageFilesDropzon.acceptedFiles.map((file: any) => (
    <img alt={file.path} src={URL.createObjectURL(file)} style={{ height: 200, width: 300, objectFit: 'cover' }} />
  ));

  const files = acceptedFilesDropzon.acceptedFiles.map((file: any) => {
    if (file.type.includes("image")) {
      return <img alt={file.path} src={URL.createObjectURL(file)} style={{ height: 200, width: 300, objectFit: 'cover' }} />
    }
    
    return <label style={{ height: 200, width: 300 }}>Selected <strong>{file.path}</strong></label>
  });

  const inputProps: any = {
    maxLength: item.maxLength || 50,
  }

  if (item.isHidden) return null;

  return (
    <Grid item xs={12} sm={item.width as GridSize}>
      {item.type === FormItemType.TEXTINPUT &&
        <TextField
          size={props.isDense ? 'small' : 'medium'}
          disabled={item.isDisabled || false}
          inputProps={inputProps}
          variant="outlined"
          fullWidth
          multiline={item.maxRows ? true : false}
          rows={item.maxRows || 1}
          id={item.key}
          name={item.key}
          // label={item.label}
          label={`${item.label}${item.isRequired ? ' *' : ''}`}
          //@ts-ignore
          value={formik.values[item.key]}
          onChange={formik.handleChange}
          //@ts-ignore
          error={formik.touched[item.key] && Boolean(formik.errors[item.key])}
          //@ts-ignore
          helperText={formik.touched[item.key] ? formik.errors[item.key] : item.helpText}
        />
      }
      {(item.type === FormItemType.SELECT) &&
        <TextField
          size={props.isDense ? 'small' : 'medium'}
          select
          disabled={item.isDisabled || false}
          // type={item.isNumberOnly ? 'number' : 'text'}
          // inputProps={inputProps}
          variant="outlined"
          fullWidth
          id={item.key}
          name={item.key}
          // label={item.label}
          label={`${item.label}${item.isRequired ? ' *' : ''}`}
          //@ts-ignore
          value={formik.values[item.key]}
          onChange={formik.handleChange}
          //@ts-ignore
          error={formik.touched[item.key] && Boolean(formik.errors[item.key])}
          //@ts-ignore
          helperText={formik.touched[item.key] && formik.errors[item.key]}
        >
          {item.selectValues!.map((e: FormItemSelectValue) => (
            <MenuItem key={e.value} value={e.value}>
              {e.label}
            </MenuItem>
          ))}
        </TextField>
      }
      {item.type === FormItemType.DATEPICKER &&
        <MobileDatePicker
          // label={item.label}
          label={`${item.label}${item.isRequired ? ' *' : ''}`}
          inputFormat="dd/MM/yyyy"
          minDate={item.minDate || new Date('1870-01-01T00:00:00Z')}
          maxDate={item.maxDate || new Date()}
          //@ts-ignore
          value={formik.values[item.key] ? moment(formik.values[item.key], 'DD/MM/YYYY').toDate() : null}
          onChange={value => formik.setFieldValue(item.key, moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY'))} // Force convert d/m/yyyy on iOS to dd/MM/yyyy
          renderInput={(params) =>
            <TextField
              {...params}
              size={props.isDense ? 'small' : 'medium'}
              disabled={item.isDisabled || false}
              variant="outlined"
              fullWidth
              id={item.key}
              name={item.key}
              //@ts-ignore
              error={formik.touched[item.key] && Boolean(formik.errors[item.key])}
              //@ts-ignore
              helperText={formik.touched[item.key] && formik.errors[item.key]}
            />
          }
        />
      }
      {item.type === FormItemType.DOCUMENT &&
        <React.Fragment>
          <Box {...acceptedImageFilesDropzon.getRootProps()}>
            <input {...acceptedImageFilesDropzon.getInputProps()} />
            <Box component="div" sx={{ position: 'relative', p: 2, height: 260, backgroundColor: '#f6f6f6', border: '2px dashed #bbb', borderRadius: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
              {acceptedImageFilesDropzon.acceptedFiles.length ?
                <React.Fragment>
                  <Typography variant="body1" component="div" sx={{ pb: 1 }}>
                    {item.label}{item.isRequired ? ' *' : ''}
                  </Typography>
                  <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} aria-label="delete" onClick={() => { acceptedImageFilesDropzon.acceptedFiles.length = 0; }}>
                    <ClearOutlined />
                  </IconButton>
                  {imagefiles}
                </React.Fragment>
                :
                <React.Fragment>
                  <ImageOutlined sx={{ fontSize: 120, color: '#ddd' }} />
                  <Typography variant="h5" component="div">
                    {item.label}{item.isRequired ? ' *' : ''}
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ py: 1, color: '#666' }}>
                    Drag and drop image file or browser
                  </Typography>
                  <Typography variant="caption" component="div" sx={{ color: '#666' }}>
                    (Allowed file types:  png, jpg, jpeg, bmp, jfif, tif, gif and tiff)
                  </Typography>
                </React.Fragment>
              }
            </Box>
          </Box>
          {(formik.touched[item.key] && Boolean(formik.errors[item.key])) ?
            <Alert severity="warning">Document front image is required</Alert>
            : null}
        </React.Fragment>
      }
      {item.type === FormItemType.SUPPORT_DOCUMENT &&
        <React.Fragment>
          <Box {...acceptedFilesDropzon.getRootProps()}>
            <input {...acceptedFilesDropzon.getInputProps()} />
            <Box component="div" sx={{ position: 'relative', p: 2, height: 260, backgroundColor: '#f6f6f6', border: '2px dashed #bbb', borderRadius: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
              {acceptedFilesDropzon.acceptedFiles.length ?
                <React.Fragment>
                  <Typography variant="body1" component="div" sx={{ pb: 1 }}>
                    {item.label}{item.isRequired ? ' *' : ''}
                  </Typography>
                  <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} aria-label="delete" onClick={() => { acceptedFilesDropzon.acceptedFiles.length = 0; }}>
                    <ClearOutlined />
                  </IconButton>
                  {files}
                </React.Fragment>
                :
                <React.Fragment>
                  <ImageOutlined sx={{ fontSize: 120, color: '#ddd' }} />
                  <Typography variant="h5" component="div">
                    {item.label}{item.isRequired ? ' *' : ''}
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ py: 1, color: '#666' }}>
                    Drag and drop image file or browser
                  </Typography>
                  <Typography variant="caption" component="div" sx={{ color: '#666' }}>
                    (Allowed file types:  pdf, doc, docx, xls, xlsx, ppt, pptx, png, jpg, jpeg, bmp, jfif, tif, gif, tiff, txt and rtf)
                  </Typography>
                </React.Fragment>
              }
            </Box>
          </Box>
          {(formik.touched[item.key] && Boolean(formik.errors[item.key])) ?
            <Alert severity="warning">Document front image is required</Alert>
            : null}
        </React.Fragment>
      }
      {item.type === FormItemType.SWITH &&
        <FormControlLabel control={<Switch onChange={(e, value) => formik.setFieldValue(item.key, value)} checked={formik.values[item.key]} />} label={item.label} />
      }
      {item.type === FormItemType.CURRENCY && 
        <TextField
          size={props.isDense ? 'small' : 'medium'}
          select={item.selectValues?.length && item.selectValues?.length > 1 ? true : false}
          disabled={item.isDisabled || false}
          // type={item.isNumberOnly ? 'number' : 'text'}
          // inputProps={inputProps}
          variant="outlined"
          fullWidth
          id={item.key}
          name={item.key}
          // label={item.label}
          label={`${item.label}${item.isRequired ? ' *' : ''}`}
          //@ts-ignore
          value={formik.values[item.key]}
          onChange={formik.handleChange}
          //@ts-ignore
          error={formik.touched[item.key] && Boolean(formik.errors[item.key])}
          //@ts-ignore
          helperText={formik.touched[item.key] && formik.errors[item.key]}
        >
          {item.selectValues?.length && item.selectValues?.length > 1 && item.selectValues.map((e: FormItemSelectValue) => (
            <MenuItem key={e.value} value={e.value}>
              {e.label}
            </MenuItem>
          ))}
        </TextField>
      }
    </Grid>
  );
};

export default FormItemComponent;
