import { FormItem, FormItemType } from "../../models/common";

const purposes = [
  "Family Support",
  "Personal Savings",
  "Personal Travels & Tours",
  "Repayment of Loans",
  "Gift",
  "Special Occasion",
  "Medical Expenses",
  "Investments",
  "Goods Purchased",
  "Salary Payment",
  "Transfer to own account",
  "Education-related student expenses",
  "Utility Bills",
  "Purchase of Residential Property",
  "Offshore Contractor Payment",
  "Other"
];

export const TransactionFormItems: { [key: string]: FormItem; } = {
  Purpose: {
    label: 'Purpose',
    key: 'Purpose',
    type: FormItemType.SELECT,
    width: 12,
    selectValues: purposes.map(e => ({ label: e, value: e })),
  },
  // PurposeSelect: {
  //   label: 'Purpose',
  //   key: 'PurposeSelect',
  //   type: FormItemType.SELECT,
  //   width: 12,
  //   selectValues: []
  // },
  SourceOfFunds: {
    key: 'SourceOfFunds',
    label: 'Source Of Funds',
    type: FormItemType.TEXTINPUT,
    width: 12,
    maxLength: 50,
  },
  DateOfTransaction: {
    key: 'DateOfTransaction',
    label: 'Date Of Transaction',
    type: FormItemType.DATEPICKER,
    width: 6,
  },
  RouteToExternalBank: {
    key: 'RouteToExternalBank',
    label: 'Route To External Bank',
    type: FormItemType.SELECT,
    width: 12,
    selectValues: [
      {
        label: 'DFCC',
        value: 2,
      },
      {
        label: "Seylan",
        value: 4,
      },
      {
        label: 'Kotak-Mahindra',
        value: 5,
      },
      {
        label: 'Commercial Bank',
        value: 6,
      },
      {
        label: 'Bank Of Shanghai',
        value: 7,
      },
      {
        label: 'Ripple',
        value: 8,
      },
    ]
  },
  AmountLock: {
    key: 'AmountLock',
    label: 'Amount Lock',
    type: FormItemType.SELECT,
    width: 12,
    selectValues: [
      {
        label: 'Fixed AUD',
        value: 'Fixed AUD',
      },
      {
        label: "Fixed Foreign",
        value: 'Fixed Foreign',
      },
    ]
  },
  QuoteId: {
    label: 'QuoteId',
    key: 'Quote Id',
    type: FormItemType.SELECT,
    width: 12,
    selectValues: []
  },
}
