import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loading = () => (
  <Box sx={{ display: 'flex', width: 1, p:10, alignItems: 'center', justifyContent: 'center' }}>
    <CircularProgress sx={{ color: '#ccc' }} />
  </Box>
);

export default Loading;
