import { FormItem, FormItemType } from "../models/common";
import Countries from '../utils/countryCodeList.json';
import { RequestBody } from "./request";

const AUSTATES = [
  {
    label: 'NSW',
    value: 'NSW',
  },
  {
    label: 'VIC',
    value: 'VIC',
  },
  {
    label: 'QLD',
    value: 'QLD',
  },
  {
    label: 'ACT',
    value: 'ACT',
  },
  {
    label: 'SA',
    value: 'SA',
  },
  {
    label: 'TAS',
    value: 'TAS',
  },
  {
    label: 'WA',
    value: 'WA',
  },
  {
    label: 'NT',
    value: 'NT',
  },
]

export const FormItems: { [key: string]: FormItem; } = {
  // Title: {
  //   key: 'Title',
  //   label: 'Title',
  //   type: FormItemType.SELECT,
  //   width: 6,
  //   selectValues: [
  //     {
  //       label: 'Mr',
  //       value: 'Mr',
  //     },
  //     {
  //       label: 'Mrs',
  //       value: 'Mrs',
  //     },
  //     {
  //       label: 'Ms',
  //       value: 'Ms',
  //     },
  //   ],
  // },
  FirstName: {
    key: 'FirstName',
    label: 'First Name',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 70,
  },
  LastName: {
    key: 'LastName',
    label: 'Last Name',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 70,

  },
  AddressLine1: {
    key: 'AddressLine1',
    label: 'Address',
    type: FormItemType.TEXTINPUT,
    width: 12,
    maxLength: 140,

  },
  Suburb: {
    key: 'Suburb',
    label: 'Suburb',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 35,

  },
  State: {
    key: 'State',
    label: 'State',
    type: FormItemType.SELECT,
    width: 2,
    selectValues: AUSTATES,
  },
  Postcode: {
    key: 'Postcode',
    label: 'Postcode',
    type: FormItemType.TEXTINPUT,
    width: 2,
    maxLength: 4,
    minLength: 4,

    isNumberOnly: true,
  },
  Country: {
    key: 'Country',
    label: 'Country',
    type: FormItemType.SELECT,
    width: 2,
    selectValues: Countries.map((i: any) => ({ value: i.value, label: i.label })),
  },
  MobileInternationalDialCode: {
    key: 'MobileInternationalDialCode',
    label: 'Country Code',
    type: FormItemType.SELECT,
    width: 2,
    selectValues: Countries.map((i: any) => ({ value: i.phone, label: i.phone })),
  },
  Mobile: {
    key: 'Mobile',
    label: 'Mobile',
    type: FormItemType.TEXTINPUT,
    isNumberOnly: true,
    isPhoneNumber: true,
    width: 4,
  },
  AUMobile: {
    key: 'AUMobile',
    label: 'Mobile',
    type: FormItemType.TEXTINPUT,
    isNumberOnly: true,
    isPhoneNumber: true,
    width: 4,
    maxLength: 9,
  },
  TelephoneInternationalDialCode: {
    key: 'TelephoneInternationalDialCode',
    label: 'Country Code',
    type: FormItemType.SELECT,
    width: 2,
    selectValues: Countries.map((i: any) => ({ value: i.phone, label: i.phone })),
  },
  Telephone: {
    key: 'Telephone',
    label: 'Telephone',
    type: FormItemType.TEXTINPUT,
    isNumberOnly: true,
    isPhoneNumber: true,
    width: 4,
  },
  EmailAddress: {
    key: 'EmailAddress',
    label: 'Email Address',
    type: FormItemType.TEXTINPUT,
    width: 12,
    maxLength: 200,
  },
  DateOfBirth: {
    key: 'DateOfBirth',
    label: 'Date Of Birth',
    type: FormItemType.DATEPICKER,
    width: 6,
  },
  Occupation: {
    key: 'Occupation',
    label: 'Occupation',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 150,
  },
  CountryOfBirth: {
    key: 'CountryOfBirth',
    label: 'Country Of Birth',
    type: FormItemType.SELECT,
    width: 6,
    selectValues: Countries.map((i: any) => ({ value: i.value, label: i.label })),
  },
  Nationality: {
    key: 'Nationality',
    label: 'Nationality',
    type: FormItemType.SELECT,
    width: 6,
    selectValues: Countries.map((i: any) => ({ value: i.label, label: i.label })),
  },
  PlaceOfBirth: {
    key: 'PlaceOfBirth',
    label: 'Place Of Birth',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 100,
  },
  AKA: {
    key: 'AKA',
    label: 'AKA',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 140,
  },
  Relationship: {
    key: 'Relationship',
    label: 'Relationship',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 100,
  },
  IsIndividual: {
    key: 'IsIndividual',
    label: 'Is Individual?',
    type: FormItemType.SWITH,
    width: 6,
  },
  BankingType: {
    key: 'BankingType',
    label: 'Banking Type',
    type: FormItemType.SELECT,
    width: 6,
    selectValues: [
      {
        label: 'IFSC Code',
        value: '1',
      },
      {
        label: 'Bank Code',
        value: '2',
      },
      {
        label: 'SWIFT Code',
        value: '3',
      },
      {
        label: 'Wallet Provider  Code',
        value: '4',
      },
      {
        label: 'IBAN Code',
        value: '5',
      },
      {
        label: 'CNAP Code',
        value: '6',
      },
      {
        label: 'Ripple Service Center',
        value: '7',
      },
      {
        label: 'Payout Partner',
        value: '10',
      },
    ]
  },
  BankIdentification: {
    key: 'BankIdentification',
    label: 'Bank Identification',
    type: FormItemType.TEXTINPUT,
    width: 6,
  },
  BranchIdentification: {
    key: 'BranchIdentification',
    label: 'Branch Identification',
    type: FormItemType.TEXTINPUT,
    width: 6,
  },
  WalletProviderID: {
    key: 'WalletProviderID',
    label: 'Wallet Provider ID',
    type: FormItemType.TEXTINPUT,
    width: 6,
  },
  AccountName: {
    key: 'AccountName',
    label: 'Account Name',
    type: FormItemType.TEXTINPUT,
    width: 6,
  },
  AccountNumber: {
    key: 'AccountNumber',
    label: 'Account Number',
    type: FormItemType.TEXTINPUT,
    width: 6,
  },
  CurrencyCode: {
    key: 'CurrencyCode',
    label: 'Currency Code',
    type: FormItemType.TEXTINPUT,
    width: 6,
  },
  BankCountryCode: {
    key: 'BankCountryCode',
    label: 'Bank Country Code',
    type: FormItemType.TEXTINPUT,
    width: 6,
  },
  SendingAmount: {
    key: 'SendingAmount',
    label: 'Sending Amount',
    type: FormItemType.TEXTINPUT,
    width: 6,
    isNumberOnly: true,
  },
  Rate: {
    key: 'Rate',
    label: 'Rate',
    type: FormItemType.TEXTINPUT,
    width: 6,
    isNumberOnly: true,
    isDisabled: true,
  },
  ServiceCharges: {
    key: 'ServiceCharges',
    label: 'Service Charges',
    type: FormItemType.TEXTINPUT,
    width: 6,
    isNumberOnly: true,
    isDisabled: true,
  },
  ReceivingForeignAmount: {
    key: 'ReceivingForeignAmount',
    label: 'Receiving Foreign Amount',
    type: FormItemType.TEXTINPUT,
    width: 6,
    isNumberOnly: true,
  },
  ReceivingCurrency: {
    key: 'ReceivingCurrency',
    label: 'Receiving Currency',
    type: FormItemType.CURRENCY,
    width: 6,
  },
  Purpose: {
    label: 'Purpose',
    key: 'Purpose',
    type: FormItemType.TEXTINPUT,
    width: 12,
    maxLength: 250,
  },
  SourceOfFunds: {
    key: 'SourceOfFunds',
    label: 'Source Of Funds',
    type: FormItemType.TEXTINPUT,
    width: 12,
    maxLength: 50,
  },
  DateOfTransaction: {
    key: 'DateOfTransaction',
    label: 'Date Of Transaction',
    type: FormItemType.DATEPICKER,
    width: 6,
  },
  RouteToExternalBank: {
    key: 'RouteToExternalBank',
    label: 'Route To External Bank',
    type: FormItemType.SELECT,
    width: 12,
    selectValues: [
      {
        label: 'DFCC',
        value: 2,
      },
      {
        label: "Seylan",
        value: 4,
      },
      {
        label: 'Kotak-Mahindra',
        value: 5,
      },
      {
        label: 'Commercial Bank',
        value: 6,
      },
      {
        label: 'Bank Of Shanghai',
        value: 7,
      },
      {
        label: 'Ripple',
        value: 8,
      },
    ]
  },
  DocumentType: {
    key: 'DocumentType',
    label: 'Document Type',
    type: FormItemType.SELECT,
    width: 6,
    selectValues: [
      {
        label: 'Passport',
        value: 'P',
      },
      {
        label: "Driver's license",
        value: 'DL',
      },
      {
        label: 'Photo ID',
        value: 'PID',
      },
    ]
  },
  DocumentNumber: {
    key: 'DocumentNumber',
    label: 'Document Number',
    type: FormItemType.TEXTINPUT,
    width: 6,
    maxLength: 15,
  },
  ExpiryDate: {
    key: 'ExpiryDate',
    label: 'Expiry Date',
    type: FormItemType.DATEPICKER,
    width: 6,
  },
  IssueDate: {
    key: 'IssueDate',
    label: 'Issue Date',
    type: FormItemType.DATEPICKER,
    width: 6,
  },
  DocumentFront: {
    key: 'DocumentFront',
    label: 'document front',
    type: FormItemType.DOCUMENT,
    width: 6,
  },
  DocumentBack: {
    key: 'DocumentBack',
    label: 'document back',
    type: FormItemType.DOCUMENT,
    width: 6,
  },
}

export function clearFormData(data: any): RequestBody {
  // clear invalid mobile, tel
  if ('Mobile' in data && 'MobileInternationalDialCode' in data && (!data.Mobile || !data.MobileInternationalDialCode)) {
    delete data.Mobile;
    delete data.MobileInternationalDialCode;
  }
  if ('Telephone' in data && 'TelephoneInternationalDialCode' in data && (!data.Telephone || !data.TelephoneInternationalDialCode)) {
    delete data.Telephone;
    delete data.TelephoneInternationalDialCode;
  }
  if ('HomeTelephone' in data && 'HomeTelephoneInternationalDialCode' in data && (!data.HomeTelephone || !data.HomeTelephoneInternationalDialCode)) {
    delete data.HomeTelephone;
    delete data.HomeTelephoneInternationalDialCode;
  }
  
  // convert type
  if ('Postcode' in data && data.Postcode) {
    data.Postcode = data.Postcode.toString();
  }
  if ('Mobile' in data && data.Mobile) {
    data.Mobile = data.Mobile.toString();
  }
  if ('MobileInternationalDialCode' in data && data.MobileInternationalDialCode) {
    data.MobileInternationalDialCode = parseInt(data.MobileInternationalDialCode);
  }

  // convert date
  if ('DateOfBirth' in data && data.DateOfBirth && data.DateOfBirth instanceof Date) {
    data.DateOfBirth = data.DateOfBirth.toLocaleDateString("en-AU");
  }
  if ('DOB' in data && data.DOB && data.DOB instanceof Date) {
    data.DOB = data.DOB.toLocaleDateString("en-AU");
  }
  if ('ExpiryDate' in data && data.ExpiryDate && data.ExpiryDate instanceof Date) {
    data.ExpiryDate = data.ExpiryDate.toLocaleDateString("en-AU");
  }
  if ('IssueDate' in data && data.IssueDate && data.IssueDate instanceof Date) {
    data.IssueDate = data.IssueDate.toLocaleDateString("en-AU");
  }

  // remove unused data
  for (var key of Object.keys(data)) {
    if (!data[key] && data[key] !== false) delete data[key]
  }

  return data;
}

