import _ from 'lodash';

interface AffiliationDomain {
  affiliateCode: string
  affiliateDomain: string
}

const affiliationDomains: AffiliationDomain[] = [
  {
    affiliateCode: 'bobout',
    affiliateDomain: 'www.bobit.bob.bt',
  },
  {
    affiliateCode: 'bobout',
    affiliateDomain: 'bobit.bob.bt',
  },
  {
    affiliateCode: 'pandaout',
    affiliateDomain: 'remit.novattitech.com',
  },
  {
    affiliateCode: 'pandaout',
    affiliateDomain: 'localhost:3000',
  },
]

const getAffiliateCodeByDomain = (domain: string) => {
  const affiliationDomain =  _.find(affiliationDomains, (item: AffiliationDomain) => item.affiliateDomain === domain)
  if (affiliationDomain) return affiliationDomain.affiliateCode;
  return '';
}

export default getAffiliateCodeByDomain;