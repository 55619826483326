import { SnackbarOptions } from "react-simple-snackbar";

export const snackBarOptions: SnackbarOptions = {
    position: 'top-center',
    style: {
      backgroundColor: 'rgb(46, 125, 50)',
      border: '0',
      color: 'white',
      fontFamily: 'Montserrat, Roboto',
      fontSize: '20px',
      textAlign: 'center',
    },
    closeStyle: {
      color: 'white',
      fontSize: '16px',
    },
  }