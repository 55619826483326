import React from "react";
import { MenuItem, Typography, Paper, FormControl, Select, Box, CircularProgress, Button, Stack, Container, Grid, Backdrop } from "@mui/material";
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, IconButton } from "@mui/material";
import { useSnackbar } from 'react-simple-snackbar'
import CheckCircle from '@mui/icons-material/CheckCircle';
import { snackBarOptions } from "../../utils/snackBar";
import { useStateStore } from "../../store";
import { SenderProfile, TransactionDetails } from "../../models/common";
import { CreateOutboundTransaction, fetchOutboundReceivers, getOutboundReceiverAccountList } from "../../services/OutboundApis";
import { CreateOutboundTransactionRequest, OutboundReceiver, OutboundReceiverAccount } from "../../models/Outbound";
import ReceiverAccountForm from "../outbound/ReceiverAccountForm";
import { initialReceiverAccount } from "../../routes/outbound/ReceiverAccounts";
import OutboundConfirmPayment from "./OutboundConfirmPayment";
import OutboundReceiverForm from "../outbound/ReceiverForm";
import { initialOutboundReceiver } from "../../routes/outbound/Receivers";
import UploadSupportDocumentForm from "../outbound/UploadSupportDocumentForm";
import { getReceiverAccountText } from "../../utils/common";
import DepositMethodOptions from "./DepositMethodOptions";
import moment from 'moment';

export interface SelectOutboundReceiverProps {
  Rate: number,
  SendingAmount: number,
  SendingCurrency: string,
  ServiceCharges: number,
  ReceivingForeignAmount: number,
  ReceivingCurrency: string,
  ReceivingCountryCode: string,
}

const CreateOutBoundTransaction = ({ Rate, SendingAmount, ServiceCharges, ReceivingForeignAmount, ReceivingCurrency, SendingCurrency, ReceivingCountryCode }: SelectOutboundReceiverProps) => {
  const [openPaymentDialog, setOpenPaymentDialog] = React.useState(false);
  const [openNewReceiverDialog, setOpenNewReceiverDialog] = React.useState(false);
  const [openNewReceiverAccountDialog, setOpenNewReceiverAccountDialog] = React.useState(false);
  const [openNewUploadSupportDocDialog, setOpenNewUploadSupportDocDialog] = React.useState(false);
  const [openTransactionCompleteDialog, setOpenTransactionCompleteDialog] = React.useState(false);
  const [openTransactionIncompleteDialog, setOpenTransactionIncompleteDialog] = React.useState(false);

  const [openSnackbar] = useSnackbar(snackBarOptions)
  const [loadingReceivers, setLoadingReceivers] = React.useState<boolean>(true);
  const [receivers, setReceivers] = React.useState<OutboundReceiver[]>([]);
  const [receiver, setReceiver] = React.useState<OutboundReceiver>();
  const [loadingAccounts, setLoadingAccounts] = React.useState<boolean>(false);
  const [receiverAccounts, setReceiverAccounts] = React.useState<OutboundReceiverAccount[]>([]);
  const [receiverAccount, setReceiverAccount] = React.useState<OutboundReceiverAccount>();
  const [KAASITransactionID, setKAASITransactionID] = React.useState<string>();
  const [SourceOfFunds, setSourceOfFunds] = React.useState<string>();
  const [Purpose, setPurpose] = React.useState<string>();
  const [submitingTransaction, setSubmitingTransaction] = React.useState<boolean>(false);
  const [transactionDetails, setTransactionDetails] = React.useState<TransactionDetails>();

  const state = useStateStore();

  React.useEffect(() => {
    if (state.profile) getReceivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.profile]);

  const getReceivers = async () => {
    try {
      setLoadingReceivers(true);
      const res = await fetchOutboundReceivers(state.affiliate.affiliateId, (state.profile as unknown as SenderProfile)?.ExSenderId);
      const fetchedRecivers = res.receivers as OutboundReceiver[];
      setReceivers(fetchedRecivers);
      if (fetchedRecivers.length > 0) {
        setReceiver(fetchedRecivers[0]);
        getReceiverAccounts(fetchedRecivers[0].kaasiReceiverID);
      }
    } catch (error) {
    } finally {
      setLoadingReceivers(false);
    }
  }

  const getReceiverAccounts = async (receiverId: number) => {
    try {
      setLoadingAccounts(true);
      const res = await getOutboundReceiverAccountList(state.affiliate.affiliateId, String(receiverId));
      const fetchedReciverAccounts = res.accounts as OutboundReceiverAccount[];
      setReceiverAccounts(fetchedReciverAccounts);
      if (fetchedReciverAccounts.length > 0) {
        setReceiverAccount(fetchedReciverAccounts[0]);
      }
    } catch (error) {
    } finally {
      setLoadingAccounts(false);
    }
  }

  const handleChangeReceiver = (event: any) => {
    const receiverId: number = event.target.value;
    if (receiverId === receiver!.kaasiReceiverID) return;
    const selectReceiver: OutboundReceiver = _.find(receivers, e => e.kaasiReceiverID === receiverId)!;
    setReceiver(selectReceiver);
    getReceiverAccounts(selectReceiver.kaasiReceiverID);
  };

  const handleChangeReceiverAccount = (event: any) => {
    const receiverAccountId: number = event.target.value;
    const receiverAccount: OutboundReceiverAccount = _.find(receiverAccounts, e => e.kaasiAccountID === String(receiverAccountId))!;
    setReceiverAccount(receiverAccount);
  };

  const handleOpenPaymentDialog = () => {
    if (!receiver) {
      openSnackbar('Please select receiver', 2000,);
      return;
    }
    if (!receiverAccount) {
      openSnackbar('Please select payment method', 2000,);
      return;
    }
    setOpenPaymentDialog(true);
  };

  const handleClosePaymentDialog = () => {
    setOpenPaymentDialog(false);
  };

  const handleConfirmPaymentSuccess = (purpose: string, sourceOfFunds: string) => {
    setPurpose(purpose);
    setSourceOfFunds(sourceOfFunds);
    setOpenPaymentDialog(false);
    if (SendingAmount > state.affiliate.threshold) {
      handleOpenUploadSupportDocDialog();
    } else {
      createOutboundTransaction(purpose, sourceOfFunds);
    }
  }

  const createOutboundTransaction = async (Purpose: string, SourceOfFunds: string) => {
    try {
      setSubmitingTransaction(true);
      const res = await CreateOutboundTransaction(state.affiliate.affiliateId,
        {
          AffiliateTransactionReferenceID: uuidv4(),
          SenderID: (state.profile as unknown as SenderProfile)?.ExSenderId,
          ReceiverID: String(receiver!.kaasiReceiverID),
          ReceiverAccountID: String(receiverAccount!.kaasiAccountID),
          Rate: String(Rate),
          SendingAmount: String(SendingAmount),
          ServiceCharges: String(ServiceCharges),
          ReceivingForeignAmount: String(ReceivingForeignAmount),
          ReceivingCurrency: ReceivingCurrency,
          DateOfTransaction: moment().format("DD/MM/YYYY"),
          SourceOfFunds,
          Purpose,
        } as CreateOutboundTransactionRequest,
      );
      setKAASITransactionID(res.KAASITransactionID)
      
      const transactionDetails: TransactionDetails = {
        transactionId: res.KAASITransactionID,
        amount: SendingAmount
      };
  
      if (state.profile) {
        let senderProfile = state.profile as SenderProfile;
        transactionDetails.senderFullName = `${senderProfile.FirstName} ${senderProfile.LastName}`;
        transactionDetails.senderEmail = senderProfile.EmailAddress;
      };

      setTransactionDetails(transactionDetails);
      handleOpenTransactionCompletedDialog();
    } catch (error) {
      throw error;
    } finally {
      setSubmitingTransaction(false);
    }
  }

  const handleOpenNewReceiverDialog = () => {
    setOpenNewReceiverDialog(true);
  };

  const handleCloseNewReceiverDialog = () => {
    setOpenNewReceiverDialog(false);
  };

  const handleNewReceiverSuccess = (receiverId: string, receiverList: OutboundReceiver[]) => {
    setReceivers(receiverList);
    setReceiver(_.find(receiverList, r => String(r.kaasiReceiverID) === receiverId));
    setReceiverAccounts([]);
    setReceiverAccount(undefined);
    setOpenNewReceiverDialog(false);
    handleOpenNewReceiverAccountDialog();
  }

  const handleOpenNewReceiverAccountDialog = () => {
    setOpenNewReceiverAccountDialog(true);
  };

  const handleCloseNewReceiverAccountDialog = () => {
    setOpenNewReceiverAccountDialog(false);
  };

  const handleNewReceiverAccountSuccess = (accountId: string, accountList: OutboundReceiverAccount[]) => {
    setReceiverAccounts(accountList);
    setReceiverAccount(_.find(accountList, r => String(r.kaasiAccountID) === accountId));
    setOpenNewReceiverAccountDialog(false);
  }

  const handleOpenUploadSupportDocDialog = () => {
    setOpenNewUploadSupportDocDialog(true);
  };

  const handleCloseUploadSupportDocDialog = () => {
    setOpenNewUploadSupportDocDialog(false);
    setOpenTransactionIncompleteDialog(true);
  };

  const handleUploadSupportDocSuccess = () => {
    setOpenNewUploadSupportDocDialog(false);
    createOutboundTransaction(Purpose!, SourceOfFunds!);
  };

  const handleOpenTransactionCompletedDialog = () => {
    setOpenTransactionCompleteDialog(true);
  };

  const handleCloseTransactionCompletedDialog = () => {
    setOpenTransactionCompleteDialog(false);
  };

  const handleCloseTransactionIncompletedDialog = () => {
    setOpenTransactionIncompleteDialog(false);
  };

  return (
    <React.Fragment>
      {/* receiver */}
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
          Select receiver:
        </Typography>
        <Button variant="text" onClick={handleOpenNewReceiverDialog}>
          Add New
        </Button>
      </Stack>
      <Paper elevation={1} sx={{ px: 2, py: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {loadingReceivers && <CircularProgress size={33} sx={{ color: '#ccc', }} />}
        {(!loadingReceivers && receivers.length === 0) &&
          <Box sx={{ textAlign: 'center', p: 0 }}>
            <Typography sx={{ color: '#666' }} variant="caption" component="span">
              No Receiver Found
            </Typography>
          </Box>
        }
        {(!loadingReceivers && receivers.length > 0 && receiver) &&
          <FormControl variant="standard" fullWidth  >
            <Select
              disableUnderline
              value={receiver.kaasiReceiverID}
              renderValue={(() => <Typography variant="body1" component="span" sx={{ px: 1 }}>
                ({receiver.country})-{receiver.firstName} {receiver.lastName}
              </Typography>)}
              onChange={handleChangeReceiver}
            >
              {receivers.map((item: OutboundReceiver) => (
                <MenuItem key={item.kaasiReceiverID} value={item.kaasiReceiverID}>({item.country})-{item.firstName} {item.lastName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        }
      </Paper>
      {/* receiver account */}
      {receiver &&
        <React.Fragment>
          <Stack flexDirection="row" justifyContent="space-between" sx={{ mt: 2 }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', }}>
              Select payment method:
            </Typography>
            <Button variant="text" onClick={handleOpenNewReceiverAccountDialog}>
              Add New
            </Button>
          </Stack>
          <Paper elevation={1} sx={{ px: 2, py: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            {loadingAccounts && <CircularProgress size={33} sx={{ color: '#ccc', }} />}
            {(!loadingAccounts && receiverAccounts.length === 0) &&
              <Box sx={{ textAlign: 'center', p: 0 }}>
                <Typography sx={{ color: '#666' }} variant="caption" component="span">
                  No payment method found
                </Typography>
              </Box>
            }
            {(!loadingAccounts && receiverAccounts.length > 0 && receiverAccount) &&
              <FormControl variant="standard" fullWidth  >
                <Select
                  disableUnderline
                  value={receiverAccount.kaasiAccountID}
                  renderValue={(() => <Typography variant="body1" component="span" sx={{ px: 1 }}>
                    {getReceiverAccountText(receiverAccount)}
                  </Typography>)}
                  onChange={handleChangeReceiverAccount}
                >
                  {receiverAccounts.map((item: OutboundReceiverAccount) => (
                    <MenuItem key={item.kaasiAccountID} value={item.kaasiAccountID}>{getReceiverAccountText(item)}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
          </Paper>
        </React.Fragment>
      }
      {(!loadingReceivers && !loadingAccounts) ?
        <Button
          sx={{ textTransform: 'none', fontSize: 20, fontWeight: 'bold', lineHeight: 2, width: 1, mt: 4 }}
          disableElevation
          variant="contained"
          onClick={handleOpenPaymentDialog}
        // disabled={!receiver || !receiverAccount}
        >
          Confirm Payment
        </Button>
      : null}
      {/* confirm payment */}
      <Dialog open={openPaymentDialog} onClose={handleClosePaymentDialog}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" >
            <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
              Confirm Payment
            </Typography>
            <IconButton aria-label="close" color="secondary" onClick={handleClosePaymentDialog}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Container disableGutters sx={{ py: 1, }}>
            <OutboundConfirmPayment
              transactionDetail={{ Rate, SendingAmount, ServiceCharges, ReceivingForeignAmount, ReceivingCurrency, SendingCurrency, ReceivingCountryCode }}
              cb={handleConfirmPaymentSuccess}
              receiver={receiver!}
              receiverAccount={receiverAccount!}
            />
          </Container>
        </DialogContent>
      </Dialog>

      {/* new receiver */}
      <Dialog open={openNewReceiverDialog} onClose={handleCloseNewReceiverDialog}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" >
            <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
              Add New Receiver
            </Typography>
            <IconButton aria-label="close" color="secondary" onClick={handleCloseNewReceiverDialog}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Container disableGutters sx={{ py: 1, my: 1 }}>
            <OutboundReceiverForm cb={handleNewReceiverSuccess} formValues={initialOutboundReceiver} buttonText="Next" />
          </Container>
        </DialogContent>
      </Dialog>

      {/* new receiver account */}
      {receiver &&
        <Dialog open={openNewReceiverAccountDialog} onClose={handleCloseNewReceiverAccountDialog}>
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between" >
              <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
                Add Payment Method
              </Typography>
              <IconButton aria-label="close" color="secondary" onClick={handleCloseNewReceiverAccountDialog}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Container disableGutters sx={{ py: 1, my: 1 }}>
              <ReceiverAccountForm cb={handleNewReceiverAccountSuccess} formValues={{ ...initialReceiverAccount, BankCountryCode: receiver.country, CurrencyCode: ReceivingCurrency, AccountName: `${receiver.firstName} ${receiver.lastName}` }} receiverId={receiver!.kaasiReceiverID} buttonText="Next" />
            </Container>
          </DialogContent>
        </Dialog>
      }

      {/* upload support document */}
      <Dialog open={openNewUploadSupportDocDialog} onClose={handleCloseUploadSupportDocDialog}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" >
            <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
              Upload Supporting Document
            </Typography>
            <IconButton aria-label="close" color="secondary" onClick={handleCloseUploadSupportDocDialog}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography variant="caption" component="span" >
            Please upload proof of funds to support your transaction.
          </Typography>
          <Container disableGutters sx={{ py: 1, my: 1 }}>

            <UploadSupportDocumentForm cb={handleUploadSupportDocSuccess} transactionId={parseInt(KAASITransactionID!)} />
          </Container>
        </DialogContent>
      </Dialog>

      {/* transaction completed */}
      <Dialog open={openTransactionCompleteDialog} onClose={handleCloseTransactionCompletedDialog}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" >
            <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
              Transaction Submitted
            </Typography>
            <IconButton aria-label="close" color="secondary" onClick={handleCloseTransactionCompletedDialog}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ textAlign: 'center', minWidth: 500 }}>
          <CheckCircle sx={{ color: '#60C637', fontSize: 100, m: 3 }} />
          <Typography variant="h6" sx={{ fontSize: 20 }} component="div" >
            Your order has been received. <br/>Thank you!
          </Typography>
          <Divider sx={{ my: 1, mb: 1 }} />
          <Typography variant="body1" component="div" sx={{ mt: 1, mb: 2 }}>
            Please Deposit AUD funds into the below bank
          </Typography>
          <Grid container spacing={1} sx={{ textAlign: "left", pl: 3, ml: 0, pb: 1, width: 500, bgcolor: "primary.light", border: "2px solid", borderColor: "primary.light", borderRadius: 2 }}>
            <Grid item xs={12}>
              <Typography variant="h6" component="div" sx={{fontWeight: "bold"}} >
                BANK DEPOSIT
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" component="div" >
                PAYID:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body1" component="div" sx={{textAlign: "right"}} >
                {state.affiliate.bankDetail.payId}
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body2" component="div" sx={{ fontWeight: 'bold', fontStyle: 'italic' }} >
                OR
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" component="div" >
                BSB:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body1" component="div" sx={{textAlign: "right"}} >
                {state.affiliate.bankDetail.bsb}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" component="div" >
                ACCOUNT:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body1" component="div" sx={{textAlign: "right"}} >
                {state.affiliate.bankDetail.accountNumber}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" component="div" >
                REF:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body1" component="div" sx={{textAlign: "right"}} >
                {KAASITransactionID}
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body2" component="div" sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                Please ensure that the following transaction number {KAASITransactionID} is entered as part of your bank deposit description. Failure to enter this may result in the transaction being rejected.
              </Typography>
            </Grid>
          </Grid>
          <DepositMethodOptions transaction={transactionDetails!}/>
          <Container disableGutters sx={{ py: 1, my: 1, mt: 2 }}>
            <Button variant="contained" onClick={handleCloseTransactionCompletedDialog}>Close</Button>
          </Container>
        </DialogContent>
      </Dialog>

      {/* transaction incompleted */}
      <Dialog open={openTransactionIncompleteDialog} onClose={handleCloseTransactionIncompletedDialog}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" >
            <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
              Transaction Incomplete
            </Typography>
            <IconButton aria-label="close" color="secondary" onClick={handleCloseTransactionIncompletedDialog}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ textAlign: 'center', minWidth: 500 }}>
          <InfoIcon sx={{ color: '#ff9800', fontSize: 100, m: 3 }} />
          <Typography variant="h6" sx={{ fontSize: 24 }} component="div" >
            Transaction - Incomplete
          </Typography>
          <Typography variant="body1" component="div" >
            Your transaction detail have not been submitted successfully. Please try again if you wish to go ahead with the transfer.
          </Typography>
          <Container disableGutters sx={{ py: 1, my: 1, mt: 2 }}>
            <Button variant="contained" onClick={handleCloseTransactionIncompletedDialog}>Close</Button>
          </Container>
        </DialogContent>
      </Dialog>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitingTransaction}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment >
  );
};

export default CreateOutBoundTransaction;
