

const devAuth0 = {
  domain: "novatti.au.auth0.com",
  clientId: "OtGGYw9Mlm247RDK7P1scOq7bjF8ve3e",
  audience: "https://novatti.au.auth0.com/api/v2/"
}

const prodAuth0 = {
  domain: "novattixb.au.auth0.com",
  clientId: "Dvmcf6yGYNWUaCEazyn9x6J5XlWjVQxW",
  audience: "https://novattixb.au.auth0.com/api/v2/"
}

const getAuth0 = (env: string) => {
  if (env === 'production') {
    return prodAuth0;
  }
  return devAuth0;
}

export default getAuth0(process.env.REACT_APP_ENV!);