/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import { Button, Stack, Grid, Backdrop, CircularProgress, Card } from "@mui/material";
import { CFCountry, Currency, FormItem, FormItemSelectValue } from "../../models/common";
// import { FormItems } from "../../utils/form";
import { ReceiverAccountEditFormItems } from "./ReceiverAccountEditFormItems";
import FormItemComponent from "../FormItem";
import { Bank, BankBranch, BankBranchListResponse, BankListResponse, EditOutboundReceiverAccountRequest, OutboundReceiverAccount, WalletProvider, WalletProviderListResponse } from "../../models/Outbound";
import { useStateStore } from "../../store";
import { editOutboundReceiverAccount, fetchBankBranchList, fetchBankList, fetchWalletProviderList } from "../../services/OutboundApis";
import { toast } from "react-toastify";

const validationSchema = yup.object({
  KaasiReceiverAccountId: yup
    .string(),
  BankingType: yup
    .string(),
  IsReceiverAccountActive: yup
    .boolean(),
  AccountName: yup
    .string()
    .max(120)
    .when('BankingType', {
      is: (BankingType: any) => ['4'].includes(BankingType),
      then: yup.string()
        .required('Wallet Name is required'),
      otherwise: yup.string()
        .required('Account Name is required'),
    }),
  AccountNumber: yup
    .string()
    .max(34)
    .when('BankingType', {
      is: (BankingType: any) => ['1', '2', '3', '5'].includes(BankingType),
      then: yup.string()
        .required('Account Number is required'),
    })
    .when('BankingType', {
      is: (BankingType: any) => ['4'].includes(BankingType),
      then: yup.string()
        .required('Wallet Number is required'),
    }),
  CurrencyCode: yup
    .string()
    .matches(/^[A-Z]{3}$/, "Must be exactly 3 letter")
    .required('Currency Code is required'),
  IFSC: yup
    .string()
    .max(50)
    .when('BankingType', {
      is: (BankingType: any) => ['1'].includes(BankingType),
      then: yup.string()
        .required('IFSC is required'),
    }),
  BankCode: yup
    .string()
    .max(50)
    .when('BankingType', {
      is: (BankingType: any) => ['2'].includes(BankingType),
      then: yup.string()
        .required('Bank Code is required'),
    }),
  BankIdentification: yup
    .string()
    .when('BankingType', {
      is: (BankingType: any) => ['2'].includes(BankingType),
      then: yup.string()
        .required('Bank Identification is required'),
    }),
  BankBranchCode: yup
    .string()
    .max(50)
    .when('BankingType', {
      is: (BankingType: any) => ['2'].includes(BankingType),
      then: yup.string()
        .required('Branch Name is required'),
    }),
  BranchIdentification: yup
    .string()
    .max(50)
    .when('BankingType', {
      is: (BankingType: any) => ['2'].includes(BankingType),
      then: yup.string()
        .required('Branch Name is required'),
    }),
  SWIFT: yup
    .string()
    .max(50)
    .when('BankingType', {
      is: (BankingType: any) => ['3'].includes(BankingType),
      then: yup.string()
        .required('SWIFT Code is required'),
    }),
  IBAN: yup
    .string()
    .max(50)
    .when('BankingType', {
      is: (BankingType: any) => ['5'].includes(BankingType),
      then: yup.string()
        .required('IBAN is required'),
    }),
  WalletProviderId: yup
    .string()
    .max(50)
    .when('BankingType', {
      is: (BankingType: any) => ['4'].includes(BankingType),
      then: yup.string()
        .required('Wallet Provider Id is required'),
    }),
  CNAP: yup
    .string()
    .max(50)
    .when('BankingType', {
      is: (BankingType: any) => ['6'].includes(BankingType),
      then: yup.string()
        .required('CNAP is required'),
    }),
  RippleServiceCenterCode: yup
    .string()
    .max(50)
    .when('BankingType', {
      is: (BankingType: any) => ['7'].includes(BankingType),
      then: yup.string()
        .required('Ripple Service Center Code is required'),
    }),
  PayoutPartnerCode: yup
    .string()
    .max(50)
    .when('BankingType', {
      is: (BankingType: any) => ['10'].includes(BankingType),
      then: yup.string()
        .required('Payout Partner Code is required'),
    }),});

// const initialValuesArray: FormItem[] = [];
// for (var key of Object.keys(initialValues)) {
//   if (ReceiverAccountFormItems[key]) initialValuesArray.push(ReceiverAccountFormItems[key]);
// }

const formValues2FormItemsArray = (formValues: any): FormItem[] => {
  const formItemsArray: FormItem[] = [];
  for (var key of Object.keys(formValues)) {
    if (ReceiverAccountEditFormItems[key]) {
      switch (key) {
        default:
          formItemsArray.push(ReceiverAccountEditFormItems[key])
          break;
      }
    };
  }

  return formItemsArray;
}

interface ReceiverAccountEditFormProps {
  receiverAccount: OutboundReceiverAccount;
  cb: Function,
  receiverId: number,
}

const ReceiverAccountEditForm = ({ receiverAccount, cb, receiverId }: ReceiverAccountEditFormProps) => {
  const [submitting, setSubmitting] = React.useState(false);
  const state = useStateStore();
  const [bankList, setBankList] = React.useState<Bank[]>([]);
  const [bankBranchList, setBankBranchList] = React.useState<BankBranch[]>([]);
  const formValues: any = {
    KaasiReceiverAccountId: Number(receiverAccount.kaasiAccountID),
    BankCountryCode: receiverAccount.bankCountryCode,
    CurrencyCode: receiverAccount.currencyCode,
    BankingType: receiverAccount.bankingType,
    BankIdentification: '',
    BranchCity: '',
    BranchIdentification: '',
    WalletProviderId: '',
  };
  switch (receiverAccount.bankingType) {
    case "1":
      formValues.IFSC = receiverAccount.branchIdentification;
      break;
    case "2":
      formValues.BankCode = receiverAccount.bankIdentification;;
      formValues.BankBranchCode = receiverAccount.branchIdentification;;
      break;
    case "3":
      formValues.SWIFT = receiverAccount.branchIdentification;;
      break;
    case "4":
      formValues.WalletProviderId = receiverAccount.walletProviderId;
      break;
    case "5":
      formValues.IBAN = receiverAccount.branchIdentification;;
      break;
    case "6":
      formValues.CNAP = receiverAccount.branchIdentification;;
      break;
    case "7":
      formValues.RippleServiceCenterCode = receiverAccount.branchIdentification;;
      break;
    case "10":
      formValues.PayoutPartnerCode = receiverAccount.branchIdentification;;
      break;
    default:
      break;
  }
  
  // Put at the bottom of form 
  formValues.AccountName = receiverAccount.accountName;
  formValues.AccountNumber = receiverAccount.accountNumber;
  formValues.IsReceiverAccountActive = true;

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit: async (values: any) => {
      try {
        setSubmitting(true);
        const payload = {
          ...values,
          BankCode: values.BankIdentification,
          BankBranchCode: values.BranchIdentification,
        };
        delete payload.BankingType;
        delete payload.BankIdentification;
        delete payload.BranchIdentification;
        delete payload.BranchCity;

        await editOutboundReceiverAccount(state.affiliate.affiliateId,
          payload as EditOutboundReceiverAccountRequest,
        );
        cb();
      } catch (error) {
        console.log(error)
      } finally {
        setSubmitting(false);
      }
    },
  });

  // const formItemsArray = formValues2FormItemsArray(formValues);
  const [formItemsArray, setFormItemsArray] = React.useState(formValues2FormItemsArray(formValues));

  React.useEffect(() => {
    const getBankList = async () => {
      try {
        setSubmitting(true);
        const res = await fetchBankList(state.affiliate.affiliateId, formValues.BankCountryCode) as unknown as BankListResponse;
        setBankList(res.BankList);

        const selectBankValues: FormItemSelectValue[] = [];
        res.BankList.forEach((b: Bank) => {
          selectBankValues.push({
            label: b.BankName,
            value: b.BankCode,
          });
        });

        setFormItemsArray(previousItems => _.map(previousItems, (item) => {
          switch (item.key) {
            case 'BankIdentification':
              return {
                ...item,
                selectValues: selectBankValues,
                isHidden: false,
                isRequired: true
              }
            case 'BranchIdentification':
              return {
                ...item,
                isRequired: true
              }
            case 'WalletProviderId':
              return {
                ...item,
                isHidden: true,
                isRequired: false,
              }
            case 'AccountName':
              return {
                ...item,
                label: 'Account Name'
              }
            case 'AccountNumber':
              return {
                ...item,
                label: 'Account Number'
              }
            default:
              return item;
          }
        }));

        if (res.BankList.length === 0) {
          toast.warn('Sorry, no banks avaliable. ', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          return
        }
      } catch (error) {
        console.log(error)
      } finally {
        setSubmitting(false);
      }

    }

    const getWalletList = async () => {
      try {
        setSubmitting(true);
        const res = await fetchWalletProviderList(state.affiliate.affiliateId, formValues.BankCountryCode) as WalletProviderListResponse;

        const selectValues: FormItemSelectValue[] = [];
        res.WalletProviders.forEach((w: WalletProvider) => {
          selectValues.push({
            label: w.WalletProviderName,
            value: w.WalletProviderID,
          });
        });

        setFormItemsArray(previousItems => _.map(previousItems, (item) => {
          switch (item.key) {
            case 'BankIdentification':
              return {
                ...item,
                isHidden: true,
                isRequired: false
              }
            case 'BranchIdentification':
              return {
                ...item,
                isHidden: true,
                isRequired: false,
              }
            case 'WalletProviderId':
              return {
                ...item,
                selectValues,
                isHidden: false,
                isRequired: true,
              }
            case 'AccountName':
              return {
                ...item,
                label: 'Wallet Name'
              }
            case 'AccountNumber':
              return {
                ...item,
                label: 'Wallet Number'
              }
            case 'BranchCity':
              return {
                ...item,
                isHidden: true
              }
            default:
              return item;
          }
        }));

        if (res.WalletProviders.length === 0) {
          toast.warn('Sorry, no wallet providers avaliable. ', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          return
        }
      } catch (error) {
        console.log(error)
      } finally {
        setSubmitting(false);
      }
    }

    var bankingType = formik.values.BankingType as string;

    if (bankingType) {
      const IsChangedBankingType = bankingType !== receiverAccount.bankingType;
      formik.values.WalletProviderId =  IsChangedBankingType ? '' : receiverAccount.walletProviderId;
      formik.values.BankIdentification = IsChangedBankingType ? '' : receiverAccount.bankIdentification;
      formik.values.BranchIdentification = IsChangedBankingType ? '' : receiverAccount.branchIdentification;
      formik.values.BranchCity = '';
      formik.values.AccountName = IsChangedBankingType ? '' : receiverAccount.accountName;
      formik.values.AccountNumber = IsChangedBankingType ? '' : receiverAccount.accountNumber;
    }

    if (bankingType === '2') {
      getBankList();
    }
    if (bankingType === '4') {
      getWalletList();
    }

  }, [formik.values.BankingType]);

  React.useEffect(() => {
    const getBranchList = async (BankIdentification: string) => {
      try {
        setSubmitting(true);
        const res = await fetchBankBranchList(state.affiliate.affiliateId, BankIdentification) as BankBranchListResponse;

        setBankBranchList(res.BranchList);
        const selectValues: FormItemSelectValue[] = [];

        res.BranchList.forEach((b: BankBranch) => {
          selectValues.push({
            label: b.BranchName,
            value: b.BranchCode,
          });
        });

        // group branch city list
        const cities = _.chain(res.BranchList)
          .filter(b => (b.BranchCity !== null && b.BranchCity !== ''))
          .groupBy("BranchCity")
          .map((value, key) => ({ city: key }))
          .value();

        const citySelectValues: FormItemSelectValue[] = [{
          label: 'All',
          value: 'ALL',
        },];
        cities.forEach((city) => {
          citySelectValues.push({
            label: city.city,
            value: city.city,
          });
        });

        setFormItemsArray(previousItems => _.map(previousItems, (item) => {
          switch (item.key) {
            case 'BranchIdentification':
              return {
                ...item,
                selectValues: selectValues,
                isHidden: false,
                isRequired: true
              }
            case 'BranchCity':
              return {
                ...item,
                selectValues: citySelectValues,
                isHidden: false
              }
            default:
              return item;
          }
        }));
        formik.values.BranchCity = 'ALL';

        if (res.BranchList.length === 0) {
          toast.warn('Sorry, no branches avaliable. ', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          return
        }
      } catch (error) {
        console.log(error)
      } finally {
        setSubmitting(false);
      }
    }

    var BankIdentification = formik.values.BankIdentification as string;
    if (BankIdentification) {
      formik.values.BranchIdentification = BankIdentification !== receiverAccount.bankIdentification ? '' : receiverAccount.branchIdentification;
      getBranchList(BankIdentification);
    }
  }, [formik.values.BankIdentification]);

  React.useEffect(() => {
    const filterBranchListByCity = (BranchCity: string) => {

      var newFormItemsArray = _.cloneDeep(formItemsArray);
      const BranchIdentificationIndex = _.findIndex(formItemsArray, i => i.key === 'BranchIdentification');

      const selectValues: FormItemSelectValue[] = [];
      let filterBranchList: BankBranch[] = [];
      if (BranchCity === 'ALL') {
        filterBranchList = bankBranchList;
      } else {
        filterBranchList = _.filter(bankBranchList, b => b.BranchCity === BranchCity)
      }

      filterBranchList.forEach((b: BankBranch) => {
        selectValues.push({
          label: b.BranchName,
          value: b.BranchCode,
        });
      });

      newFormItemsArray[BranchIdentificationIndex].selectValues = selectValues;
      setFormItemsArray(newFormItemsArray);
    }

    var BranchCity = formik.values.BranchCity as string;
    if (BranchCity) {
      formik.values.BranchIdentification = BranchCity === 'ALL' ? formik.values.BranchIdentification : '';
      filterBranchListByCity(BranchCity);
    }
  }, [formik.values.BranchCity]);

  React.useEffect(() => {
    const initCurrencyOptions = (CountryCode : string) => {
      var newFormItemsArray = _.cloneDeep(formItemsArray);
      const CurrencyIndex = _.findIndex(formItemsArray, i => i.key === 'CurrencyCode');
      const selectValues: FormItemSelectValue[] = [];

      const receiveCountriesCollection = _.filter(
        state.affiliate.receiveCountriesCollection.items,
        item => item.code.toLowerCase() === CountryCode.toLowerCase()
      );

      receiveCountriesCollection?.forEach((country:CFCountry) => {
        country.currencyCollection?.items?.forEach((c: Currency) => {
          selectValues.push({
            label: c.code,
            value: c.code          
          })
        })
      });

      newFormItemsArray[CurrencyIndex].selectValues = selectValues;
      setFormItemsArray(newFormItemsArray);

      // Set default CurrencyCode value if not exist
      if (selectValues.length > 0 && !formik.values.CurrencyCode) {
        formik.setFieldValue('CurrencyCode', selectValues[0].value)
      }
    }
    
    const CountryCode = formik.values.BankCountryCode as string;
    if (CountryCode) {
      initCurrencyOptions(CountryCode);
    }

  },[formik.values.BankCountryCode])

  let selectBank, selectedBranch;
  if (formik.values.BankIdentification) {
    selectBank = _.find(bankList, b => b.BankCode === formik.values.BankIdentification)
  }
  if (formik.values.BranchIdentification) {
    selectedBranch = _.find(bankBranchList, b => b.BranchCode === formik.values.BranchIdentification)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack alignItems='center' spacing={4}>
        <Grid container spacing={2} alignItems='center'>
          {formItemsArray.map((item: FormItem) =>
            <FormItemComponent key={item.key} item={item} formik={formik} validationSchema={validationSchema} />
          )}
          {(selectBank) ?
            <Card variant="outlined" sx={{
              width: 1,
              ml: 2,
              my: 2,
              p: 2,
              bgcolor: 'primary.light',
              fontSize: 13,
              lineHeight: 1.6,
            }}>
              <b>Bank Name:</b> {selectBank.BankName} ({selectBank.BankCode})<br />
              <b>Address:</b> {selectBank.AddressLine1} {selectBank.City} {selectBank.State} {selectBank.Postcode}<br />
              {selectedBranch &&
                <span><b>Branch Name:</b> {selectedBranch.BranchName} ({selectedBranch.BranchCode})<br />
                </span>
              }
            </Card>
            : null}
        </Grid>
        <Button size="large" color="primary" variant="contained" sx={{ width: 1 / 2 }} type="submit">
          Submit
        </Button>
      </Stack>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  );
};

export default ReceiverAccountEditForm;
