import { stringify } from 'qs';
import { toast } from 'react-toastify';
import { FetchTransactionListParams, TransactionDetails } from '../models/common';
import { CreateOutboundAffiliateQuoteRequest, CreateOutboundQuoteRequest, CreateOutboundReceiverAccountRequest, CreateOutboundReceiverRequest, CreateOutboundSenderRequest, CreateOutboundTransactionRequest, DepositMethodConfig, DepositMethodLinkRequest, EditOutboundReceiverAccountRequest, EditOutboundSenderRequest, RunKYCForSenderRequest, UpdateOutboundReceiverRequest, UploadSenderPrimaryDocumentRequest, UploadSenderSupportingDocumentRequest } from "../models/Outbound";
import { clearFormData } from "../utils/form";
import request from "../utils/request";

export async function CreateOutboundSender(AffiliateId: string, data: CreateOutboundSenderRequest) {
  const res = await request({
    url: `/outboundCreateSender/${AffiliateId}`,
    method: 'POST',
    data: clearFormData(data),
  });
  if (res.Status === 'DUPLICATE') {
    toast.warn('Oops! Duplicate sender found', {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    throw res;
  }
  return res;
}

export async function EditOutboundSender(AffiliateId: string, data: EditOutboundSenderRequest) {
  return request({
    url: `/editSender/${AffiliateId}`,
    method: 'POST',
    data: clearFormData(data),
  });
}

export async function CreateOutboundReceiver(AffiliateId: string, data: CreateOutboundReceiverRequest) {
  const res = await request({
    url: `/outReceiver/${AffiliateId}`,
    method: 'POST',
    data: clearFormData(data),
  });
  if (res.Status === 'DUPLICATE') {
    toast.warn('Oops! Duplicate receiver found', {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    throw res;
  }
  return res;
}

export async function UpdateOutboundReceiver(AffiliateId: string, data: UpdateOutboundReceiverRequest) {
  return await request({
    url: `/outboundEditReceiver/${AffiliateId}`,
    method: 'POST',
    data: clearFormData(data),
  });
}

export async function UploadSenderPrimaryDocument(AffiliateId: string, data: UploadSenderPrimaryDocumentRequest) {
  return request({
    url: `/uploadSenderPrimaryDocument/${AffiliateId}`,
    method: 'POST',
    data: clearFormData(data),
  });
}

export async function UploadSenderSupportingDocument(AffiliateId: string, data: UploadSenderSupportingDocumentRequest) {
  return request({
    url: `/uploadSenderSupportingDocument/${AffiliateId}`,
    method: 'POST',
    data: clearFormData(data),
  });
}

export async function fetchOutboundReceivers(AffiliateId: string, senderId: string) {
  return request({
    url: `/listReceivers/${AffiliateId}?senderId=${senderId}`,
    method: 'GET',
  });
}

export async function CreateOutboundReceiverAccount(AffiliateId: string, data: CreateOutboundReceiverAccountRequest) {
  const res = await request({
    url: `/outboundCreateReceiverAccount/${AffiliateId}`,
    method: 'POST',
    data: clearFormData(data),
  });
  if (res.Status === 'DUPLICATE') {
    toast.warn('Oops! Duplicate account found', {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    throw res;
  }
  return res;
}

export async function CreateOutboundTransaction(AffiliateId: string, data: CreateOutboundTransactionRequest) {
  return request({
    url: `/outboundCreateTransaction/${AffiliateId}`,
    method: 'POST',
    data: clearFormData(data),
  });
}

export async function getOutboundQuote(AffiliateId: string, data: CreateOutboundQuoteRequest) {
  return request({
    url: `/outboundCreateQuote/${AffiliateId}`,
    method: 'POST',
    data: clearFormData(data),
  });
}

export async function getOutboundAffiliateQuote(AffiliateId: string, data: CreateOutboundAffiliateQuoteRequest) {
  return request({
    url: `/outAffiliateQuote/${AffiliateId}`,
    method: 'POST',
    data: clearFormData(data),
  });
}

export async function fetchOutboundTransactionList(AffiliateId: string, params: FetchTransactionListParams) {
  return request({
    url: `/outTransactions/${AffiliateId}?${stringify(params)}`,
    method: 'GET',
  });
}

export async function fetchOutboundTransactionDetail(AffiliateId: string, transactionId: string) {
  return request({
    url: `/outboundGetTransactionDetails/${AffiliateId}/transaction/${transactionId}`,
    method: 'GET',
  });
}

export async function getOutboundReceiverAccountList(AffiliateId: string, receiverId: string) {
  return request({
    url: `/getReceiverAccount/${AffiliateId}?receiverId=${receiverId}`,
    method: 'GET',
  }, true);
}

export async function editOutboundReceiverAccount(AffiliateId: string, data: EditOutboundReceiverAccountRequest) {
  return request({
    url: `/editReceiverAccount/${AffiliateId}`,
    method: 'POST',
    data: clearFormData(data),
  });
}

export async function runKYCForSender(AffiliateId: string, data: RunKYCForSenderRequest) {
  return request({
    url: `/runKYCForSender/${AffiliateId}`,
    method: 'POST',
    data: clearFormData(data),
  });
}

export async function fetchBankList(AffiliateId: string, countryCode: string) {
  return request({
    url: `/getBankList/${AffiliateId}/Country/${countryCode}`,
    method: 'GET',
  });
}

export async function fetchBankBranchList(AffiliateId: string, bankCode: string) {
  return request({
    url: `/getBranchList/${AffiliateId}/Bank/${bankCode}`,
    method: 'GET',
  });
}

export async function fetchWalletProviderList(AffiliateId: string, countryCode: string) {
  return request({
    url: `/getWalletProviders/${AffiliateId}/Country/${countryCode}`,
    method: 'GET',
  });
}

export async function createDepositMethodLink(AffiliateId: string, transaction: TransactionDetails, config: DepositMethodConfig) {
  var data: DepositMethodLinkRequest = {
    MethodName: config.MethodName,
    SendConfirmationEmailToMerchant: config.SendConfirmationEmailAfterPaymentCompleted,
    SendEmailLinkToCustomer: config.SendEmailLinkToCustomer,
    ExpiryAfterDays: config.ExpiryAfterDays,
    KAASITransactionID: transaction.transactionId,
    TransactionAmount: +transaction.amount, // cast value to number
    SenderFullName: transaction.senderFullName,
    SenderEmail: transaction.senderEmail,
    AllowSenderReference: config.AllowSenderReference,
    AllowMultiplePayment: config.AllowMultiplePayment
  };

  return request({
    url: `/createDepositMethodLink/${AffiliateId}`,
    method: 'POST',
    data: data
  });
}