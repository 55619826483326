import React from 'react';
import { Stack, Typography, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircle';
import { useAuth0 } from '@auth0/auth0-react';

const EmailVerificationSuccess = (props: any) => {

  const affiliationShortName = localStorage.getItem('affiliationShortName');
  if (affiliationShortName) {
    props.history.push(`/${affiliationShortName}/email/verification/success`);
  }

  const {
    error,
    isAuthenticated,
  } = useAuth0();

  if (isAuthenticated) props.history.push("/")
  if (error) props.history.push("/callback")



  return (
    <Stack direction="column" spacing={4} alignItems="center" sx={{ py: { xs: 2, sm: 10 } }}>
      <CheckCircleOutlineIcon sx={{ color: '#4caf50', fontSize: 80, }} />
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h5" gutterBottom component="div" sx={{ fontWeight: 'bold' }}>
          Email Verified!
        </Typography>
        <Typography variant="body1" gutterBottom component="div">
          You have successfully verified email.
        </Typography>
      </Box>
    </Stack>
  );
}



export default EmailVerificationSuccess;
