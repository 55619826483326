import React from "react";
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { Button, Stack, Grid, } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { FormItem, SenderProfile } from "../../models/common";
import { ReceiverFormItems } from "./ReceiverFormItems";
import FormItemComponent from "../FormItem";
import { useStateStore } from "../../store";
// import { toast } from "react-toastify";
import { CreateInboundReceiverRequest, InboundReceiver } from "../../models/Inbound";
import { CreateInboundReceiver, fetchInboundReceivers } from "../../services/InboundApis";
import _ from "lodash";

const validationSchema = yup.object({
  FullName: yup
    .string()
    .required('Full Name is required')
    .matches(/^[a-zA-Z\s-']+$/, "Only contain letters, spaces, apostrophes and hyphens.")
    .max(140, 'Maximum 140 letters'),
  AddressLine1: yup
    .string()
    .required('Address is required')
    .max(140, 'Maximum 140 letters')
    .matches(/^[a-zA-Z0-9\s-',/]+$/, "Only contain letters, numbers, spaces, apostrophes, hyphens, commas and forward slashes."),
  Suburb: yup
    .string()
    .required('Suburb is required')
    .max(35, 'Maximum 35 letters')
    .matches(/^[a-zA-Z\s-']+$/, "Only contain letters, spaces, apostrophes and hyphens."),
  State: yup
    .string()
    .required('State is required'),
  Postcode: yup
    .string()
    .matches(/^[0-9]{4}$/, "Must be exactly 4 digits")
    .required('Postcode is required'),
  Country: yup
    .string()
    .required('Country is required'),
  MobileInternationalDialCode: yup
    .string(),
  Mobile: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(9, 'Minimum 9 digits')
    .max(10, 'Maximum 10 digits'),
  EmailAddress: yup
    .string()
    .email('Enter a valid email')
    .max(200, 'Maximum 200 letters'),
  // .required('Email is required'),
  DOB: yup
    // .date()
    .string()
    .nullable(),
  // .required('DateOfBirth is required'),
  Occupation: yup.string(),
  AKA: yup.string(),
  ABN: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(11, 'Maximum 11 digits')
    .when('IndividualOrBusiness', {
      is: (IndividualOrBusiness: any) => IndividualOrBusiness === 'B',
      then: yup.string()
        .required('ABN is required')
    }),
  ACN: yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(9, 'Maximum 9 digits'),
  BankCode: yup
    .string()
    .required('Bank Code is required')
    .max(3, 'Maximum 3 letters'),
  BSB: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .required('BSB is required')
    .max(6, 'Maximum 6 digits'),
  AccountNumber: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .required('Account Number is required')
    .min(6, 'Minimum 6 digits')
    .max(12, 'Maximum 12 digits'),
  IndividualOrBusiness: yup
    .string()
    .required('Individual Or Business is required'),
});

const formValues2FormItemsArray = (formValues: any): FormItem[] => {
  const formItemsArray: FormItem[] = [];
  for (var key of Object.keys(formValues)) {
    if (ReceiverFormItems[key]) {
      switch (key) {
        case 'Country':
          formItemsArray.push({
            ...ReceiverFormItems[key],
            isDisabled: true,
          })
          break;
        case 'MobileInternationalDialCode':
        case 'TelephoneInternationalDialCode':
          formItemsArray.push({
            ...ReceiverFormItems[key],
            isDisabled: true,
          })
          break;
        default:
          formItemsArray.push(ReceiverFormItems[key])
          break;
      }
    };
  }
  return formItemsArray;
}

interface ReceiverFormProps {
  formValues: CreateInboundReceiverRequest;
  cb: Function,
  buttonText?: string;
}


const InboundReceiverForm = ({ formValues, cb, buttonText }: ReceiverFormProps) => {
  const [formItemsArray, setFormItemsArray] = React.useState(formValues2FormItemsArray(formValues));

  const [submitting, setSubmitting] = React.useState(false);
  const state = useStateStore();
  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit: async (values: CreateInboundReceiverRequest) => {
      try {
        setSubmitting(true);
        if (values.AffiliateReceiverID) { 
        } else { // create
          const res = await CreateInboundReceiver(state.affiliate.affiliateId,
            {
              ...values,
              AffiliateReceiverID: uuidv4(),
              KAASISenderID: parseInt((state.profile as unknown as SenderProfile)?.ExSenderId),
            } as CreateInboundReceiverRequest,
          );
          const listRes = await fetchInboundReceivers(state.affiliate.affiliateId, (state.profile as unknown as SenderProfile)?.ExSenderId);
          cb(res.KAASIBeneficiaryID,  listRes.receivers as InboundReceiver[]);
        }
      } catch (error) {
        console.log(error)
      } finally {
        setSubmitting(false);
      }
    },
  });

  React.useEffect(() => {
    const ABNIndex = _.findIndex(formItemsArray, i => i.key === 'ABN');
    const ACNIndex = _.findIndex(formItemsArray, i => i.key === 'ACN');
    var newFormItemsArray = _.cloneDeep(formItemsArray);
    var individualOrBusiness = formik.values.IndividualOrBusiness as string;

    if (individualOrBusiness === 'B') {
      newFormItemsArray[ABNIndex].isHidden = false;
      newFormItemsArray[ABNIndex].isRequired = true;
      newFormItemsArray[ACNIndex].isHidden = false;
    }
    if (individualOrBusiness === 'I') {
      newFormItemsArray[ABNIndex].isHidden = true;
      newFormItemsArray[ABNIndex].isRequired = false;
      newFormItemsArray[ACNIndex].isHidden = true;
    }
    setFormItemsArray(newFormItemsArray);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.IndividualOrBusiness]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack alignItems='center' spacing={4}>
        <Grid container spacing={2} alignItems='center'>
          {formItemsArray.map((item: FormItem) =>
            <FormItemComponent key={item.key} item={item} formik={formik} validationSchema={validationSchema} />
          )}
        </Grid>
        <Button size="large" color="primary" variant="contained" sx={{ width: 1 / 2 }} type="submit">
          {buttonText || 'Submit'}
        </Button>
      </Stack>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  );
};

export default InboundReceiverForm;
