import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { Affiliate } from "../models/common";
import { fetchProfile } from "../services/CommonApis";
import { ContextState, useDispatchStore, useStateStore } from "../store";
import { getIdFromAuth0Sub } from "../utils/common";
import Footer from "./Footer";
import Loading from "./Loading";
import NavBar from "./NavBar";
import getContentfulConfig from "./../contentful_config";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

interface LayoutProps {
  children: any;
  hideBorder?: any;
}

//@ts-ignore
const Layout = ({ children, hideBorder }: LayoutProps) => {
  const { affiliationShortName } = useParams<{
    affiliationShortName?: string;
  }>();
  const dispatch = useDispatchStore();
  const store = useStateStore();
  const history = useHistory();
  const { user, getAccessTokenSilently, isAuthenticated, logout } = useAuth0();

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  // profile faile alert
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    logout();
  };

  React.useEffect(() => {
    if (affiliationShortName) fetchAffiliation(affiliationShortName);

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const getToken = async () => {
      const accessToken = await getAccessTokenSilently();
      sessionStorage.setItem("token", accessToken);
    };
    if (isAuthenticated) {
      getToken();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  const fetchAffiliation = async (affiliationShortName: string) => {
    const query = `
        {
          transactionViewCollection(where: {shortName: "${affiliationShortName}"} limit: 1) {
            items {
              affiliateId
              name
              type
              shortName
              themeColor
              headline
              logoUrl {
                url
              }
              featuresCollection {
                items {
                  title
                  description
                }
              }
              sendCountriesCollection(limit: 5) {
                items {
                  name
                  code
                  currencyCollection(limit: 5) {
                    items {
                      code
                    }
                  }
                }
              }
              receiveCountriesCollection(limit: 100) {
                items {
                  name
                  code
                  currencyCollection(limit: 5) {
                    items {
                      code
                    }
                  }
                }
              }
              bankDetail {
                bsb
                accountNumber
                payId
              }
              threshold
              privacyStatement {
                json
                links {
                  entries {
                    inline {
                      sys {
                        id
                      }
                    }
                  }
                }
              }
              termsAndConditions {
                json
                links {
                  entries {
                    inline {
                      sys {
                        id
                      }
                    }
                  }
                }
              }
              contactUs {
                json
                links {
                  entries {
                    inline {
                      sys {
                        id
                      }
                    }
                  }
                }
              }
              app {
                appStoreUrl
                googlePlayUrl
                showAppDownload
              }
              poLi {
                enable
                poLiLogo {
                  url
                },
                sendConfirmationEmailToMerchant
                sendEmailLinkToCustomer
                expiryAfterDays
                allowSenderReference
                allowMultiplePayment
              }
            }
          }
        }
    `;
    const res = await window.fetch(
      `https://graphql.contentful.com/content/v1/spaces/${getContentfulConfig.CONTENTFUL_SPACEID}/environments/${getContentfulConfig.CONTENTFUL_ENV}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getContentfulConfig.CONTENTFUL_ACCESSTOKEN}`,
        },
        body: JSON.stringify({ query }),
      }
    );
    const { data, errors } = await res.json();
    if (errors) {
      console.error(errors);
      history.push("/");
    }
    if (data.transactionViewCollection.items.length > 0) {
      const newAffiliate: Affiliate = data.transactionViewCollection.items[0];
      localStorage.setItem("affiliationShortName", affiliationShortName);
      let newState: ContextState = {
        loading: false,
        affiliate: newAffiliate,
      };
      try {
        // fetch profile
        if (user && user.sub) {
          const profileRes = await fetchProfile(
            getIdFromAuth0Sub(user?.sub!),
            newAffiliate.affiliateId
          );
          newState.profile = profileRes.profile;
        }
        dispatch({
          type: "update",
          payload: newState,
        });
        if (user && (!newState.profile || !newState.profile.ExSenderId))
          history.push(
            `/${newAffiliate.shortName}/${newAffiliate.type}/registration`
          );
      } catch (error: any) {
        if (error.response && error.response.status === 403) {
          setOpen(true);
        } else {
          dispatch({
            type: "update",
            payload: newState,
          });
          history.push(
            `/${newAffiliate.shortName}/${newAffiliate.type}/registration`
          );
        }
      }
    } else {
      dispatch({
        type: "update",
        payload: {
          loading: false,
        },
      });
      history.push("/");
    }
  };
  if (store.loading)
    return (
      <React.Fragment>
        <Loading />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Oops! Something went wrong, please try again later.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  return (
    <React.Fragment>
      <NavBar hideBorder={hideBorder} />
      <Box sx={{ minHeight: windowDimensions.height - 315 }}>{children}</Box>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
