import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Link as RouterLink } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { Container, Typography, Button, Box, Stepper, StepLabel, Step, Stack, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from "@mui/material";
import InboundProfileForm from "../../components/inbound/ProfileForm";
import Loading from "../../components/Loading";
import Layout from "../../components/Layout";
import { CreateInboundSenderRequest } from "../../models/Inbound";
import { useStateStore } from "../../store";

const initialProfile: CreateInboundSenderRequest = {
  AffiliateSenderId: '',
  FirstName: '',
  LastName: '',
  EmailAddress: '',
  AddressLine1: '',
  Suburb: '',
  State: '',
  Postcode: '',
  Country: '',
  MobileInternationalDialCode: '',
  Mobile: '',
  DateOfBirth: '',
  IsIndividual: true,
};

const steps = ['Personal information', 'Done!'];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#60C637',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#60C637',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#60C637',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#60C637',
      zIndex: 1,
      fontSize: 30,
    },
    '& .QontoStepIcon-circle': {
      width: 15,
      height: 15,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <CheckCircle className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const Registration = () => {
  const {
    logout,
  } = useAuth0();
  const state = useStateStore();
  const [openPrivacyStatement, setOpenPrivacyStatement] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleDisagreeStatement = () => {
    setOpenPrivacyStatement(false);
    logout({
      returnTo: `${window.location.origin}?affiliationShortName=${state.affiliate.shortName}`,
    });
  };

  const handleAgreeStatement = () => {
    setOpenPrivacyStatement(false);
  };

  React.useEffect(() => {
    if (state.affiliate.privacyStatement) setOpenPrivacyStatement(true);
  }, [state])

  return (
    <Layout>
      <Container maxWidth="xl" sx={{ p: 3, maxWidth: 1000 }}>
        <Stack alignItems='center' >
          <Typography variant="h5" component="div" sx={{ pb: 2, fontWeight: 600 }} >
            Complete your registration
          </Typography>
          <Typography variant="body1" component="div" sx={{ pb: 2, color: '#999' }} >
            Let’s get you registered so you can send money safely and securely
          </Typography>
          <Box sx={{ my: 2, width: 1 }}>
            <Stepper sx={{ mb: 4 }} alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon} >{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && <InboundProfileForm isStepper formValues={initialProfile} cb={handleNext} />}
            {activeStep === 1 &&
              <Stack alignItems='center' spacing={2}>
                <CheckCircle sx={{ color: '#60C637', fontSize: 100, m: 3 }} />
                <Typography variant="h4" component="div" sx={{ }} >
                  All done!
                </Typography>
                <Typography variant="body1" component="div" sx={{  }} >
                  You can send money now.
                </Typography>
                <Button variant="contained" component={RouterLink} to={`/${state.affiliate.shortName}`}>
                  Send Money
                </Button>
              </Stack>
            }

          </Box>
          {/* <Button onClick={handleNext}>next</Button> */}
        </Stack>
      </Container>
      <Dialog
        fullScreen={fullScreen}
        open={openPrivacyStatement}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Privacy Statement
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {state.affiliate.privacyStatement && documentToReactComponents(state.affiliate.privacyStatement['json'] as Document)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDisagreeStatement}>
            Disagree
          </Button>
          <Button onClick={handleAgreeStatement} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default withAuthenticationRequired(Registration, {
  onRedirecting: () => <Loading />,
});
