

const devAffilicates = {
  inbound: "pandain",
  outbound: "pandaout",
}

const prodAffilicates = {
  inbound: "pandain",
  outbound: "pandaout",
}

const getDemoAffiliates = (env: string) => {
  if (env === 'production') {
    return prodAffilicates;
  }
  return devAffilicates;
}

export default getDemoAffiliates(process.env.REACT_APP_ENV!);