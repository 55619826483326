import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link as RouterLink } from "react-router-dom";
import { Alert, Button, Stack } from '@mui/material';
import qs from 'qs';

const alertComp = (error?: Error) => {
  if (error && error.message) {
    return (
      <Alert severity="info">{error.message}</Alert>
    );
  }
  return <Alert severity="error">Oops! Something went wrong.</Alert>;
}

const Callback = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  const affiliationShortName =  qs.parse(props.location.search, { ignoreQueryPrefix: true }).affiliationShortName;
  const {
    error,
    isAuthenticated,
  } = useAuth0();

  const homeUrl = affiliationShortName ? `/${affiliationShortName}` : '/';

  if (isAuthenticated) props.history.push(homeUrl)

  return (
    <Stack direction="column" spacing={4} alignItems="center" sx={{ py: { xs: 4, sm: 10 } }}>
      {alertComp(error)}
      <Button component={RouterLink} to={homeUrl} variant="contained">Home</Button>
    </Stack>
  );
}



export default Callback;
