import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Container, Divider, Grid, Typography } from "@mui/material";
import AccountMenu from "../../components/AccountMenu";
import Loading from "../../components/Loading";
import Layout from "../../components/Layout";
import UploadDocumentForm from "../../components/outbound/UploadDocumentForm";

const UploadDocment = () => {
  return (
    <Layout>
      <Container maxWidth="xl" sx={{ p: 3 }}>
        <Grid container spacing={5} justifyContent="center"
          alignItems="flex-start">
          <Grid item xs={2} sm={2} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <AccountMenu selected="profile" />
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="h4" component="div" sx={{ pb: 2 }} >
              Upload Document
            </Typography>
            <Divider />
            <Container disableGutters sx={{ p: { xs: 1, sm: 2 }, my: 1 }}>
            <UploadDocumentForm />
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default withAuthenticationRequired(UploadDocment, {
  onRedirecting: () => <Loading />,
});
