

const devApiUrl = 'https://api.novattitech.com/remit';
const prodApiUrl = 'https://api.novattixb.com/remit';

const getApiUrl = (env: string) => {
  if (env === 'production') {
    return prodApiUrl;
  }
  return devApiUrl;
}

export default getApiUrl(process.env.REACT_APP_ENV!);