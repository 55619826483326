import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Button, Card, CardHeader, Container, Divider, Grid, IconButton, Stack, } from "@mui/material";
import AccountMenu from "../../components/AccountMenu";
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import { CreateOutboundReceiverAccountRequest, OutboundReceiverAccount } from "../../models/Outbound";
import ReceiverAccountForm from "../../components/outbound/ReceiverAccountForm";
import Loading from "../../components/Loading";
import Layout from "../../components/Layout";
import { useStateStore } from "../../store";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getOutboundReceiverAccountList } from "../../services/OutboundApis";
import { getReceiverAccountText } from "../../utils/common";
import ReceiverAccountEditForm from "../../components/outbound/ReceiverAccountEditForm";

export const initialReceiverAccount: CreateOutboundReceiverAccountRequest = {
  SenderID: 0,
  ReceiverID: 0,
  BankCountryCode: '',
  CurrencyCode: '',
  // DeliveryMethod: '',
  BankingType: '',

  BankIdentification: '',
  BranchCity: '',
  // BankBranchCode: '',
  BranchIdentification: '',
  WalletProviderID: '',
  AccountName: '',
  AccountNumber: '',
};

type ReceiverAccountsParams = {
  receiverId: string;
  receiverName: string;
  countryCode: string;
};

const ReceiverAccounts = () => {
  const [receiverAccountFormValue, setReceiverAccountFormValue] = React.useState<CreateOutboundReceiverAccountRequest>(initialReceiverAccount);
  const [openNewAccount, setOpenNewAccount] = React.useState(false);
  const [openEditAccount, setOpenEditAccount] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [receiverAccounts, setReceiverAccounts] = React.useState<OutboundReceiverAccount[]>([]);
  const [updatingReceiverAccount, setUpdatingReceiverAccount] = React.useState<OutboundReceiverAccount>();

  const state = useStateStore();

  let { receiverId, receiverName, countryCode } = useParams<ReceiverAccountsParams>();

  React.useEffect(() => {
    if (state.profile) getReceiverAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.profile]);

  const getReceiverAccounts = async () => {
    try {
      setLoading(true);
      const res = await getOutboundReceiverAccountList(state.affiliate.affiliateId, receiverId);
      setReceiverAccounts(res.accounts);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const handleAddAccount = () => {
    setReceiverAccountFormValue({
      ...initialReceiverAccount, 
      BankCountryCode: countryCode,
      AccountName: receiverName,
    });
    setOpenNewAccount(true);
  };

  const handleEditAccount = (index: number) => {
    const receiverAccount = receiverAccounts[index];
    setUpdatingReceiverAccount(receiverAccount)
    setOpenEditAccount(true);
  };

  const handleCloseNewAccount = () => {
    setOpenNewAccount(false);
  };

  const handleCloseEditAccount = () => {
    setOpenEditAccount(false);
  };

  const handleNewAccountSubmitSuccess = (accountId: string, accountList: OutboundReceiverAccount[]) => {
    toast.success('Your information has been submitted.', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    setOpenNewAccount(false);
    setReceiverAccounts(accountList);
  }

  const handleEditAccountSubmitSuccess = () => {
    toast.success('Your information has been submitted.', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    setOpenEditAccount(false);
    getReceiverAccounts();
  }

  const getReceiverAccountsWidget = () => {
    if (loading) return <Loading />;
    if (!loading && receiverAccounts.length === 0) {
      return (
        <Box sx={{ textAlign: 'center', p: 8 }}><Typography sx={{ color: '#666' }} variant="caption" component="span">
          No Receiver Found
        </Typography></Box>
      );
    }

    return receiverAccounts.map((receiverAccount: OutboundReceiverAccount, index: number) =>
      <Card key={receiverAccount.kaasiAccountID} variant="outlined" sx={{ my: 2, pr: 2 }}>
        <CardHeader
          avatar={
            <Typography component="span" sx={{ fontSize: { xs: 16, sm: 20 } }}>
              {getReceiverAccountText(receiverAccount)}
            </Typography>
          }
          action={
            <Box sx={{ display: 'flex', mt: 0 }}>
              <Button variant="text" onClick={() => handleEditAccount(index)}>
                Edit
              </Button>
            </Box>
          }
        />
      </Card>
    );
  }


  return (
    <Layout>
      <Container maxWidth="xl" sx={{ p: 3 }}>
        <Grid container spacing={5} justifyContent="center"
          alignItems="flex-start">
          <Grid item xs={2} sm={2} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <AccountMenu selected="receivers" />
          </Grid>
          <Grid item xs={12} sm={10}>
            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ pb: 2 }} alignItems={{ xs: "flex-start", sm: "center" }}>
              <Typography variant="h4" component="div"  >
                Payment Methods
              </Typography>
              <Typography variant="subtitle1" component="div" sx={{ color: '#888', ml: { xs: 0, sm: 2 } }} >
                ({receiverName})
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent={{ xs: "center", sm: "flex-end" }}>
              <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddAccount}>
                Add Payment Method
              </Button>
            </Stack>
            {getReceiverAccountsWidget()}
          </Grid>
        </Grid>
        <Dialog open={openNewAccount} onClose={handleCloseNewAccount}>
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between" >
              <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
                Add Payment Method
              </Typography>
              <IconButton aria-label="close" color="secondary" onClick={handleCloseNewAccount}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Container disableGutters sx={{ py: 1, my: 1 }}>
              <ReceiverAccountForm cb={handleNewAccountSubmitSuccess} formValues={receiverAccountFormValue} receiverId={parseInt(receiverId)} />
            </Container>
          </DialogContent>
        </Dialog>
        {updatingReceiverAccount ?
          <Dialog open={openEditAccount} onClose={handleCloseEditAccount}>
            <DialogTitle>
              <Stack direction="row" justifyContent="space-between" >
                <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
                  Update Payment Method
                </Typography>
                <IconButton aria-label="close" color="secondary" onClick={handleCloseEditAccount}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Container disableGutters sx={{ py: 1, my: 1 }}>
                <ReceiverAccountEditForm cb={handleEditAccountSubmitSuccess} receiverAccount={updatingReceiverAccount!} receiverId={parseInt(receiverId)} />
              </Container>
            </DialogContent>
          </Dialog>
          : null}
      </Container>
    </Layout>
  );
};

export default withAuthenticationRequired(ReceiverAccounts, {
  onRedirecting: () => <Loading />,
});