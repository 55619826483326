/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useFormik } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import { Button, Stack, Grid, Backdrop, CircularProgress, Card } from "@mui/material";
import { CFCountry, Currency, FormItem, FormItemSelectValue, SenderProfile } from "../../models/common";
// import { FormItems } from "../../utils/form";
import { ReceiverAccountFormItems } from "./ReceiverAccountFormItems";
import FormItemComponent from "../FormItem";
import { Bank, BankBranch, BankBranchListResponse, BankListResponse, CreateOutboundReceiverAccountRequest, WalletProvider, WalletProviderListResponse } from "../../models/Outbound";
import { useStateStore } from "../../store";
import { CreateOutboundReceiverAccount, fetchBankBranchList, fetchBankList, fetchWalletProviderList, getOutboundReceiverAccountList } from "../../services/OutboundApis";
import { toast } from "react-toastify";

const validationSchema = yup.object({
  BankingType: yup
    .string()
    .required('Payment Method is required'),
  BankIdentification: yup
    .string()
    .when('BankingType', {
      is: (BankingType: any) => ['2'].includes(BankingType),
      then: yup.string()
        .required('Bank Identification is required'),
    }),
  BranchCity: yup
    .string(),
  BranchIdentification: yup
    .string()
    .when('BankingType', {
      is: (BankingType: any) => ['1', '2', '3', '5', '6', '7', '10'].includes(BankingType),
      then: yup.string()
        .required('Branch Identification is required'),
    }),
  WalletProviderID: yup
    .string()
    .when('BankingType', {
      is: (BankingType: any) => ['4'].includes(BankingType),
      then: yup.string()
        .required('Wallet Provider ID is required'),
    }),
  AccountName: yup
    .string()
    .max(120)
    .when('BankingType', {
      is: (BankingType: any) => ['4'].includes(BankingType),
      then: yup.string()
        .required('Wallet Name is required'),
      otherwise: yup.string()
        .required('Account Name is required'),
    }),
  AccountNumber: yup
    .string()
    .max(34)
    .when('BankingType', {
      is: (BankingType: any) => ['1', '2', '3', '5'].includes(BankingType),
      then: yup.string()
        .required('Account Number is required'),
    })
    .when('BankingType', {
      is: (BankingType: any) => ['4'].includes(BankingType),
      then: yup.string()
        .required('Wallet Number is required'),
    }),
  CurrencyCode: yup
    .string()
    .matches(/^[A-Z]{3}$/, "Must be exactly 3 letter")
    .required('Currency Code is required'),
  BankCountryCode: yup
    .string()
    .matches(/^[A-Z]{3}$/, "Must be exactly 3 letter")
    .required('Country is required'),
});

// const initialValuesArray: FormItem[] = [];
// for (var key of Object.keys(initialValues)) {
//   if (ReceiverAccountFormItems[key]) initialValuesArray.push(ReceiverAccountFormItems[key]);
// }

const formValues2FormItemsArray = (formValues: any): FormItem[] => {
  const formItemsArray: FormItem[] = [];
  for (var key of Object.keys(formValues)) {
    if (ReceiverAccountFormItems[key]) {
      switch (key) {
        case 'BankIdentification':
        case 'BranchIdentification':
        case 'WalletProviderID':
        case 'BankBranchCode':
        case 'BranchCity':
          var t = ReceiverAccountFormItems[key];
          t.isHidden = true;
          formItemsArray.push(t)
          break;
        default:
          formItemsArray.push(ReceiverAccountFormItems[key])
          break;
      }
    };
  }

  return formItemsArray;
}

interface ReceiverAccountFormProps {
  formValues: CreateOutboundReceiverAccountRequest;
  cb: Function,
  receiverId: number,
  buttonText?: string;
}

const ReceiverAccountForm = ({ formValues, cb, receiverId, buttonText }: ReceiverAccountFormProps) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [formItemsArray, setFormItemsArray] = React.useState(formValues2FormItemsArray(formValues));
  const [bankList, setBankList] = React.useState<Bank[]>([]);
  const [bankBranchList, setBankBranchList] = React.useState<BankBranch[]>([]);

  const state = useStateStore();
  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit: async (values: CreateOutboundReceiverAccountRequest) => {
      try {
        setSubmitting(true);
        const res = await CreateOutboundReceiverAccount(state.affiliate.affiliateId,
          {
            ...values,
            ReceiverID: receiverId,
            SenderID: parseInt((state.profile as unknown as SenderProfile)?.ExSenderId),
          } as CreateOutboundReceiverAccountRequest,
        );
        const listRes = await getOutboundReceiverAccountList(state.affiliate.affiliateId, String(receiverId));
        cb(res.KAASIAccountID, listRes.accounts);

      } catch (error) {
        console.log(error)
      } finally {
        setSubmitting(false);
      }
    },
  });

  React.useEffect(() => {
    const getBankList = async () => {
      try {
        setSubmitting(true);
        const res = await fetchBankList(state.affiliate.affiliateId, formValues.BankCountryCode) as unknown as BankListResponse;
        setBankList(res.BankList);
        var newFormItemsArray = _.cloneDeep(formItemsArray);
        const BankIdentificationIndex = _.findIndex(formItemsArray, i => i.key === 'BankIdentification');
        const BranchIdentificationIndex = _.findIndex(formItemsArray, i => i.key === 'BranchIdentification');
        const WalletProviderIDIndex = _.findIndex(formItemsArray, i => i.key === 'WalletProviderID');
        const selectValues: FormItemSelectValue[] = [];
        res.BankList.forEach((b: Bank) => {
          selectValues.push({
            label: b.BankName,
            value: b.BankCode,
          });
        });
        newFormItemsArray[BankIdentificationIndex].selectValues = selectValues;
        newFormItemsArray[BankIdentificationIndex].isHidden = false
        newFormItemsArray[BankIdentificationIndex].isRequired = true;

        newFormItemsArray[WalletProviderIDIndex].isHidden = true
        newFormItemsArray[WalletProviderIDIndex].isRequired = false;
        newFormItemsArray[BranchIdentificationIndex].isRequired = true;

        const AccountNameIndex = _.findIndex(formItemsArray, i => i.key === 'AccountName');
        const AccountNumberIndex = _.findIndex(formItemsArray, i => i.key === 'AccountNumber');
        newFormItemsArray[AccountNameIndex].label = 'Account Name';
        newFormItemsArray[AccountNumberIndex].label = 'Account Number';

        setFormItemsArray(newFormItemsArray);
        if (res.BankList.length === 0) {
          toast.warn('Sorry, no banks avaliable. ', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          return
        }
      } catch (error) {
        console.log(error)
      } finally {
        setSubmitting(false);
      }

    }

    const getWalletList = async () => {
      try {
        setSubmitting(true);
        const res = await fetchWalletProviderList(state.affiliate.affiliateId, formValues.BankCountryCode) as WalletProviderListResponse;

        var newFormItemsArray = _.cloneDeep(formItemsArray);
        const BankIdentificationIndex = _.findIndex(formItemsArray, i => i.key === 'BankIdentification');
        const BranchIdentificationIndex = _.findIndex(formItemsArray, i => i.key === 'BranchIdentification');
        const WalletProviderIDIndex = _.findIndex(formItemsArray, i => i.key === 'WalletProviderID');
        const BranchCityIndex = _.findIndex(formItemsArray, i => i.key === 'BranchCity');

        const selectValues: FormItemSelectValue[] = [];
        res.WalletProviders.forEach((w: WalletProvider) => {
          selectValues.push({
            label: w.WalletProviderName,
            value: w.WalletProviderID,
          });
        });
        newFormItemsArray[WalletProviderIDIndex].selectValues = selectValues;
        newFormItemsArray[WalletProviderIDIndex].isHidden = false
        newFormItemsArray[WalletProviderIDIndex].isRequired = true;

        newFormItemsArray[BankIdentificationIndex].isHidden = true
        newFormItemsArray[BranchIdentificationIndex].isHidden = true;
        newFormItemsArray[BankIdentificationIndex].isRequired = false;
        newFormItemsArray[BranchIdentificationIndex].isRequired = false;
        newFormItemsArray[BranchCityIndex].isHidden = true;

        const AccountNameIndex = _.findIndex(formItemsArray, i => i.key === 'AccountName');
        const AccountNumberIndex = _.findIndex(formItemsArray, i => i.key === 'AccountNumber');
        newFormItemsArray[AccountNameIndex].label = 'Wallet Name';
        newFormItemsArray[AccountNumberIndex].label = 'Wallet Number';

        setFormItemsArray(newFormItemsArray);
        if (res.WalletProviders.length === 0) {
          toast.warn('Sorry, no wallet providers avaliable. ', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          return
        }
      } catch (error) {
        console.log(error)
      } finally {
        setSubmitting(false);
      }
    }

    var bankingType = formik.values.BankingType as string;
    if (bankingType) {
      formik.values.WalletProviderID = '';
      formik.values.BankIdentification = '';
      formik.values.BranchIdentification = '';
      formik.values.BranchCity = '';
      // formik.values.AccountName = '';
      formik.values.AccountNumber = '';
    }
    if (bankingType === '2') {
      getBankList();
    }
    if (bankingType === '4') {
      getWalletList();
    }

  }, [formik.values.BankingType]);

  React.useEffect(() => {
    const getBranchList = async (BankIdentification: string) => {
      try {
        setSubmitting(true);
        const res = await fetchBankBranchList(state.affiliate.affiliateId, BankIdentification) as BankBranchListResponse;

        setBankBranchList(res.BranchList);
        var newFormItemsArray = _.cloneDeep(formItemsArray);
        const BranchIdentificationIndex = _.findIndex(formItemsArray, i => i.key === 'BranchIdentification');
        const BranchCityIndex = _.findIndex(formItemsArray, i => i.key === 'BranchCity');
        const selectValues: FormItemSelectValue[] = [];

        res.BranchList.forEach((b: BankBranch) => {
          selectValues.push({
            label: b.BranchName,
            value: b.BranchCode,
          });
        });
        newFormItemsArray[BranchIdentificationIndex].selectValues = selectValues;
        newFormItemsArray[BranchIdentificationIndex].isHidden = false
        newFormItemsArray[BranchIdentificationIndex].isRequired = true;

        // group branch city list
        const cities = _.chain(res.BranchList)
          .filter(b => (b.BranchCity !== null && b.BranchCity !== ''))
          .groupBy("BranchCity")
          .map((value, key) => ({ city: key }))
          .value();

        const citySelectValues: FormItemSelectValue[] = [{
          label: 'All',
          value: 'ALL',
        },];
        cities.forEach((city) => {
          citySelectValues.push({
            label: city.city,
            value: city.city,
          });
        });
        newFormItemsArray[BranchCityIndex].selectValues = citySelectValues;
        newFormItemsArray[BranchCityIndex].isHidden = false
        setFormItemsArray(newFormItemsArray);
        formik.values.BranchCity = 'ALL';

        if (res.BranchList.length === 0) {
          toast.warn('Sorry, no branches avaliable. ', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          return
        }
      } catch (error) {
        console.log(error)
      } finally {
        setSubmitting(false);
      }
    }

    var BankIdentification = formik.values.BankIdentification as string;
    if (BankIdentification) {
      formik.values.BranchIdentification = '';
      getBranchList(BankIdentification);
    }
  }, [formik.values.BankIdentification]);

  React.useEffect(() => {
    const filterBranchListByCity = (BranchCity: string) => {

      var newFormItemsArray = _.cloneDeep(formItemsArray);
      const BranchIdentificationIndex = _.findIndex(formItemsArray, i => i.key === 'BranchIdentification');

      const selectValues: FormItemSelectValue[] = [];
      let filterBranchList: BankBranch[] = [];
      if (BranchCity === 'ALL') {
        filterBranchList = bankBranchList;
      } else {
        filterBranchList = _.filter(bankBranchList, b => b.BranchCity === BranchCity)
      }

      filterBranchList.forEach((b: BankBranch) => {
        selectValues.push({
          label: b.BranchName,
          value: b.BranchCode,
        });
      });

      newFormItemsArray[BranchIdentificationIndex].selectValues = selectValues;
      setFormItemsArray(newFormItemsArray);
    }

    var BranchCity = formik.values.BranchCity as string;
    if (BranchCity) {
      formik.values.BranchIdentification = '';
      filterBranchListByCity(BranchCity);
    }
  }, [formik.values.BranchCity]);

  React.useEffect(() => {
    const initCurrencyOptions = (CountryCode : string) => {
      var newFormItemsArray = _.cloneDeep(formItemsArray);
      const CurrencyIndex = _.findIndex(formItemsArray, i => i.key === 'CurrencyCode');
      const selectValues: FormItemSelectValue[] = [];

      const receiveCountriesCollection = _.filter(
        state.affiliate.receiveCountriesCollection.items,
        item => item.code.toLowerCase() === CountryCode.toLowerCase()
      );

      receiveCountriesCollection?.forEach((country:CFCountry) => {
        country.currencyCollection?.items?.forEach((c: Currency) => {
          selectValues.push({
            label: c.code,
            value: c.code          
          })
        })
      });

      newFormItemsArray[CurrencyIndex].selectValues = selectValues;
      setFormItemsArray(newFormItemsArray);

      // Set default CurrencyCode value if not exist
      if (selectValues.length > 0 && !formik.values.CurrencyCode) {
        formik.setFieldValue('CurrencyCode', selectValues[0].value)
      }
    }
    
    const CountryCode = formik.values.BankCountryCode as string;
    if (CountryCode) {
      initCurrencyOptions(CountryCode);
    }

  },[formik.values.BankCountryCode])

  let selectBank, selectedBranch;
  if (formik.values.BankIdentification) {
    selectBank = _.find(bankList, b => b.BankCode === formik.values.BankIdentification)
  }
  if (formik.values.BranchIdentification) {
    selectedBranch = _.find(bankBranchList, b => b.BranchCode === formik.values.BranchIdentification)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack alignItems='center' spacing={4}>
        <Grid container spacing={2} alignItems='center'>
          {formItemsArray.map((item: FormItem) =>
            <FormItemComponent key={item.key} item={item} formik={formik} validationSchema={validationSchema} />
          )}
          {(selectBank) ?
            <Card variant="outlined" sx={{
              width: 1,
              ml: 2,
              my: 2,
              p: 2,
              bgcolor: 'primary.light',
              fontSize: 13,
              lineHeight: 1.6,
            }}>
              <b>Bank Name:</b> {selectBank.BankName} ({selectBank.BankCode})<br />
              <b>Address:</b> {selectBank.AddressLine1} {selectBank.City} {selectBank.State} {selectBank.Postcode}<br />
              {selectedBranch &&
                <span><b>Branch Name:</b> {selectedBranch.BranchName} ({selectedBranch.BranchCode})<br />
                </span>
              }
            </Card>
            : null}
        </Grid>
        <Button size="large" color="primary" variant="contained" sx={{ width: 1 / 2 }} type="submit">
          {buttonText || 'Submit'}
        </Button>
      </Stack>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  );
};

export default ReceiverAccountForm;
