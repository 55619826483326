import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Container, Divider, Grid, Typography, Alert, Stack, Card, CardContent } from "@mui/material";
import AccountMenu from "../../components/AccountMenu";
import Loading from "../../components/Loading";
import Layout from "../../components/Layout";
import { fetchProfile } from "../../services/CommonApis";
import { getIdFromAuth0Sub } from "../../utils/common";
import { SenderProfile } from "../../models/common";
import { useStateStore } from "../../store";

const Profile = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [profile, setProfile] = React.useState<SenderProfile>();
  const state = useStateStore();
  const {
    user,
  } = useAuth0();
  React.useEffect(() => {
    if (user && user.sub) getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProfile = async () => {
    try {
      setLoading(true);
      const res = await fetchProfile(getIdFromAuth0Sub(user?.sub!), state.affiliate.affiliateId);
      setProfile(res.profile);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const getFormWidget = () => {
    if (loading) return <Loading />;
    if (profile) return (
      <Card variant="outlined" sx={{ my: 2 }}>
        <CardContent >
          <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
            <Typography color="#777" variant="body1" component="div">
              First Name: {profile.FirstName}
            </Typography>
            <Typography color="#777" variant="body1" component="div">
              Last Name: {profile.LastName}
            </Typography>
            <Typography color="#777" variant="body1" component="div">
              Email Address: {profile.EmailAddress}
            </Typography>
            <Typography color="#777" variant="body1" component="div">
              Mobile International Dial Code: {profile.MobileInternationalDialCode}
            </Typography>
            <Typography color="#777" variant="body1" component="div">
              Mobile: {profile.Mobile}
            </Typography>
            <Typography color="#777" variant="body1" component="div">
              Address: {profile.AddressLine1}
            </Typography>
            <Typography color="#777" variant="body1" component="div">
              Suburb: {profile.Suburb}
            </Typography>
            <Typography color="#777" variant="body1" component="div">
              State: {profile.State}
            </Typography>
            <Typography color="#777" variant="body1" component="div">
              Postcode: {profile.Postcode}
            </Typography>
            <Typography color="#777" variant="body1" component="div">
              Country: {profile.Country}
            </Typography>
            <Typography color="#777" variant="body1" component="div">
              Occupation: {profile.Occupation}
            </Typography>
            <Typography color="#777" variant="body1" component="div">
              Nationality: {profile.Nationality}
            </Typography>
            <Typography color="#777" variant="body1" component="div">
              Date Of Birth: {profile.DateOfBirth}
            </Typography>
            <Typography color="#777" variant="body1" component="div">
              Country Of Birth: {profile.CountryOfBirth}
            </Typography>
            <Typography color="#777" variant="body1" component="div">
              Place Of Birth: {profile.PlaceOfBirth}
            </Typography>
            <Typography color="#777" variant="body1" component="div">
              Kyc Status: {profile.KycStatus}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    );
    return <Alert severity="error">Oops! Something wrong.</Alert>
  }

  return (
    <Layout>
      <Container maxWidth="xl" sx={{ p: 3 }}>
        <Grid container spacing={5} justifyContent="center"
          alignItems="flex-start">
          <Grid item xs={2} sm={2} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <AccountMenu selected="profile" />
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="h4" component="div" sx={{ pb: 2 }} >
              Profile
            </Typography>
            <Divider />
            <Container disableGutters sx={{ my: 2 }}>
              {getFormWidget()}
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default withAuthenticationRequired(Profile, {
  onRedirecting: () => <Loading />,
});
