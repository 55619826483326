import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Link as RouterLink } from "react-router-dom";
import { Card, Container, Grid, Stack, Typography, Divider, Link, CardContent, Box, Pagination, TextField, MenuItem, Chip } from "@mui/material";
import AccountMenu from "../../components/AccountMenu";
import Loading from "../../components/Loading";
import Layout from "../../components/Layout";
import { FetchTransactionListParams, SenderProfile } from "../../models/common";
import { useStateStore } from "../../store";
import moment from "moment";
import { InboundTransaction } from "../../models/Inbound";
import { fetchInboundTransactionList } from "../../services/InboundApis";

const LIMIT = 20;

const STATUS_OPTIONS = ["ALL", "PENDING", "SUCCESSFUL", "CANCELLED", "FAILED", "IN PROGRESS", "PAYMENT FAILED"];

const Transactions = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [transactions, setTransactions] = React.useState<InboundTransaction[]>([]);
  const [page, setPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);
  const [status, setStatus] = React.useState<string>('ALL');
  const state = useStateStore();

  React.useEffect(() => {
    if (state.profile) getTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.profile, ]);

  React.useEffect(() => {
    if (state.profile) getTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, status]);

  const getTransactions = async () => {
    try {
      setLoading(true);
      const params: FetchTransactionListParams = {
        senderId: (state.profile as unknown as SenderProfile)?.ExSenderId,
        limit: LIMIT,
        offset: (page - 1) * LIMIT,
      }
      if (status !== 'ALL') params.status = status;
      const res = await fetchInboundTransactionList(state.affiliate.affiliateId, params);
      setTransactions(res.result);
      setTotal(res.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const changePage = (page: number) => {
    setPage(page);
  }

  const changeStatus = (newStatus: string) => {
    if (status === newStatus) return;
    console.log(newStatus)
    setStatus(newStatus);
    setPage(1);
  }



  const getTransactionsWidget = () => {
    if (loading) return <Loading />;
    if (!loading && transactions.length === 0) {
      return (
        <Box sx={{ textAlign: 'center', p: 8 }}><Typography sx={{ color: '#666' }} variant="caption" component="span">
          No Transaction Found
        </Typography></Box>
      );
    }


    return (<React.Fragment>
      {transactions.map((tx: InboundTransaction) =>
        <Card variant="outlined" sx={{ my: 2, px: 1 }}>
          <CardContent >
            <Typography color="#777" variant="body2" component="div">
              Transaction ID: {tx.transactionID}
            </Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 0, py: 1 }}>
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent={{ xs: 'center', sm: 'flex-start' }} sx={{ pb: 1, borderBottom: 1, borderBottomColor: '#ccc', width: { xs: 1, sm: 'auto' } }}>
                <Typography variant="h4" component="span" sx={{ fontWeight: 'bold', fontSize: 29 }}>
                  AUD {tx.dollarAmount}
                </Typography>
                {/* <NavigateNextIcon fontSize="large" sx={{ display: { xs: 'none', sm: 'inline' } }} /> */}
                {/* <ExpandMoreIcon fontSize="large" sx={{ display: { xs: 'block', sm: 'none' } }} /> */}

              </Stack>
              <Link sx={{ display: { xs: 'none', sm: 'inline' } }} component={RouterLink} to={`/${state.affiliate.shortName}/inbound/transaction/${tx.transactionID}`}>Detail</Link>
            </Stack>
            {/* <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
            Send to: Gary Lee (Australia)
          </Typography> */}
            <Typography color="#777" variant="body2" component="div" sx={{ pt: 0 }}>
              Transaction date: {moment(tx.dateOfTransaction).format('DD/MM/YYYY hh:mm:ss')}
            </Typography>
            <Chip variant="outlined" color="primary" label={tx.status} sx={{ fontWeight: 'bold' }} />
            <Link sx={{ display: { xs: 'block', sm: 'none' } }} component={RouterLink} to={`/${state.affiliate.shortName}/inbound/transaction/${tx.transactionID}`}>Detail</Link>

          </CardContent>
        </Card>
      )}
      <Pagination count={Math.ceil(total / LIMIT)} page={page} onChange={(e, p) => changePage(p)} variant="outlined" color="primary" />
    </React.Fragment>
    );
  }

  return (
    <Layout>
      <Container maxWidth="xl" sx={{ p: 3 }}>
        <Grid container spacing={5} justifyContent="center"
          alignItems="flex-start">
          <Grid item xs={2} sm={2} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <AccountMenu selected="transactions" />
          </Grid>
          <Grid item xs={12} sm={10}>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} sm={9}>
                <Typography variant="h4" component="div" sx={{ pb: 2 }} >
                  Transactions
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} sx={{ textAlign: 'right' }}>
                <TextField
                  size='small'
                  select
                  variant="outlined"
                  fullWidth
                  id='status'
                  name='status'
                  label='Filter by Status'
                  value={status}
                  onChange={(e) => changeStatus(e.target.value)}
                >
                  {STATUS_OPTIONS.map((e: string) => (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Divider />
            {getTransactionsWidget()}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default withAuthenticationRequired(Transactions, {
  onRedirecting: () => <Loading />,
});
