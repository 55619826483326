import React from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Stack, Grid, Alert, } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { FormItem, SenderProfile } from "../../models/common";
import { UploadDocumentFormItems } from "./UploadDocumentFormItems";
import FormItemComponent from "../FormItem";
import { useStateStore } from "../../store";
import { toast } from "react-toastify";
import _ from "lodash";
import { runKYCForSender, UploadSenderPrimaryDocument, UploadSenderSupportingDocument } from "../../services/OutboundApis";
import { UploadSenderPrimaryDocumentRequest, UploadSenderSupportingDocumentRequest } from "../../models/Outbound";

const initialUploadDocument: any = {
  DocumentType: '',
  DocumentNumber: '',
  DocIssueCountry: '',
  DocIssueState: '',
  CardNumber: '',
  ExpiryDate: '',
  IssueDate: '',
  DocumentFront: '',
  DocumentBack: '',
  Support_DocumentType: '',
  Support_DocumentName: '',
  Support_Description: '',
  // Support_TransactionID: '',
  Support_Document: '',
};

const AUS_CARD_NUMBER: {[index: string]: string} = {
  'NSW': '^[0-9]{10}$',
  'VIC': '^[a-zA-Z0-9]{8}$',
  'QLD': '^[a-zA-Z0-9]{10}$',
  'ACT': '^[a-zA-Z0-9]{10}$',
  'SA': '^[a-zA-Z0-9]{9}$',
  'TAS': '^[a-zA-Z0-9]{9}$',
  'WA': '^[a-zA-Z0-9]{8,10}$',
  'NT': '^[0-9]{6,8}$'
}

const validationSchema = yup.object({
  DocumentType: yup
    .string()
    .required('Document Type is required')
    .max(15, 'Maximum 15 letters'),
  DocumentNumber: yup
    .string()
    .required('Document Number is required')
    .max(50, 'Maximum 50 letters'),
  DocIssueCountry: yup
    .string()
    .when('DocumentType', {
      is: (DocumentType: any) => ['P'].includes(DocumentType),
      then: yup.string()
        .required('Issuing Country is required'),
    }),
  DocIssueState: yup
    .string()
    .when('DocumentType', {
      is: (DocumentType: any) => ['DL', 'PID'].includes(DocumentType),
      then: yup.string()
        .required('Issuing State is required'),
    }),
  CardNumber: yup
    .string()
    .when('DocumentType', {
      is: (DocumentType: string) => ['DL'].includes(DocumentType),
      then: yup.string()
        .required('Card Number is required')
        .when('DocIssueState', (state, schema) => {
          return schema.test({
            test: (cardNumber: string) => state && new RegExp(AUS_CARD_NUMBER[state]).test(cardNumber),
            message: 'Card Number is not valid'
          })
        }),
    }),
  ExpiryDate: yup
    .string()
    .when('DocumentType', {
      is: (DocumentType: any) => (DocumentType === 'P' || DocumentType === 'DL'),
      then: yup.string()
        .required('Expiry Date is required'),
    }),
  IssueDate: yup
    .string()
    .when('DocumentType', {
      is: (DocumentType: any) => (DocumentType === 'P' || DocumentType === 'PID'),
      then: yup.string()
        .required('Issue Date is required'),
    }),
  DocumentFront: yup
    .string()
    .required('Document Front is required'),
  DocumentBack: yup
    .string()
    .when('DocumentType', {
      is: (DocumentType: any) => DocumentType === 'DL',
      then: yup.string()
        .required('Document Back is required'),
    }),
  Support_DocumentType: yup
    .string()
    .when('DocumentType', {
      is: (DocumentType: any) => DocumentType === 'P',
      then: yup.string()
        .required('Supporting Document Type is required'),
    }),
  Support_DocumentName: yup
    .string()
    .when('DocumentType', {
      is: (DocumentType: any) => DocumentType === 'P',
      then: yup.string()
        .required('Supporting Document Name is required'),
    })
    .max(495, 'Maximum 495 letters'),
  Support_Description: yup
    .string()
    .when('DocumentType', {
      is: (DocumentType: any) => DocumentType === 'P',
      then: yup.string()
        .required('Supporting Document Description is required'),
    })
    .max(1500, 'Maximum 1500 letters'),
  Support_TransactionID: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits"),
  Support_Document: yup
    .string()
    .when('DocumentType', {
      is: (DocumentType: any) => DocumentType === 'P',
      then: yup.string()
        .required('Supporting Document is required'),
    }),
});

const formValues2FormItemsArray = (formValues: any): FormItem[] => {
  const formItemsArray: FormItem[] = [];
  for (var key of Object.keys(formValues)) {
    if (UploadDocumentFormItems[key] && !key.includes('Support_')) {
      switch (key) {
        default:
          formItemsArray.push(UploadDocumentFormItems[key])
          break;
      }
    };
  }
  return formItemsArray;
}

const formValues2SupportDocumentFormItemsArray = (formValues: any): FormItem[] => {
  const formItemsArray: FormItem[] = [];
  for (var key of Object.keys(formValues)) {
    if (UploadDocumentFormItems[key] && key.includes('Support_')) {
      switch (key) {
        default:
          formItemsArray.push(UploadDocumentFormItems[key])
          break;
      }
    };
  }
  return formItemsArray;
}

interface UploadFormProps {
  cb?: Function;
  isStepper?: boolean;
}

const UploadDocumentForm = ({ cb, isStepper = false }: UploadFormProps) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [formItemsArray, setFormItemsArray] = React.useState(formValues2FormItemsArray(initialUploadDocument));
  const [supportDocumentFormItemsArray, setSupportDocumentFormItemsArray] = React.useState(formValues2SupportDocumentFormItemsArray(initialUploadDocument));
  const [supportDocument, setSupportDocument] = React.useState(false);
  const state = useStateStore();
  const formik = useFormik({
    initialValues: initialUploadDocument,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: async (values: any) => {
      try {
        setSubmitting(true);
        const primaryDocRequrestBody: UploadSenderPrimaryDocumentRequest = {
          SenderID: parseInt((state.profile as unknown as SenderProfile)?.ExSenderId),
          DocumentType: values.DocumentType,
          DocumentNumber: values.DocumentNumber,
          StateOrCountry: values.DocumentType === 'P' ? values.DocIssueCountry : values.DocIssueState,
          CardNumber: values.CardNumber,
          ExpiryDate: values.ExpiryDate,
          IssueDate: values.IssueDate,
          Document: {
            Front: values.DocumentFront,
            Back: values.DocumentBack,
          },
        }
        await UploadSenderPrimaryDocument(state.affiliate.affiliateId, primaryDocRequrestBody);
        // upload address supporting doc for passport
        if (values.DocumentType === 'P') {
          const supportingDocRequrestBody: UploadSenderSupportingDocumentRequest = {
            SenderID: parseInt((state.profile as unknown as SenderProfile)?.ExSenderId),
            Description: values.Support_Description,
            DocumentName: values.Support_DocumentName,
            DocumentType: values.Support_DocumentType,
            Document: values.Support_Document,
          }
          await UploadSenderSupportingDocument(state.affiliate.affiliateId, supportingDocRequrestBody);
          await runKYCForSender(state.affiliate.affiliateId, {
            SenderID: parseInt((state.profile as unknown as SenderProfile)?.ExSenderId)
          });
        }
        if (!isStepper)
          toast.success('Your information has been submitted.', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        if (cb) cb();
      } catch (error) {
        console.log(error)
      } finally {
        setSubmitting(false);
      }
    },
  });

  // set mobile country code by selected country
  React.useEffect(() => {
    const docType = formik.values.DocumentType as string;
    const newFormItemsArray = _.cloneDeep(formItemsArray);
    const newSupportDocumentFormItemsArray = _.cloneDeep(supportDocumentFormItemsArray);

    const Support_DocumentTypeIndex = _.findIndex(supportDocumentFormItemsArray, i => i.key === 'Support_DocumentType');
    const Support_DocumentNameIndex = _.findIndex(supportDocumentFormItemsArray, i => i.key === 'Support_DocumentName');
    const Support_DescriptionIndex = _.findIndex(supportDocumentFormItemsArray, i => i.key === 'Support_Description');
    const Support_DocumentIndex = _.findIndex(supportDocumentFormItemsArray, i => i.key === 'Support_Document');
    const DocIssueCountryIndex = _.findIndex(formItemsArray, i => i.key === 'DocIssueCountry');
    const DocIssueStateIndex = _.findIndex(formItemsArray, i => i.key === 'DocIssueState');
    const CardNumberIndex = _.findIndex(formItemsArray, i => i.key === 'CardNumber');
    const ExpiryDateIndex = _.findIndex(formItemsArray, i => i.key === 'ExpiryDate');
    const IssueDateIndex = _.findIndex(formItemsArray, i => i.key === 'IssueDate');
    const DocumentBackIndex = _.findIndex(formItemsArray, i => i.key === 'DocumentBack');

    if (docType) {
      setSupportDocument(docType === 'P');
      
      newSupportDocumentFormItemsArray[Support_DocumentTypeIndex].isRequired = ['P'].includes(docType);
      newSupportDocumentFormItemsArray[Support_DocumentNameIndex].isRequired = ['P'].includes(docType);
      newSupportDocumentFormItemsArray[Support_DescriptionIndex].isRequired = ['P'].includes(docType);
      newSupportDocumentFormItemsArray[Support_DocumentIndex].isRequired = ['P'].includes(docType);
      newFormItemsArray[DocIssueCountryIndex].isRequired = ['P'].includes(docType);
      newFormItemsArray[DocIssueStateIndex].isRequired = !['P'].includes(docType);
      newFormItemsArray[CardNumberIndex].isRequired = ['DL'].includes(docType);

      newFormItemsArray[ExpiryDateIndex].isRequired = ['P', 'DL'].includes(docType);
      newFormItemsArray[IssueDateIndex].isRequired = ['P', 'PID'].includes(docType);
      newFormItemsArray[DocumentBackIndex].isRequired = ['DL'].includes(docType);

      const countryIndex = _.findIndex(formItemsArray, i => i.key === 'DocIssueCountry');
      const stateIndex = _.findIndex(formItemsArray, i => i.key === 'DocIssueState');
      const cardNumberIndex = _.findIndex(formItemsArray, i => i.key === 'CardNumber');
      const documentBackIndex = _.findIndex(formItemsArray, i => i.key === 'DocumentBack');

      newFormItemsArray[stateIndex].isHidden = ['P'].includes(docType);
      newFormItemsArray[cardNumberIndex].isHidden = !['DL'].includes(docType);
      newFormItemsArray[countryIndex].isHidden = ['DL', 'PID'].includes(docType);
      newFormItemsArray[documentBackIndex].isHidden = ['P'].includes(docType);

      newFormItemsArray[DocIssueStateIndex].width = newFormItemsArray[CardNumberIndex].isRequired ? 3 : 6;

      setFormItemsArray(newFormItemsArray);
      setSupportDocumentFormItemsArray(newSupportDocumentFormItemsArray);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.DocumentType]);


  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={4} alignItems="center">
        <Grid container spacing={2} alignItems='center'>
          {formItemsArray.map((item: FormItem) =>
            <FormItemComponent key={item.key} item={item} formik={formik} validationSchema={validationSchema} />
          )}
          {supportDocument &&
            <React.Fragment>
              <Alert sx={{ width: 1, ml: 2, mt: 2 }} severity="info">Please upload support document to prove your address(bill or bank statement).</Alert>
              {supportDocumentFormItemsArray.map((item: FormItem) =>
                <FormItemComponent key={item.key} item={item} formik={formik} validationSchema={validationSchema} />
              )}
            </React.Fragment>
          }
        </Grid>
        <Button size="large" color="primary" variant="contained" sx={{ width: 1 / 2 }} type="submit">
          {isStepper ? 'Next' : 'Upload'}
        </Button>
      </Stack>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  );
};

export default UploadDocumentForm;
