import * as React from 'react';
import {ErrorBoundary} from 'react-error-boundary'
import ReactDOM from 'react-dom';
import { Auth0Provider } from "@auth0/auth0-react";
import App from './App';
import history from "./utils/history";
import { getConfig } from "./config";
import { StoreProvider } from './store';
import { Button, Stack, Typography } from '@mui/material';

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: `${window.location.origin}/callback`,
  onRedirectCallback,
};

const OurFallbackComponent = () => {
  return (
    <Stack direction="column" spacing={4} alignItems="center" sx={{ py: 10 }}>
      <Typography variant="h4">An error occurred</Typography>
      <Button onClick={() => window.location.reload()} variant="contained">
        Try again
      </Button>
    </Stack>
  );
};

ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <ErrorBoundary
      FallbackComponent={OurFallbackComponent}
    >
      <StoreProvider>
        <App />
      </StoreProvider>
    </ErrorBoundary>
  </Auth0Provider >,
  document.querySelector('#root'),
);
