import React from "react";
import { Divider, Grid, Container, Typography } from "@mui/material";
import { useStateStore } from "../store";

const Features = () => {
  const store = useStateStore();
  return (
    <Container maxWidth="xl" disableGutters sx={{ p: { xs: 5, sm: 8 } }}>
      <Grid container spacing={{ xs: 6, sm: 8 }}>
        {store.affiliate.featuresCollection?.items.map(i =>
          <Grid item xs={12} sm={4} key={i.title}>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }} component="div">
              {i.title}
            </Typography>
            <Divider sx={{ width: 1 / 2, my: 1 }} />
            <Typography variant="body1" component="div">
              {i.description}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Features;
